<template>
	<div class="progressbar">
		<div
			class="line"
			:style="{ width: percent + '%', backgroundColor: currentBgColor }"
		>
			<!-- v-if="(percent > 0)" -->
			<div
				class="text"
				:style="{ opacity: showPercents }"
			>
				{{ transformedPercent }}
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			percent: Number,
		},

		data() {
			return {
				bgColor1: "#FFEB4F",
				bgColor2: "#24d2b5",
				// showPercents: 0,
			};
		},

		computed: {
			transformedPercent() {
				return `${this.percent.toFixed(0)}%`;
			},

			currentBgColor() {
				if (this.percent < 100) {
					return this.bgColor1;
				}

				return this.bgColor2;
			},

			showPercents() {
				if (this.percent === 0) {
					return "0";
				}
				return "1";
			},
		},
	};
</script>
<style lang="scss">
	.progressbar {
		max-width: 100%;
		background-color: #e2e2e2;
		border-radius: 0.4rem;
		overflow: hidden;
		height: auto !important;
		margin-bottom: 20px;
		.text {
			transition-delay: 75s;
			transition: all 0.5s linear;
		}
	}

	.line {
		font-size: 12px;
		background-color: #f5dc24;
		background-color: #24d2b5;
		transition: all 0.5s ease-in;

		background-image: linear-gradient(
			45deg,
			rgba(255, 255, 255, 0.15) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, 0.15) 50%,
			rgba(255, 255, 255, 0.15) 75%,
			transparent 75%,
			transparent
		);
        
		background-size: 1rem 1rem;
		border-radius: 0.2rem;
		color: black;
		font-weight: 700;
		font-family: Urbanist;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
