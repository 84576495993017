import axios from "axios";
import config from "config";
import store from "@/store";
import LS from "./localstorage";

export const AXIOS = axios.create({
	baseURL: `${config.apiUrl}`,
});

export const setAxiosToken = token => {
	AXIOS.defaults.headers.common["Authorization"] = `${token}`;
};

AXIOS.interceptors.response.use(
	function (response) {
		return {
			response,
			status: true,
		};
	},
	function (error) {
		return {
			response: error.response,
			status: false,
		};
	}
);

AXIOS.interceptors.request.use(config => {
	config.data = {
		// add your default ones
		Authorization: LS.get("user.token") || store.state.auth.user.token || undefined,

		// spread the request's params
		...config.data,
	};
	return config;
});
