<template >
	<div class="progress-block">
		<div class="progress-block__wrapper">
			<div v-for='i of countOfBlocks'
					 :key="i"
					 :id="(i - 1)"
					 class="progress-block__item">
				<!-- <div class="i">{{ i }}</div> -->
			</div>
			<!-- v-if="!done" -->
			<div :id="(countOfBlocks)"
					 class="progress-block__item">
				<!-- <div class="i">{{ i }}</div> -->
			</div>
		</div>
	</div>
</template>
<script>
export default {

	mounted() {
		if (document.getElementById("0")) {
			document.getElementById("0").classList.add("active");
		}
		if (this.done) {
			let arr = document.querySelectorAll('.progress-block__item');
			arr.forEach(i => i.classList.add('done'))
		}
	},

	props: {
		countOfBlocks: Number,
		done: Boolean,
	},

	methods: {
		changeSlide(idx) {
			this.$emit('changeSlideMark', idx)
		},
	},



}
</script>
<style lang="scss">
@import '@/sass/_vars';

.progress-block {
	width: 100%;
	margin-top: 24px;

	&__wrapper {
		display: flex;
		border-radius: 0.5rem;
		// border: 1px black solid;
		overflow: hidden;
		box-sizing: border-box;
		background-color: rgb(223, 223, 223);

	}

	&__item {
		box-sizing: border-box;
		height: 25px;
		flex-grow: 1;
		flex-basis: auto;
		position: relative;

		transition: all 0.5s linear;

		>.i {
			position: absolute;
			top: 40%;
			left: 50%;
		}

		&.done {
			background: linear-gradient(to right, #309be0, #2690d7, #1c86cd, #107bc4, #0071ba);
		}

		&.active {
			background-color: #57c766;
		}

		&.done.active {
			background: none;
			background-color: #57c766;
		}
	}
}
</style>
