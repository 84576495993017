<template>
	<!-- <div class="goback-button"> -->
	<div class="goback">
		<f7-button
			class="link my-button"
			@click="goBack"
			v-if="!loginScreen"
		>
			<div class="input-icon">
				<svg
					viewBox="0 0 1792 1792"
					xml:space="preserve"
					fill="currentcolor"
				>
					<path
						d="M259.9,983.3c-23.6-23.2-35.9-55.6-33.6-88.6c-2.4-31.9,10-63,33.6-84.6l546.3-548.3c45.4-45.4,119-45.4,164.4,0
					s45.4,119,0,164.4L616.3,780.5h833.6c63.8-0.4,115.8,51,116.1,114.8c0,0.2,0,0.4,0,0.7c-0.4,64-52.1,115.8-116.1,116.1H616.3
					l354.4,353.7c45.4,45.4,45.4,119,0,164.4s-119,45.4-164.4,0l0,0L259.9,983.3z"
					/>
				</svg>
			</div>
		</f7-button>
		<f7-button
			class="link my-button"
			@click="goBackLogin"
			v-else
		>
			<div class="input-icon">
				<svg
					viewBox="0 0 1792 1792"
					xml:space="preserve"
					fill="currentcolor"
				>
					<path
						d="M259.9,983.3c-23.6-23.2-35.9-55.6-33.6-88.6c-2.4-31.9,10-63,33.6-84.6l546.3-548.3c45.4-45.4,119-45.4,164.4,0
					s45.4,119,0,164.4L616.3,780.5h833.6c63.8-0.4,115.8,51,116.1,114.8c0,0.2,0,0.4,0,0.7c-0.4,64-52.1,115.8-116.1,116.1H616.3
					l354.4,353.7c45.4,45.4,45.4,119,0,164.4s-119,45.4-164.4,0l0,0L259.9,983.3z"
					/>
				</svg>
			</div>
		</f7-button>
		<div>{{ title }}</div>
	</div>
</template>

<script>
	import { goBack } from "@/plugin/func";

	export default {
		props: {
			title: String,
			loginScreen: Boolean,
		},

		methods: {
			goBack: goBack,
			goBackLogin: function () {
				this.$emit("backToPhone");
			},
		},
	};
</script>

<style lang="scss">
	.goback {
		display: flex;
		// color: var(--primary-font-color);
		// font-weight: 500;
		margin: 18px 24px;
		align-items: center;
		font-size: 24px;
		align-self: start;

		.my-button {
			flex-shrink: 0;
			background-color: transparent;
			padding: 0 !important;
			white-space: normal;
			height: auto !important;
			text-align: left;

			.input-icon {
				display: flex;
				align-items: center;
				padding-right: 16px;
				width: 25px;
			}

			svg {
				width: 100%;
			}
		}
	}
</style>
