<template>
	<div class="main-header">
		<div class="main-header-profile">
			<f7-link
				class="header-practice-location-name"
				@click="locationClick">
				{{ userPractice[0].client?.practice?.name }}
			</f7-link>

			<f7-link
				class="main-header-info"
				@click.stop="navigateTo('editprofile')">
				<div
					v-if="image"
					class="img-wrapper">
					<img
						:src="image"
						alt="###" />
				</div>
				<div
					v-else
					class="img-wrapper">
					<div class="icon">
						<svg
							viewBox="0 0 16 20"
							fill="currentcolor"
							id="user">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M13.294 5.29105C13.294 8.22808 10.9391 10.5831 8 10.5831C5.0619 10.5831 2.70601 8.22808 2.70601 5.29105C2.70601 2.35402 5.0619 0 8 0C10.9391 0 13.294 2.35402 13.294 5.29105ZM8 20C3.66237 20 0 19.295 0 16.575C0 13.8539 3.68538 13.1739 8 13.1739C12.3386 13.1739 16 13.8789 16 16.599C16 19.32 12.3146 20 8 20Z" />
						</svg>
					</div>
				</div>
				<div class="description">
					<div
						class="greeting"
						v-text="makeGreetings"></div>
					<div class="name">{{ fullName }}</div>
				</div>

				<div
					class="main-header-icon-wrapper"
					@click.stop="navigateTo('notifications')"
					popover-open=".popover">
					<div class="svg-wrapper">
						<svg
							fill="currentcolor"
							id="bell"
							viewBox="0 0 227.805 227.805"
							:class="{ shakebell: alertsCount }">
							<path
								d="M200.551,194.476c-8.234-7.774-27.387-30.341-27.387-71.894V100.01c0-24.913-15.461-46.269-37.285-55.018
                        c3.691-4.728,5.898-10.667,5.898-17.117C141.777,12.505,129.272,0,113.902,0S86.027,12.505,86.027,27.875
                        c0,6.449,2.208,12.39,5.899,17.118c-21.823,8.749-37.282,30.105-37.282,55.017v22.572c0,41.545-19.155,64.118-27.392,71.895
                        c-2.233,2.109-2.955,5.368-1.82,8.224c1.136,2.855,3.897,4.729,6.97,4.729h54.658c3.285,11.738,14.07,20.375,26.842,20.375
                        s23.557-8.637,26.842-20.375h54.658c3.073,0,5.835-1.874,6.97-4.73C203.507,199.844,202.785,196.585,200.551,194.476z M113.902,15
                        c7.1,0,12.875,5.776,12.875,12.875c0,7.099-5.775,12.875-12.875,12.875c-7.1,0-12.875-5.776-12.875-12.875
                        C101.027,20.776,106.803,15,113.902,15z M113.902,212.805c-4.3,0-8.104-2.127-10.443-5.375h20.887
                        C122.006,210.678,118.202,212.805,113.902,212.805z M134.297,192.43c-0.007,0-0.013-0.001-0.02-0.001
                        c-0.007,0-0.013,0.001-0.02,0.001H93.547c-0.007,0-0.013-0.001-0.02-0.001c-0.007,0-0.013,0.001-0.02,0.001H48.742
                        c9.907-13.845,20.902-36.686,20.902-69.848V100.01c0-24.405,19.854-44.26,44.26-44.26c24.405,0,44.26,19.855,44.26,44.26v22.572
                        c0,33.165,10.992,56.004,20.897,69.848H134.297z" />
						</svg>
					</div>

					<div
						class="notifications-count"
						v-if="alertsCount">
						{{ alertsCount }}
					</div>
				</div>
			</f7-link>
		</div>

		<f7-popover
			class="popover"
			:opened="isPopoverOpened"
			vertical-position="bottom"
			target-el=".main-header-icon-wrapper"
			:close-on-escape="true"
			@popover:closed="isPopoverOpened = false">
			<div>{{ popoverText }}</div>
		</f7-popover>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";

	export default {
		props: {
			image: String,
			fullName: String,
			alertsCount: Number,
		},

		data() {
			return {
				isPopoverOpened: false,
				popoverText: "You have no notifications",
			};
		},

		methods: {
			locationClick: function (e) {
				e.stopPropagation();
				f7router.navigate(`/practice-page`, {
					// history: false,
					// browserHistory: false,
				});
			},

			popoverBehavior() {
				this.isPopoverOpened = true;
				// setTimeout(() => {
				// 	this.isPopoverOpened = false;
				// }, 1500);
			},

			navigateTo(string) {
				// if (
				// 	string === "notifications" &&
				// 	this.readNotifications.length < 1 &&
				// 	this.unreadNotifications.length < 1 &&
				// 	this.alertsCount < 0
				// ) {
				// 	this.popoverBehavior();
				// 	return;
				// }
				f7router.navigate(`/${string}`);
			},
		},

		computed: {
			...mapGetters("auth", ["userPractice"]),
			...mapGetters("app", ["readNotifications", "unreadNotifications"]),

			// TODO
			// ...mapGetters("app", ["practiceInfo"]),

			makeGreetings() {
				var myDate = new Date();
				var hrs = myDate.getHours();
				let greet = "";

				if (hrs < 12) {
					return (greet = "Good Morning");
				} else if (hrs >= 12 && hrs <= 17) {
					return (greet = `Good Afternoon!`);
				} else if (hrs >= 17 && hrs <= 24) {
					return (greet = "Good Evening");
				}

				return greet;
			},
		},
	};
</script>

<style lang="scss"></style>
