<template>
	<f7-page class="notifications">
		<!-- <Topbar /> -->
		<BackButton title="Notifications" />

		<div
			class="notification-list notifications-links"
			v-if="unreadNotifications?.length >= 1">
			<f7-link
				@click="toCurrentNotification(notification)"
				v-for="(notification, index) in unreadNotifications"
				:key="index"
				class="card-shadow1"
				:class="notification.done ? 'already-done' : ''">
				<div class="notifications-icon-wrapper">
					<div
						class="svg-wrapper"
						v-if="notification.securityAlert.urgency === 1">
						<svg
							viewBox="0 0 24 24"
							id="exclamation-triangle">
							<path
								class="cls-1"
								d="M12.13,3.41h-.26a1.48,1.48,0,0,0-1.26.7L1.73,18.32a1.48,1.48,0,0,0-.23.79h0A1.48,1.48,0,0,0,3,20.59H21a1.48,1.48,0,0,0,1.48-1.48h0a1.48,1.48,0,0,0-.23-.79L13.39,4.11A1.48,1.48,0,0,0,12.13,3.41Z" />
							<line
								class="cls-1"
								x1="12"
								y1="8.18"
								x2="12"
								y2="13.91" />
							<line
								class="cls-1"
								x1="12"
								y1="15.82"
								x2="12"
								y2="17.73" />
						</svg>
					</div>

					<ItemDoneMark v-if="notification.done" />

					<!-- <div
						class="img-wrapper-done"
						v-if="alert.done"
					>
						<svg
							viewBox="0 0 405.272 405.272"
							fill="currentcolor"
						>
							<path
								d="M393.401,124.425L179.603,338.208c-15.832,15.835-41.514,15.835-57.361,0L11.878,227.836
                            c-15.838-15.835-15.838-41.52,0-57.358c15.841-15.841,41.521-15.841,57.355-0.006l81.698,81.699L336.037,67.064
                            c15.841-15.841,41.523-15.829,57.358,0C409.23,82.902,409.23,108.578,393.401,124.425z"
							/>
						</svg>
					</div> -->

					<div
						class="svg-wrapper"
						v-if="notification.securityAlert.urgency === 0">
						<svg
							fill="currentcolor"
							id="bell"
							viewBox="0 0 227.805 227.805">
							<path
								d="M200.551,194.476c-8.234-7.774-27.387-30.341-27.387-71.894V100.01c0-24.913-15.461-46.269-37.285-55.018
                                                c3.691-4.728,5.898-10.667,5.898-17.117C141.777,12.505,129.272,0,113.902,0S86.027,12.505,86.027,27.875
                                                c0,6.449,2.208,12.39,5.899,17.118c-21.823,8.749-37.282,30.105-37.282,55.017v22.572c0,41.545-19.155,64.118-27.392,71.895
                                                c-2.233,2.109-2.955,5.368-1.82,8.224c1.136,2.855,3.897,4.729,6.97,4.729h54.658c3.285,11.738,14.07,20.375,26.842,20.375
                                                s23.557-8.637,26.842-20.375h54.658c3.073,0,5.835-1.874,6.97-4.73C203.507,199.844,202.785,196.585,200.551,194.476z M113.902,15
                                                c7.1,0,12.875,5.776,12.875,12.875c0,7.099-5.775,12.875-12.875,12.875c-7.1,0-12.875-5.776-12.875-12.875
                                                C101.027,20.776,106.803,15,113.902,15z M113.902,212.805c-4.3,0-8.104-2.127-10.443-5.375h20.887
                                                C122.006,210.678,118.202,212.805,113.902,212.805z M134.297,192.43c-0.007,0-0.013-0.001-0.02-0.001
                                                c-0.007,0-0.013,0.001-0.02,0.001H93.547c-0.007,0-0.013-0.001-0.02-0.001c-0.007,0-0.013,0.001-0.02,0.001H48.742
                                                c9.907-13.845,20.902-36.686,20.902-69.848V100.01c0-24.405,19.854-44.26,44.26-44.26c24.405,0,44.26,19.855,44.26,44.26v22.572
                                                c0,33.165,10.992,56.004,20.897,69.848H134.297z" />
						</svg>
					</div>
				</div>

				<div class="notifications-link-name">
					{{ notification.securityAlert.subject }}
				</div>
			</f7-link>
		</div>
		<div
			class="notification-list read-notifications notifications-links"
			v-else-if="readNotifications?.length >= 1">
			<f7-link
				@click="toCurrentNotification(notification)"
				v-for="(notification, index) in readNotifications"
				:key="index"
				class="card-shadow1"
				:class="notification.done ? 'already-done' : ''">
				<div class="notifications-icon-wrapper">
					<div
						class="svg-wrapper"
						v-if="notification.securityAlert.urgency === 1">
						<svg
							viewBox="0 0 24 24"
							id="exclamation-triangle">
							<path
								class="cls-1"
								d="M12.13,3.41h-.26a1.48,1.48,0,0,0-1.26.7L1.73,18.32a1.48,1.48,0,0,0-.23.79h0A1.48,1.48,0,0,0,3,20.59H21a1.48,1.48,0,0,0,1.48-1.48h0a1.48,1.48,0,0,0-.23-.79L13.39,4.11A1.48,1.48,0,0,0,12.13,3.41Z" />
							<line
								class="cls-1"
								x1="12"
								y1="8.18"
								x2="12"
								y2="13.91" />
							<line
								class="cls-1"
								x1="12"
								y1="15.82"
								x2="12"
								y2="17.73" />
						</svg>
					</div>

					<ItemDoneMark v-if="notification.done" />

					<!-- <div
						class="img-wrapper-done"
						v-if="alert.done"
					>
						<svg
							viewBox="0 0 405.272 405.272"
							fill="currentcolor"
						>
							<path
								d="M393.401,124.425L179.603,338.208c-15.832,15.835-41.514,15.835-57.361,0L11.878,227.836
                            c-15.838-15.835-15.838-41.52,0-57.358c15.841-15.841,41.521-15.841,57.355-0.006l81.698,81.699L336.037,67.064
                            c15.841-15.841,41.523-15.829,57.358,0C409.23,82.902,409.23,108.578,393.401,124.425z"
							/>
						</svg>
					</div> -->

					<div
						class="svg-wrapper"
						v-if="notification.securityAlert.urgency === 0">
						<svg
							fill="currentcolor"
							id="bell"
							viewBox="0 0 227.805 227.805">
							<path
								d="M200.551,194.476c-8.234-7.774-27.387-30.341-27.387-71.894V100.01c0-24.913-15.461-46.269-37.285-55.018
                                                c3.691-4.728,5.898-10.667,5.898-17.117C141.777,12.505,129.272,0,113.902,0S86.027,12.505,86.027,27.875
                                                c0,6.449,2.208,12.39,5.899,17.118c-21.823,8.749-37.282,30.105-37.282,55.017v22.572c0,41.545-19.155,64.118-27.392,71.895
                                                c-2.233,2.109-2.955,5.368-1.82,8.224c1.136,2.855,3.897,4.729,6.97,4.729h54.658c3.285,11.738,14.07,20.375,26.842,20.375
                                                s23.557-8.637,26.842-20.375h54.658c3.073,0,5.835-1.874,6.97-4.73C203.507,199.844,202.785,196.585,200.551,194.476z M113.902,15
                                                c7.1,0,12.875,5.776,12.875,12.875c0,7.099-5.775,12.875-12.875,12.875c-7.1,0-12.875-5.776-12.875-12.875
                                                C101.027,20.776,106.803,15,113.902,15z M113.902,212.805c-4.3,0-8.104-2.127-10.443-5.375h20.887
                                                C122.006,210.678,118.202,212.805,113.902,212.805z M134.297,192.43c-0.007,0-0.013-0.001-0.02-0.001
                                                c-0.007,0-0.013,0.001-0.02,0.001H93.547c-0.007,0-0.013-0.001-0.02-0.001c-0.007,0-0.013,0.001-0.02,0.001H48.742
                                                c9.907-13.845,20.902-36.686,20.902-69.848V100.01c0-24.405,19.854-44.26,44.26-44.26c24.405,0,44.26,19.855,44.26,44.26v22.572
                                                c0,33.165,10.992,56.004,20.897,69.848H134.297z" />
						</svg>
					</div>
				</div>

				<div class="notifications-link-name">
					{{ notification.securityAlert.subject }}
				</div>
			</f7-link>
		</div>

		<div
			class="div"
			v-else>
			There is no notifications...
		</div>
	</f7-page>
</template>

<script>
	import { mapGetters } from "vuex";
	import ItemDoneMark from "../ItemDoneMark.vue";

	export default {
		async created() {
			await this.$store.dispatch("app/getNofifications");
		},

		methods: {
			toCurrentNotification(info) {
				this.$store.commit("app/setCurrentNotification", info);
				f7router.navigate("notification-info");
			},
		},

		computed: {
			...mapGetters("app", ["readNotifications", "unreadNotifications"]),

			notifications() {
				return this.$store.state.app.notifications;
			},
		},
		components: { ItemDoneMark },
	};
</script>

<style></style>
