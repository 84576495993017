import store from "@/store";

import home from "@/components/homepage/Homepage.vue";

import editprofile from "@/components/edit-profile/ProfilePage";

import directory from "@/components/directory/directory";
import directory_info from "@/components/directory/directory-info";

import handbook from "@/components/handbook/Handbook";
import handbook_practice from "@/components/handbook/HandbookPractice";
import handbook_procedure_info from "@/components/handbook/HandbookProcedureInfo";

import designation_page from "@/components/designations/DesignationPage";
import practice_page from "@/components/views/PracticePage";

import notifications from "@/components/notifications/Notifications";
import notification_info from "@/components/notifications/NotificationInfo";

import trainings_page from "@/components/trainings/TrainingsPage";
import current_training from "@/components/trainings/CurrentTraining";
import training_action from "@/components/trainings/TrainingAction";

const routes = [
	{
		path: "/",
		name: "home",
		component: home,
	},

	{
		path: "/editprofile",
		name: "editprofile",
		component: editprofile,
		routes: [],
	},

	{
		path: "/directory",
		name: "directory",
		component: directory,
		routes: [
			{
				path: "/directory-info",
				name: "directory-info",
				component: directory_info,
			},
		],
	},

	{
		path: "/handbook",
		name: "handbook",
		component: handbook,
		routes: [
			{
				path: "/handbook-practice",
				name: "handbook-practice",
				component: handbook_practice,
				routes: [
					{
						path: "/handbook-procedure-info",
						name: "handbook-procedure-info",
						component: handbook_procedure_info,
					},
				],
			},
		],
	},

	{
		path: "/designation",
		name: "designation",
		component: designation_page,
	},

	{
		path: "/notifications",
		name: "notifications",
		component: notifications,
		routes: [
			{
				path: "/notification-info",
				name: "notification-info",
				component: notification_info,
			},
		],
	},

	{
		path: "/trainings-page",
		name: "trainings-page",
		component: trainings_page,
		routes: [
			{
				path: "/current-training",
				name: "current-training",
				component: current_training,
				routes: [
					{
						path: "/training-action",
						name: "training-action",
						component: training_action,
					},
				],
			},
		],
	},
	{
		path: "/practice-page",
		name: "practice-page",
		component: practice_page,
	},
];

function setBeforeEnter(obj) {
	obj.beforeEnter = f7 => {
		if (store.state.auth.user.token) {
			f7.resolve();
		} else {
			f7.reject();
		}
	};

	if ("routes" in obj) {
		for (const route of obj.routes) {
			setBeforeEnter(route);
		}
	}
}

routes.forEach(route => {
	if (route.name === "home") {
		return;
	}
	setBeforeEnter(route);
});

export default routes;
