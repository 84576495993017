<template>
	<div class="container">
		<vueVimeoPlayer
			class="vimeo"
			ref="player"
			:options="{
				responsive: true,
				loop: false,
				playsinline: true,
				pip: false,
			}"
			allowfullscreen
			:video-id="videoID"
			@ready="onReady"
			@ended="onEnded"
			@error="onError"
			@loaded="onLoaded"
			@onload="onError"
		/>

		<!-- allowfullscreen -->
		<!-- :autoplay="true" -->
	</div>
</template>
<script>
	import { vueVimeoPlayer } from "vue-vimeo-player";

	export default {
		props: {
			trainingAction: Object,
			video: Object,
		},

		data() {
			return {
				videoID: "",
				playerReady: false,
				loop: false,
				players: "",
			};
		},

		components: { vueVimeoPlayer },

		created() {
			this.videoID = this.video ? this.video.vimeoId : null;
		},

		mounted() {
			if (this.videoID !== null) {
				this.$store.commit("loading", true);
			}

			//? MAKE FULLSCREEN PLAYER
			// document
			// 	.querySelector(".vimeo")
			// 	.requestFullscreen()
			// 	.catch((err) => {
			// 		alert(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
			// 	});

			// setTimeout(() => {
			// 	console.log(document.querySelector(".vimeo").lastChild);
			// 	console.log(document.querySelector("iframe"));
			// 	document
			// 		.querySelector("iframe")
			// 		.requestFullscreen()
			// 		.catch((err) => {
			// 			console.log(`${err.message} (${err.name})`);
			// 		});
			// }, 3000);

			// setTimeout(() => {
			// 	try {
			// 		this.$refs.player.$el.requestFullscreen();
			// 	} catch (error) {
			// 		console.log(error);
			// 	}
			// }, 2000);
		},

		methods: {
			onReady(e) {
				this.playerReady = true;
				// console.log("player :>> onready", this.$refs.player);

				// console.log("player :>> ", this.$refs.player);

				// try {
				// 	document
				// 		.querySelector("iframe")
				// 		.requestFullscreen()
				// 		.catch((err) => {
				// 			console.log(`${err.message} (${err.name})`);
				// 		});
				// } catch (error) {
				// 	console.log(error);
				// }

				this.$store.commit("loading", false);
			},

			onLoaded() {
				// console.log('loaded')
				this.$store.commit("loading", false);
			},

			onEnded() {
				this.$store.commit("loading", true);

				if (!this.trainingAction.done) {
					this.$emit("agreeWithAction");
					this.$emit("submit");
				} else {
					this.$store.commit("loading", false);
				}
			},

			onError(e) {
				// this.player = player;
				this.$store.commit("loading", false);
			},

			play() {
				// console.log(this.$refs.player);
				this.$refs.player.play();
			},

			stop() {
				this.$refs.player.stop();
			},
		},
	};
</script>
<style lang="scss">
	.vimeo {
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		margin-top: 20px;
	}
</style>
