<template>
	<div
		class="homepage-designation-actions"
		v-if="designations"
	>
		<div class="homepage-designation-actions__header section-header">
			<div class="homepage-designation-actions__title section-header__title">Designations</div>
		</div>

		<div class="link-container">
			<f7-link
				v-for="designation in designations"
				@click="toCurrentDesignation(designation.id)"
			>
				<div class="svg-wrapper">
					<svg
						viewBox="0 0 512 512"
						fill="currentcolor"
					>
						<path
							d="M332.64,64.58C313.18,43.57,286,32,256,32c-30.16,0-57.43,11.5-76.8,32.38-19.58,21.11-29.12,49.8-26.88,80.78C156.76,206.28,203.27,256,256,256s99.16-49.71,103.67-110.82C361.94,114.48,352.34,85.85,332.64,64.58Z"
						/>
						<path
							d="M432,480H80A31,31,0,0,1,55.8,468.87c-6.5-7.77-9.12-18.38-7.18-29.11C57.06,392.94,83.4,353.61,124.8,326c36.78-24.51,83.37-38,131.2-38s94.42,13.5,131.2,38c41.4,27.6,67.74,66.93,76.18,113.75,1.94,10.73-.68,21.34-7.18,29.11A31,31,0,0,1,432,480Z"
						/>
					</svg>
				</div>
				<div class="designation">
					{{ designation.action }}
				</div>
			</f7-link>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			designations: Array,
		},

		methods: {
			toCurrentDesignation(id) {
				this.$store.dispatch("trainings/getTrainings").then(() => {
					this.$store.commit("trainings/setDesignationId", id);
					f7router.navigate("/designation");
				});
			},
		},
	};
</script>
<style lang="scss"></style>
