<template>
	<f7-page class="directory">
		<BackButton
			title="My Directory"
			@click="setCurrentPage('home')">
		</BackButton>

		<div
			class="search-button"
			@click="showInput">
			<div class="icon-wrapper"></div>
		</div>

		<div
			class="input-block"
			:class="{ 'active-input': isActiveInput }">
			<f7-input
				@input="searchingStr = $event.target.value"
				type="text"
				clear-button
				placeholder="Please enter"
				ref="search">
				<template v-slot:media>
					<f7-icon icon="demo-list-icon"></f7-icon>
				</template>
			</f7-input>
		</div>
		<div class="item-list">
			<f7-link
				v-for="(practice, idx) of practicesList"
				:key="idx"
				@click="toDetails(practice)">
				<div class="practice-item">
					<div class="img-wrapper">
						<div class="icon"></div>
					</div>
					<div class="practice-name name">{{ practice.name }}</div>
				</div>
			</f7-link>

			<f7-link
				@click="toDetails(user)"
				v-for="user in sortedList"
				:key="user.id"
				:id="user.id">
				<div
					v-if="user.image"
					class="img-wrapper">
					<img
						:src="user.image"
						alt="###" />
				</div>
				<div
					v-else
					class="img-wrapper">
					<div class="icon"></div>
				</div>
				<div class="description">
					<div class="name">
						{{ user.firstName + " " + user.lastName }}
					</div>
					<div class="job-title">
						<span
							v-for="(job, index) in user.jobTitle"
							:key="index"
							>{{ job }}</span
						>
					</div>
				</div>
			</f7-link>
			<!-- <div v-if="sortedUsersList.length < 1">No match found...</div> -->
		</div>
	</f7-page>
</template>

<script>
	export default {
		data() {
			return {
				searchingStr: "",
				isActiveInput: false,
			};
		},
		created() {
			this.$store.dispatch("app/getDirectory");
		},
		computed: {
			practicesList() {
				return this.$store.getters["app/getPracticesListOfDirectory"];
			},
			usersList() {
				return this.$store.getters["app/getUsersListOfDirectory"];
			},
			sortedList() {
				return this.usersList.filter(
					i => `${i.firstName.toLowerCase()} ${i.lastName.toLowerCase()}`.indexOf(this.searchingStr.toLowerCase()) !== -1
				);
			},
		},
		methods: {
			setCurrentPage(id) {
				this.$store.commit("app/setCurrentPage", id);
			},
			closeInput() {
				this.isActiveInput = false;
			},
			showInput() {
				if (this.isActiveInput) {
					this.isActiveInput = false;
				} else {
					this.isActiveInput = true;
					this.$refs.search.$el.focus();
				}
			},
			toDetails(info) {
				this.$store.commit("app/setCurrentItemInfo", info);
				f7.views.main.router.navigate("/directoryinfo");
			},
		},
	};
</script>

<style></style>
