<template>
	<div
		class="custom-popup"
		:class="{ 'show-it': popupShow }"
		@click="
			e => {
				this.$store.state.app.popupShow = false;
			}
		">
		<div
			class="custom-popup-container"
			@click="
				e => {
					e.stopPropagation();
				}
			">
			<div class="custom-popup-title procedure-title">
				{{ title }}
			</div>

			<div class="custom-popup-text">
				{{ reason }}
			</div>

			<div class="submit-button">
				<f7-button
					class="custom-popup-close-button"
					@click="this.$store.state.app.popupShow = false">
					<!-- <svg
                        viewBox="0 0 1792 1792"
                        fill="currentcolor">
                        <path
                            d="M1082.2,896.6l410.2-410c51.5-51.5,51.5-134.6,0-186.1s-134.6-51.5-186.1,0l-410.2,410L486,300.4
                                            c-51.5-51.5-134.6-51.5-186.1,0s-51.5,134.6,0,186.1l410.2,410l-410.2,410c-51.5,51.5-51.5,134.6,0,186.1
                                            c51.6,51.5,135,51.5,186.1,0l410.2-410l410.2,410c51.5,51.5,134.6,51.5,186.1,0c51.1-51.5,51.1-134.6-0.5-186.2L1082.2,896.6z" />
                    </svg> -->
					Close
				</f7-button>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			popupShow: Boolean,
			title: String,
			reason: String,
		},
	};
</script>
<style lang="scss">
	@import "@/sass/_vars";

	.custom-popup {
		position: absolute;
		max-width: $max-width;

		z-index: 999999;
		top: 0%;
		left: 50%;
		align-self: center;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.781);
		transform: translate(-50%, 50%);

		opacity: 0;
		pointer-events: none;

		transition: all 0.2s linear;

		&.show-it {
			transform: translate(-50%, 0);
			opacity: 1;
			pointer-events: all;
		}

		&-container {
			max-width: 320px;
			margin: 0 24px;
			background-color: var(--page-bg-color);
			padding: 20px;
			border-radius: 0.5rem;
			min-width: 200px;

			font-size: 16px;

			.submit-button {
				margin: 0;
			}
		}

		// &-title {
		// 	font-weight: 400;
		// 	font-size: 20px;
		// 	border-bottom: 3px solid var(--primary-font-color);
		// 	margin-bottom: 8px;
		// }
		&-text {
			margin-top: 16px;
			font-family: Urbanist;
		}

		.custom-popup-close-button {
			margin: 15px auto 0;
			// height: auto;
			border-radius: 0.4rem;
			background-color: var(--primary-color);
			// aspect-ratio: 1/1;
			// width: 50px;

			svg {
				width: 50%;
				color: #fff;
			}
		}
	}
</style>
