<template>
	<div
		class="choice-of-practice"
		v-if="getPractices"
	>
		<Topbar />

		<BackButton
			:loginScreen="true"
			@backToPhone="destroy"
			v-if="!showSuccessMessage && !showErrorMessage && !showQuizScreen"
		/>

		<QuizScreen
			:quizInfo="quizInfo"
			:verifyData="data"
			v-if="showQuizScreen"
			@statusSuccess="quizSuccess"
			@statusError="quizError"
			class="quiz-screen"
		/>

		<div v-else-if="!showQuizScreen && !getShowPracticeVerificationSuccessScreen && getPractices.length > 1">
			<div class="submit-btns-block">
				<div class="title">Select practice:</div>
				<div
					class="submit-button"
					v-for="(practice, index) in getPractices"
					:key="index"
				>
					<f7-button
						:class="{
							active: setPracticeType(practice[0]) === 'active',
							disabled: setPracticeType(practice[0]) === 'disabled',
							orange: setPracticeType(practice[0]) === 'verify',
						}"
						@click="confirmChoice(practice[0].id)"
					>
						<div class="text">
							{{ practice[0].client.practice.name }}
<!--							{{ practice[0].userToLocations[0].location.name }}-->

							<span
								class="verified-practice"
								v-if="practice[0].client.isVerified !== true"
							>
								verification required
							</span>
						</div>

						<div
							class="svg-wrapper exclamation"
							id="exclamation-mark"
							v-if="practice[0].client.isVerified !== true"
						>
							<svg
								fill="currentcolor"
								viewBox="0 0 64 64"
							>
								<circle
									fill-rule="evenodd"
									clip-rule="evenodd"
									cx="31.9990997"
									cy="48.9999504"
									r="2.9999499"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M31.1189003,42h1.7622986c0.5373001,0,0.9785995-0.4245987,0.9993019-0.9614983
		l1.0794983-28.0000019C34.9818993,12.4715996,34.5280991,12,33.960701,12h-3.9212914
		c-0.5674095,0-1.0212097,0.4715996-0.9993095,1.0384998l1.0795002,28.0000019C30.1403008,41.5754013,30.5816002,42,31.1189003,42z"
								/>
							</svg>
						</div>
						<div
							class="svg-wrapper lock"
							v-if="setPracticeType(practice[0]) === 'disabled'"
						>
							<svg
								viewBox="0 0 330 330"
								xml:space="preserve"
								fill="currentcolor"
							>
								<path
									id="XMLID_519_"
									d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85
																									S80.001,38.131,80.001,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M110.001,85
																									c0-30.327,24.673-55,54.999-55c30.327,0,55,24.673,55,55v45H110.001V85z"
								/>
							</svg>
						</div>
					</f7-button>
				</div>
			</div>
		</div>

		<VerificationPracticeSuccessScreen
			v-if="getShowPracticeVerificationSuccessScreen"
			:destroy="destroy"
			:toHomePage="toHomePage"
			:chosenPracticeName="chosenPracticeName"
			:errMsg="errMsg"
			:showErrorMessage="showErrorMessage"
			:showSuccessMessage="showSuccessMessage"
		/>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	import { AUTH_SERVICE } from "@/api/auth";
	import { LS } from "@/plugin/localstorage";

	import QuizScreen from "../QuizScreen.vue";
	import VerificationPracticeSuccessScreen from "../VerificationPracticeSuccessScreen.vue";

	export default {
		data() {
			return {
				confirmInfo: undefined,
				chosenPracticeName: null,

				showSuccessMessage: false,
				showErrorMessage: false,
				showQuizScreen: false,

				errMsg: undefined,
				data: [],
				token: "",

				quizInfo: undefined,
			};
		},

		mounted() {
			if (this.getPractices.length === 1) {
				let practiceId = this.getPractices[0]?.id || this.getPractices[0][0]?.id;
				this.showQuizScreen = true;
				this.confirmChoice(practiceId);
			}
		},

		methods: {
			toHomePage() {
				this.$store.commit("app/setShowPracticeVerificationSuccessScreen", false);
				this.$store.commit("app/showToolbars");
			},

			quizSuccess() {
				this.showSuccessMessage = true;
				this.showQuizScreen = false;
				this.$store.commit("app/setShowPracticeVerificationSuccessScreen", true);
			},

			quizError(resp) {
				this.errMsg = resp;
				this.showErrorMessage = true;
				this.showQuizScreen = false;
				this.$store.commit("app/setShowPracticeVerificationSuccessScreen", true);
			},

			destroy() {
				LS.clear();
				this.$emit("goBack");
				this.confirmInfo = "";
				this.showSuccessMessage = false;
				this.showErrorMessage = false;
				this.showQuizScreen = false;
				this.errMsg = false;
				this.data = [];
				this.token = "";
				this.$store.commit("app/setShowPracticeVerificationSuccessScreen", false);
			},

			setPracticeType(prac) {
				if (prac) {
					const activePrac = prac?.client.practice.isActive;
					const verifiedPrac = prac?.client.isVerified;
					if (activePrac && verifiedPrac !== true) return "verify";
					if (activePrac) return "active";
					return "disabled";
				}
			},

			checkForVerify(practiceId) {
				let verifyKeys = Object.keys(this.getVerify);
				let result = [String(practiceId)];
				for (let i = 0; i < verifyKeys.length; i++) {
					if (practiceId === Number(verifyKeys[i])) {
						if (this.getVerify[verifyKeys[i]] !== null) {
							result.push(this.getVerify[verifyKeys[i]].code);
						}
					}
				}
				return result;
			},

			setChosenPracticeName(id) {
				let practice;
				if (this.getPractices.length !== 1) {
					practice = this.getPractices.find((el) => el[0].id == id);
				} else {
					practice = this.getPractices[0];
				}
				// this.chosenPracticeName = practice[0]?.userToLocations[0].location.name;
				this.chosenPracticeName = practice[0]?.client.practice.name;
			},

			async confirmChoice(practiceId) {
				// debugger;
				this.$store.commit("loading", true);
				this.data = this.checkForVerify(practiceId);
				this.setChosenPracticeName(practiceId);
				if (this.data.length > 1) {
					const { response, status } = await AUTH_SERVICE.verifyPractice({
						userId: this.data[0],
						code: this.data[1],
					});
					if (!status) {
						f7.views.main.router.navigate("/main-error-handler");
					}
					this.confirmInfo = response.data.items;
					this.quizInfo = response.data.items;

					this.showQuizScreen = true;
				} else {
					this.$store
						.dispatch("auth/askForToken", {
							phone_info: navigator.userAgent,
							sms_log: this.$store.state.auth.user.smsLog,
							submit: this.data[0],
						})
						.then((resp) => {
							if (resp) {
								this.$store.dispatch("login", { token: resp.token, id: resp.id });
							}
						});
				}
				this.$store.commit("loading", false);
			},
		},
		computed: {
			...mapGetters("auth", ["getPractices", "getVerify"]),
			...mapGetters("app", ["getShowPracticeVerificationSuccessScreen"]),
		},
		components: { QuizScreen, VerificationPracticeSuccessScreen },
	};
</script>

<style lang="scss">
	.var-message {
		font-size: 20px;
		margin: 0 24px;

		.text {
			padding: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
		}
	}

	.err-message {
		font-size: 20px;
		text-align: center;
		margin: 30px 24px;
		// min-height: 80px;
		background-color: rgb(255, 195, 195);
		border-radius: 5px;
		border: 1px solid rgb(252, 156, 156);
		color: rgb(255, 103, 103);
		padding: 10px;
		display: flex;
		align-self: center;
		justify-content: center;
	}
</style>
