<template>
	<f7-page
		class="edit-profile"
		v-if="userInfo"
	>
		<BackButton title="Edit Profile" />

		<div
			class="popup-msg"
			:class="{ 'active-msg': successMsgIsOpened }"
		>
			<svg
				viewBox="0 0 405.272 405.272"
				fill="currentcolor"
			>
				<path
					d="M393.401,124.425L179.603,338.208c-15.832,15.835-41.514,15.835-57.361,0L11.878,227.836
																																c-15.838-15.835-15.838-41.52,0-57.358c15.841-15.841,41.521-15.841,57.355-0.006l81.698,81.699L336.037,67.064
																																c15.841-15.841,41.523-15.829,57.358,0C409.23,82.902,409.23,108.578,393.401,124.425z"
				/>
			</svg>
			<p>{{ successMsg }}</p>
		</div>

		<ImageUpload
			:item="userInfo"
			image="image"
			cid="u_avatar"
			label="Picture"
			width="40"
			height="40"
			:round="true"
			:maxHeight="300"
			:maxWidth="300"
			@setImage="submitChanges"
		></ImageUpload>

		<form
			class="full-block"
			autocomplete="off"
		>
			<!-- firstName -->
			<div class="input-block">
				<f7-input
					:value="userInfo.firstName"
					inline-labels
					type="text"
					@input="addFirstName"
					floating-label
					placeholder="First name"
					maxlength="14"
					class="item-content item-input input-full"
				>
				</f7-input>
			</div>

			<!-- lastName -->
			<div class="input-block">
				<f7-input
					:value="userInfo.lastName"
					inline-labels
					type="text"
					@input="addLastName"
					floating-label
					placeholder="Last name"
					maxlength="14"
					class="item-content item-input input-full"
				>
				</f7-input>
			</div>
			<!-- <div class="showbd-checkbox"> -->

			<!-- datepicker -->
			<div class="input-block">
				<div class="svg-wrapper">
					<svg
						fill="currentcolor"
						viewBox="0 0 216.374 216.374"
					>
						<path
							d="M207.374,30.135h-25.438V13.432c0-4.971-4.029-9-9-9s-9,4.029-9,9v16.703H52.438V13.432c0-4.971-4.029-9-9-9s-9,4.029-9,9
                            v16.703H9c-4.971,0-9,4.029-9,9v163.808c0,4.971,4.029,9,9,9h198.374c4.971,0,9-4.029,9-9V39.135
                            C216.374,34.164,212.345,30.135,207.374,30.135z M198.374,48.135v19.997H18V48.135H198.374z M18,193.942V86.132h180.374v107.811H18
                            z"
						/>
					</svg>
				</div>
				<!-- v-if="typeof birthdayRightValue() !== 'undefined' && birthdayRightValue() !== null" -->
				<!-- v-if="1" -->
				<f7-input
					v-if="userInfo.birthday !== undefined && userInfo.birthday"
					ref="dateinput"
					type="datepicker"
					class="input__1"
					@change="addUserInfoBirthday"
					:value="[transformedUserBirthday]"
					:calendar-params="{
						disabled: {
							from: new Date(Date.now()),
						},
						// header: true,
						// minDate: new Date('12-18-1930'),

						openIn: 'customModal',
						closeOnSelect: true,
						footer: true,
						direction: 'vertical',
						backdrop: 'true',
						dateFormat: 'mm/dd/yyyy',
					}"
				>
				</f7-input>

				<f7-input
					v-else
					ref="dateinput"
					type="datepicker"
					@change="addUserInfoBirthday"
					class="input__2"
					placeholder="Date of Birth"
					:calendar-params="{
						disabled: {
							from: new Date(Date.now()),
						},
						openIn: 'customModal',
						closeOnSelect: true,
						// header: true,
						footer: true,
						// direction: 'horizontal',
						direction: 'vertical',
						backdrop: 'true',
						dateFormat: 'mm/dd/yyyy',
					}"
				></f7-input>
			</div>

			<div class="input-block email">
				<div class="svg-wrapper">
					<svg
						fill="currentcolor"
						id="envelope"
						viewBox="0 0 490 490"
					>
						<path
							d="M479.574,78.191H10.425c-0.071,0-0.141,0.007-0.211,0.008l0.001,0.003C4.556,78.315,0,82.925,0,88.617v312.766
                        c0,5.762,4.669,10.426,10.425,10.426h469.149c5.758,0,10.426-4.664,10.426-10.426V88.617C490,82.854,485.332,78.191,479.574,78.191z
                         M454.407,99.043l-138.581,138.58c-0.002,0.002-0.004,0.003-0.006,0.005L245,308.449l-70.82-70.821
                        c-0.004-0.004-0.008-0.007-0.013-0.012L35.594,99.043H454.407z M20.851,113.784l131.215,131.215L20.851,376.215V113.784z
                         M35.594,390.957l131.215-131.216l70.821,70.821c3.629,3.629,9.303,5.439,14.743,0l70.82-70.82l131.215,131.215H35.594z
                         M469.149,376.213L337.935,244.999l131.214-131.214V376.213z"
						/>
					</svg>
				</div>
				<f7-input
					class="input"
					:value="userInfo.workEmail"
					@input="addEmail"
					label="E-mail"
					type="email"
					placeholder="Personal Email"
				>
				</f7-input>
			</div>

			<div class="input-block zip">
				<f7-input
					type="text"
					v-model="userInfo.zip"
					max-length="5"
					@input="addZIP($event)"
					pattern="\d{1,5}"
					maxlength="5"
					placeholder="ZIP"
				>
				</f7-input>
			</div>

			<!-- state -->
			<div class="input-block">
				<div class="svg-wrapper">
					<svg
						fill="currentcolor"
						viewBox="-96 0 512 512"
					>
						<path
							d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
						/>
					</svg>
				</div>

				<div
					smart-select
					class="item-link smart-select smart-select-init"
					data-open-in="popup"
					data-close-on-select="true"
					data-searchbar="true"
					data-searchbar-placeholder="Search state"
					data-searchbar-enable
				>
					<div class="item-content">
						<!-- <div class="item-content">
							<div class="item-inner"></div>
						</div> -->
						<f7-input
							type="text"
							placeholder="State"
							autocomplete="off"
							:value="this.userInfo.state"
						>
						</f7-input>
					</div>
					<select @change="selectState($event)">
						<option
							v-for="(state, index) in this.states"
							:key="index"
							:value="state.id"
						>
							{{ state.text }}
						</option>
					</select>
				</div>
			</div>

			<!-- city -->
			<div
				class="input-block city"
				:style="{ pointerEvents: pointerEvent }"
				:class="{ 'grey-svg': disabledCities }"
			>
				<div class="svg-wrapper">
					<svg
						fill="currentcolor"
						viewBox="-96 0 512 512"
					>
						<path
							d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
						/>
					</svg>
				</div>

				<div
					smart-select
					class="item-link smart-select smart-select-init"
					data-open-in="popup"
					data-close-on-select="true"
					data-searchbar="true"
					data-searchbar-placeholder="Search city"
				>
					<div class="item-content">
						<!-- <div class="item-content">
							<div class="item-inner"></div>
						</div> -->
						<f7-input
							:class="{ 'disable-touching': disabledCities }"
							type="text"
							placeholder="City"
							autocomplete="off"
							:value="this.userInfo.city"
						>
						</f7-input>
					</div>
					<select
						name="city"
						@change="selectCity($event)"
					>
						<option
							v-for="(city, index) in this.cities"
							:key="index"
							:value="city.id"
						>
							{{ city.text }}
						</option>
					</select>
				</div>
			</div>

			<div class="input-block adress1">
				<f7-input
					:value="userInfo.address1"
					@input="addAddress1"
					type="text"
					placeholder="Address1"
				>
					<template v-slot:media>
						<f7-icon icon="demo-list-icon"></f7-icon>
					</template>
				</f7-input>
			</div>

			<div class="input-block adress2">
				<f7-input
					:value="userInfo.address2"
					@input="addAddress2"
					type="text"
					placeholder="Address2"
				>
					<template v-slot:media>
						<f7-icon icon="demo-list-icon"></f7-icon>
					</template>
				</f7-input>
			</div>

			<div class="input-block jobtitle">
				<f7-input
					class="disable-touching"
					:value="userInfo.jobTitle"
					label="Profession"
					type="text"
					placeholder="Your profession"
					input="false"
				>
					<template v-slot:media>
						<f7-icon icon="demo-list-icon"></f7-icon>
					</template>
				</f7-input>
			</div>
		</form>

		<div class="submit-button">
			<f7-button
				class="submit button-fill button-round"
				@click="submitChanges"
			>
				Update</f7-button
			>
		</div>
	</f7-page>
</template>

<script>
	import { APP_SERVICE } from "@/api/app";
	import ImageUpload from "@/components/edit-profile/img_upload.vue";

	export default {
		created() {},
		data() {
			return {
				pointerEvent: "none",
				disabledCities: true,
				userInfo: {},
				states: [],
				cities: [],

				successMsg: "Successfully saved",
				successMsgIsOpened: false,
			};
		},
		components: {
			ImageUpload,
		},
		watch: {
			"userInfo.state"() {
				if (this.userInfo.state?.length > 0) {
					this.enableAllCities();
				}
			},

			userInfo() {
				this.userInfo.state?.length > 0 ? this.enableAllCities() : this.disableAllCities();
			},

			stateUserInfo() {
				this.userInfo = JSON.parse(JSON.stringify(this.stateUserInfo));
			},
		},
		mounted() {
			this.$store.dispatch("app/editStaff").then(({ status, response }) => {
				// this.responsePoint = JSON.parse(JSON.stringify(response.data));

				if (this.userInfo.birthday === undefined) {
					this.userInfo.birthday = null;
				}

				this.getStates();

				if (this.userInfo.state?.length > 0) {
					this.getCitiesByState(this.userInfo.state);
				}
			});
		},

		methods: {
			activateMsg() {
				this.successMsgIsOpened = true;
				setTimeout(() => {
					this.successMsgIsOpened = false;
				}, 2000);
			},

			selectState(e) {
				this.userInfo.state = e.target.value;

				this.userInfo.city = "";
				this.getCitiesByState(this.userInfo.state);
			},
			selectCity(e) {
				this.userInfo.city = e.target.value;
			},

			disableAllCities() {
				this.pointerEvent = "none";
				this.disabledCities = true;
			},
			enableAllCities() {
				this.pointerEvent = "all";
				this.disabledCities = false;
			},

			addZIP(e) {
				let x = e.target.value;

				this.userInfo.zip = x.replace(/[^\d]+/g, "");
				if (this.userInfo.zip.length > 4) {
					this.getStateByZIP();
				}
			},

			addEmail(e) {
				this.userInfo.workEmail = e.target.value;
			},

			addUserInfoBirthday(e) {
				this.userInfo.birthday = e.target.value.split("/").join("-").split(" ").join("");
			},

			addAddress1(e) {
				let x = e.target.value;

				x.replace(/\b[a-z]|['_][a-z]|\B[A-Z]/g, function (y) {
					return y[0] === "'" || y[0] === "_" ? y : String.fromCharCode(x.charCodeAt(0) ^ 32);
				});

				this.userInfo.address1 = x;
			},

			addAddress2(e) {
				var x = e.target.value;

				this.userInfo.address2 = x;
			},

			addFirstName(e) {
				let x = e.target.value;

				if (x) {
					let val = x.split("");
					let fl = val[0].toUpperCase();
					val[0] = fl;
					x = val.join("");
				}

				this.userInfo.firstName = x;
			},
			addLastName(e) {
				let x = e.target.value;

				if (x) {
					let val = x.split("");
					let fl = val[0].toUpperCase();
					val[0] = fl;
					x = val.join("");
				}

				this.userInfo.lastName = x;
			},

			async getStates() {
				const { response, status } = await APP_SERVICE.getStates();

				if (status) {
					this.states = response.data.results;
				}
			},

			async getCitiesByState(payload) {
				const { response, status } = await APP_SERVICE.getCitiesByState(payload);

				if (status) {
					this.cities = response.data.results;
					this.enableAllCities();
				}
			},

			async getStateByZIP() {
				const { response, status } = await APP_SERVICE.getStateByZIP({
					search: this.userInfo.zip,
				});

				if (response.data) {
					this.userInfo.state = response.data.state;
					this.userInfo.city = response.data.city;
				}
			},

			async submitChanges() {
				// if (this.userInfo.image.length < 100) {
				// 	this.payloadPoint = JSON.parse(JSON.stringify(this.userInfo));
				// }

				const { status, response } = await this.$store.dispatch("app/editStaff", {
					...this.userInfo,
				});

				if (status) {
					this.activateMsg();
				}
			},
		},
		computed: {
			stateUserInfo() {
				return this.$store.state.app.userInfo;
			},

			transformedUserBirthday() {
				if (this.userInfo.birthday) {
					return new Date(this.userInfo.birthday.split("-").join("/"));
				}
			},
		},
	};
</script>

<style lang="scss">
	@import "@/sass/_vars.scss";

	.showbd-checkbox {
		display: flex;
		// width: 200px;
		padding: 5px;
		font-size: 16px;
		input {
			&:checked {
				background-color: $primary-color-500 !important;
				color: $primary-color-500 !important;
			}
			width: 20px !important;
		}
		margin: 12px auto;
		background-color: $primary-color-500;
		border-radius: 0.5rem;
		border: 1px solid $color-info;
		color: white;
		// margin-inline: auto;
	}

	.userbdaytest {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translatey(-50%);
		background-color: green;
		color: burlywood;
		font-size: 10px;
	}

	// .input__1 {
	// 	border: $amber solid 3px;
	// }
	// .input__2 {
	// 	border: $color-success solid 3px;
	// }

	.calendar-day {
		color: var(--primary-font-color);
	}

	.calendar-day-prev,
	.calendar-day-next {
		color: $greyscale-color-600;
	}

	.calendar,
	.calendar-footer,
	.calendar-header,
	.calendar .toolbar {
		a,
		.calendar-selected-date {
			color: var(--primary-font-color) !important;
		}
		// background: var(--page-bg-color);
		background: var(--card-bg-color);
	}

	.calendar-day.calendar-day-selected .calendar-day-number {
		background-color: $primary-color-500;
	}

	.calendar-year-picker-item-current,
	.calendar-month-picker-item-current {
		color: rgb(15, 118, 188) !important;
	}

	.popup {
		height: 100% !important;
		width: 100% !important;
		max-width: 500px !important;
		top: 0 !important;
		left: 50% !important;
		margin: 0 !important;

		.page-content {
			.list {
				margin: 0;
			}
		}
	}

	.popup:not(.popup-tablet-fullscreen).modal-in {
		transform: translate3d(-50%, 0, 0) !important;
	}
</style>
