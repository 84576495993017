<template>
	<div class="main-error-handler">
		<div
			class="container"
			style="font-size: 14px"
		>
			<div style="width: 100%; align-self: flex-start; max-width: 450px">
				<div class="title">ERRORINFO DATA</div>
				<div style="text-align: left; width: 100%; word-wrap: break-word">
					<div>
						{{ errorInfo.data }}
					</div>
				</div>
				<div
					style="margin-top: 15px"
					class="title"
				></div>
				OPTIONS
			</div>
			<div style="margin-bottom: 15px; overflow-wrap: break-word">{{ allOptions }}</div>
			<div class="title">PAYLOAD</div>
			<div style="margin-bottom: 15px">{{ payload }}</div>
			<div class="button-wrapper">
				<f7-button
					v-if="errorInfo.url === 'dashboard'"
					@click="this.$store.dispatch('logout')"
					class="button"
				>
					{{ buttonText }}</f7-button
				>
				<f7-button
					@click="backToDashboard"
					class="button"
					v-else
				>
					{{ buttonText }}</f7-button
				>
			</div>
			<div>{{ msg }}</div>
			<div
				v-if="errorMsg"
				class="error-message"
			>
				{{ errorMsg }}
			</div>

			<!-- <div
				v-for="(data, index) in JSON.parse(errorInfo.data)"
				:key="index"
			>
				{{ data }}
			</div> -->

			<!-- <div
				v-for="(key, index) in Object.keys(errorInfo)"
				:key="key"
			>
				{{ key }} + {{ errorInfo[key] }}
			</div> -->
			<div
				class="error-block"
				style="text-align: left"
			>
				<div
					v-if="errorInfo"
					style="padding: 10px"
				>
					{{ errorInfo?.baseURL }}/{{ errorInfo?.url }}
				</div>
				<!-- <div>{{ JSON.parse(errorInfo.data) }}</div> -->
				<!-- <div>{{ errorInfo?.data }}</div> -->
				<!-- <div>{{ errorInfo.headers }}</div> -->
				<!-- <div
					v-if="errorInfo"
					v-for="(key, index) in Object.keys(errorInfo?.headers)"
					:key="key"
				>
					{{ key }} : {{ errorInfo?.headers[key] }}
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		unmounted() {},
		props: {},
		data() {
			return {
				secondsCounter: 7,
				interval: 0,
			};
		},
		mounted() {
			// this.startCountdown();
		},
		updated() {},
		watch: {
			secondsCounter() {
				if (this.secondsCounter <= 0) {
					clearInterval(this.interval);
					if (this.errorInfo?.url === "dashboard") {
						this.$store.dispatch("logout");
					} else {
						this.backToDashboard();
					}
				}
			},
		},
		methods: {
			backToDashboard() {
				this.$store.dispatch("closeErrorHandlingModal");
				f7.views.main.router.navigate("/");
			},

			startCountdown: function () {
				this.interval = setInterval(() => {
					this.decrement();
				}, 1000);
			},

			decrement() {
				this.secondsCounter -= 1;
			},

			exitApp() {
				// console.log(navigator);
				navigator.app.exitApp();
			},
			// back() {
			// 	// console.log('backed');
			// 	f7.views.main.router.navigate("/login", {
			// 		reloadAll: true,
			// 	});
			// },
			// logout: function () {
			// 	this.$store.commit("auth/logout");
			// 	this.$store.commit("app/hideToolbars");
			// 	f7.views.main.router.navigate("/login", {
			// 		reloadAll: true,
			// 	});
			// },
		},

		computed: {
			redirectingTo() {
				if (this.errorInfo?.url === "dashboard") return "the loading page";
				return "dashboard";
			},

			msg() {
				return `Something went wrong, you will be automatically redirected to ${this.redirectingTo} in ${this.secondsCounter} seconds, or click the button.`;
			},
			errorMsg() {
				return this.$store.getters["getErrorMessage"];
			},
			errorInfo() {
				return this.$store.getters["getErrorInfo"];
			},
			allOptions() {
				return this.$store.getters["getAllOptions"];
			},
			payload() {
				return this.$store.getters["getPayload"];
			},
			buttonText() {
				return this.$store.getters["getButtonText"];
			},
		},
	};
</script>

<style scoped lang="scss">
	@import "@/sass/_vars.scss";
	.main-error-handler {
		height: 100%;
		max-width: $max-width;
		width: 100%;
		background-color: var(--page-bg-color);
		position: absolute;

		z-index: 99999;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		right: 0;

		overflow: scroll;
		font-size: 16px;
	}

	.container {
		// height: 100%;
		max-width: 100%;

		margin: auto 24px;
		display: flex;
		flex-direction: column;
		// align-items: center;
		justify-content: center;
	}

	.button-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;

		max-width: 100%;
		margin-bottom: 15px;
		// padding: 30px;

		a {
			// height: inherit;
			height: 100%;
			white-space: pre-wrap;
			padding: 10px;
			font-size: 25px;
			letter-spacing: 2px;
			margin: 10px 0;
			color: white !important;
			background-color: #0071ba;
			// display: inline-block;
			text-align: center;
		}
	}

	.error-message {
		font-size: 20px;
		color: $color-error;
		margin: 24px;
	}

	// div {
	// 	text-align: center;
	// }
</style>
