<template>
	<f7-page class="handbook">
		<!-- @click="removeFocus" -->
		<BackButton title="HIPAA Handbook" />

		<div
			class="input-container"
			id="input-container"
			ref="input-container"
			:style="{
				width: width,
			}">
			<f7-input
				autocomplete="false"
				name="hidden"
				type="text"
				style="display: none"
			></f7-input>
			<f7-input
				v-model:value="searchingStr"
				type="text"
				placeholder="Search here"
				input-id="search"
        autocomplete="off"
				clear-button>
				<template v-slot:media></template>
			</f7-input>
			<div
				class="input-sign"
				@click="iconClick">
				<svg
					fill="none"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="m14.578 14.542c1.0029-1.0119 1.6224-2.4046 1.6224-3.9419 0-3.0928-2.5072-5.6-5.6-5.6-3.0928 0-5.6 2.5072-5.6 5.6 0 3.0928 2.5072 5.6 5.6 5.6 1.5555 0 2.9628-0.6342 3.9776-1.6581zm0 0 4.4224 4.4581"
						stroke="currentcolor"
						stroke-linecap="round"
						stroke-linejoin="round" />
				</svg>
			</div>
		</div>

		<HandbookList
			:handbook="allHandbookPractices"
			v-if="!searchingList" />

		<HandbookProceduresList
			v-else-if="searchingList?.length > 0"
			:procedures="searchingList" />

		<div
			v-else
			style="margin: 24px; font-size: 16px">
			There is nothing...
		</div>
	</f7-page>
</template>

<script>
	import HandbookList from "./HandbookList.vue";
	import { mapGetters } from "vuex";
	import HandbookProceduresList from "./HandbookProceduresList.vue";

	import helper from "@/plugin/helper";

	export default {
		watch: {
			searchingStr(newVal) {
				this.searchingStr = newVal.toLowerCase();
			},
		},

		data() {
			return {
				searchingStr: "",
				isActiveSearch: false,
			};
		},

		beforeCreate() {
			this.$store.dispatch("handbook/getHandbook");
		},

		mounted() {
			this.$el.addEventListener("click", () => {
				if (this.$refs["input-container"]) {
					this.removeFocus();
				}
			});
		},

		methods: {
			removeFocus() {
				this.$refs["input-container"].classList.remove("active");
				document.querySelector("#search").blur();
				this.isActiveSearch = false;
			},

			addFocus() {
				this.$refs["input-container"].classList.add("active");
				document.querySelector("#search").focus();
				this.isActiveSearch = true;
			},

			iconClick: function (e) {
				e.stopPropagation();

				if (!this.isActiveSearch) {
					this.addFocus();
				} else {
					this.removeFocus();
				}
			},
		},

		computed: {
			width: function () {
				let v = window.innerWidth;
				if (v > 500) v = 500;

				if (this.isActiveSearch) {
					return `${v - 48}px`;
				}

				return "40px";
			},

			...mapGetters("handbook", ["allHandbookPractices", "allHandbookProcedures"]),

			searchingList: function () {
				if (!this.searchingStr) return false;

				return this.allHandbookProcedures?.filter(i =>
					helper.filterHandbookProcedures(i, this.searchingStr)
				);
			},
		},
		components: { HandbookList, HandbookProceduresList },
	};
</script>

<style></style>
