import { APP_SERVICE } from "@/api/app";
import LS from "@/plugin/localstorage";
import helper from "@/plugin/helper";

export default {
	namespaced: true,
	state: {
		userInfo: LS.get("userInfo") || {},
		totals: {},

		directory: LS.get("directory") || false,
		practiceInfo: LS.get("practice-info") || null,
		simpleTrainings: LS.get("simpleTrainings") || [],

		notifications: null || LS.get("notifications"),
		currentNotification: null || LS.get("currentNotification"),

		alertsCount: null,
		currentPage: "home",
		showToolbars: false,
		showPracticeVerificationSuccessScreen: false,

		tokenLifeTimeMins: 32,
	},
	getters: {
		showToolbars(state) {
			return state.showToolbars;
		},

		tokenLifeTime(state) {
			return state.tokenLifeTimeMins;
		},

		totals(state) {
			return state.totals;
		},

		userFullName(state) {
			return `${state.userInfo.firstName} ${state.userInfo.lastName}`;
		},

		userImage(state) {
			return state.userInfo.image;
		},

		alertsCount(state) {
			return state.alertsCount;
		},

		directory(state) {
			return state.directory;
		},

		getShowPracticeVerificationSuccessScreen(state) {
			return state.showPracticeVerificationSuccessScreen;
		},

		simpleTrainings(state) {
			return Object.values(state.simpleTrainings).filter(i => !Array.isArray(i));
		},

		designations(state) {
			let designations = state.simpleTrainings.actions;
			if (designations) {
				return helper.sortDesignations(designations);
			}
		},

		uncompleteTrainings({}, getters) {
			let publishedTrainings = getters.simpleTrainings.filter(i => i.total >= 0 && i.total !== i.done);
			let unpublishedTrainings = getters.simpleTrainings.filter(i => i.total === undefined);

			publishedTrainings.sort((a, b) => (a.priority > b.priority ? 1 : -1));
			unpublishedTrainings.sort((a, b) => (a.priority > b.priority ? 1 : -1));

			return [...publishedTrainings, ...unpublishedTrainings].slice(0, 3);
		},

		completeTrainings({}, getters) {
			return getters.simpleTrainings
				.filter(i => i.done / i.total === 1)
				.sort((a, b) => (a.priority > b.priority ? 1 : -1));
		},

		practiceInfo(state) {
			if (state.practiceInfo) return state.practiceInfo;
		},

		readNotifications(state) {
			if (state.notifications) return state.notifications.filter(i => i.done);
		},

		unreadNotifications(state) {
			if (state.notifications) return state.notifications.filter(i => !i.done);
		},
	},

	mutations: {
		setShowPracticeVerificationSuccessScreen(state, boolean) {
			state.showPracticeVerificationSuccessScreen = boolean;
		},

		hideToolbars(state) {
			state.showToolbars = false;
		},

		showToolbars(state) {
			state.showToolbars = true;
		},

		setCurrentPage(state, data) {
			state.currentPage = data;
		},

		setAlertsCount(state, data) {
			state.alertsCount = data;
		},

		setCurrentNotification(state, data) {
			state.currentNotification = data;
			LS.set("currentNotification", data);
		},

		setNotifications(state, data) {
			state.notifications = data;
			LS.set("notifications", data);
		},

		setSimpleTrainings(state, data) {
			state.simpleTrainings = data;
		},

		setTotals(state, data) {
			state.totals = data;
			LS.set("totals", state.totals);
		},

		setUserInfo(state, data) {
			state.userInfo = data;
			LS.set("userInfo", state.userInfo);
		},

		setDirectory(state, data) {
			state.directory = data;
			LS.set("directory", state.directory);
		},

		setPracticeInfo(state, data) {
			state.practiceInfo = data;
		},
	},

	actions: {
		setDashboardInfo({ commit }, data) {
			commit("setUserInfo", data.dashboard.userInfo);
			commit("setTotals", data.dashboard.handbook.totals);
			commit("setDirectory", data.dashboard.directory);
			commit("setSimpleTrainings", data.dashboard.training);
			commit("setAlertsCount", data.dashboard.alerts);
		},

		async editStaff({ commit, dispatch, rootState }, payload) {
			commit("loading", true, { root: true });

			const { response, status } = await APP_SERVICE.editStaff({
				id: rootState.auth.user.id,
				...payload,
			});

			if (status) commit("setUserInfo", response.data);
			else dispatch("openErrorHandlingModal", response, { root: true });

			commit("setPayload", payload, { root: true });
			commit("loading", false, { root: true });
			return { response, status };
		},

		async getDirectory({ commit, rootState }) {
			commit("loading", true, { root: true });
			const { response, status } = await APP_SERVICE.getDirectory({
				clientId: rootState.auth.user.id,
			});
			commit("setDirectory", response.data.items);
			commit("loading", false, { root: true });
			return status;
		},

		async getDashboard({ commit, rootState, dispatch }) {
			commit("loading", true, { root: true });

			const { response, status } = await APP_SERVICE.getDashboard({
				id: rootState.auth.user.id.toString(),
			});

			if (status) dispatch("setDashboardInfo", response.data);
			else dispatch("openErrorHandlingModal", response, { root: true });

			commit("loading", false, { root: true });
			return { response, status };
		},

		async setActionDone({ commit, dispatch, state, rootState }, payload) {
			commit("loading", true, { root: true });
			const { response, status } = await APP_SERVICE.setActionDone({
				clientId: rootState.app.userInfo.id,
				act: payload.actId,
				password: payload.adminPassword || undefined,
				email: payload.adminEmail || undefined,
			});
			commit("loading", false, { root: true });
			if (!status) return response.data;
			return { response, status };
		},

		async getNofifications({ commit, rootState, dispatch }) {
			commit("loading", true, { root: true });
			const { response, status } = await APP_SERVICE.getAlerts({
				clientId: rootState.auth.user.id,
			});

			if (status) commit("setNotifications", response.data);
			else dispatch("openErrorHandlingModal", response, { root: true });

			commit("loading", false, { root: true });
			return status;
		},

		async loadDef({ commit, rootState }) {
			const { response, status } = await APP_SERVICE.loadDef({
				id: rootState.auth.user.id,
			});
			LS.set("definitions", response.data);
		},

		async getPracticeInfo({ commit, rootState }) {
			commit("loading", true, { root: true });

			const { response, status } = await APP_SERVICE.getPracticeInfo({
				id: rootState.auth.user.id.toString(),
			});

			if (status) {
				commit("setPracticeInfo", response.data.practice);
				LS.set("practice-info", response.data.practice);
			}

			commit("loading", false, { root: true });
		},
	},
};
