<template>
	<div class="confirm-code">
		<Topbar />

		<BackButton
			:loginScreen="true"
			@backToPhone="backToPhone"
		/>

		<div class="input-container">
			<div class="input-wrapper">
				<div class="form-title">We just sent a pin to your phone. Enter it here to login.</div>
				<input
					type="text"
					id="code-input"
					maxlength="6"
					:value="code"
					pattern="^\d{1}$"
					style="position: absolute; left: -100%; top: -100%"
					@input="changeInput($event.target.value)"
					@focusin="focused"
				/>
				<div class="input-block">
					<label
						for="code-input"
						style="height: 100%; background-color: transparent; position: absolute; z-index: 1; top: 0; right: 0; left: 0"
					></label>
					<form class="code-inputs">
						<input
							disabled
							readonly
							class="code-input item-input"
							:class="item"
							pattern="^\d{1}$"
							maxlength="1"
							type="text"
							:value="inputs[index]"
							:ref="'input_' + item"
							v-for="(item, index) in maxLength"
							:key="item"
						/>
					</form>
					<div
						v-if="errMsg"
						class="error-msg"
					>
						{{ errMsg }}
					</div>
				</div>
			</div>
		</div>

		<Numericpad
			@addnumber="addNumber"
			@removenumber="removeNumber"
		/>
		<div class="submit-button verification-code">
			<f7-button
				class="submit"
				@click="verifyCode"
			>
				Continue</f7-button
			>
		</div>
	</div>
</template>

<script>
	import Numericpad from "@/components/authorization/numericpad.vue";

	export default {
		components: {
			Numericpad,
		},
		data() {
			return {
				maxLength: 6,
				errMsg: false,
				inputs: [],
				verificationSection: false,
				code: "",
			};
		},
		mounted() {
			document.querySelector("#code-input").focus();
		},
		watch: {
			code() {
				this.errMsg = false;
			},
		},
		methods: {
			focused() {
				// console.log("focused");
			},

			changeInput(val) {
				if (val) {
					let previuosEl = this.$refs["input_" + (val.length - 1)];
					let nextEl = this.$refs["input_" + (val.length + 1)];

					let prevInput;
					let nextInput;

					if (previuosEl) {
						prevInput = previuosEl[0];
					}
					if (nextEl) {
						nextInput = nextEl[0];
					}

					let cur = this.$refs["input_" + val.length][0];

					if (val.length > this.code.length) {
						for (let i = 0; i < val.length; i++) {
							const number = val[i];
							this.inputs[i] = number;
						}

						if (val.length > 2) {
							for (const el of Object.values(this.$refs)) {
								setTimeout(() => {
									if (el[0]) {
										el[0].type = "password";
									}
								}, 1500);
							}
						} else {
							if (prevInput) {
								prevInput.type = "password";
							}

							setTimeout(() => {
								cur.type = "password";
							}, 1000);
						}
					}
					if (val.length < this.code.length || !val) {
						this.inputs.pop();
						cur.type = "text";
						nextInput.type = "text";
					}
				}

				if (!val) {
					this.code = "";
					this.inputs.pop();
					return;
				}

				this.code = val;

				if (val.length >= this.maxLength) {
					document.querySelector("#code-input").blur();
					return;
				}
				return;
			},

			backToPhone() {
				this.code = "";
				this.inputs = [];
				this.$emit("goBack");
			},

			addNumber(x) {
				if (this.code.length < this.maxLength) {
					let newCode = this.code + x;
					this.changeInput(newCode);
				}
			},

			removeNumber() {
				let newValue = this.code.slice(0, -1);
				this.changeInput(newValue);
			},

			async verifyCode() {
				const isLoginVerificationSuccess = await this.$store.dispatch("auth/loginCodeVerification", {
					phone: this.$store.state.auth.user.phone,
					info: navigator.userAgent,
					code: this.code,
				});

				if (isLoginVerificationSuccess === true) {
					this.$store
						.dispatch("auth/askForToken", {
							phone_info: navigator.userAgent,
							sms_log: this.$store.state.auth.user.smsLog,
							submit:
								this.$store.state.auth.user.smsLog.length === 1
									? this.$store.state.auth.user.smsLog[0]
									: this.$store.state.auth.user.submit,
						})
						.then((resp) => {
							if (resp) {
								this.$store.dispatch("login", {
									token: resp.token,
									id: resp.id,
								});
							} else {
								this.$emit("practiceChoice");
							}
							this.code = "";
							this.inputs = [];
						});
				} else {
					this.errMsg = isLoginVerificationSuccess || "There is no verification codes in sms_log. Please contact your administrator";
					setTimeout(() => {
						this.backToPhone();
					}, 3500);
				}
			},
		},
	};
</script>
