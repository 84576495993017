import { APP_SERVICE } from "@/api/app";
import LS from "@/plugin/localstorage";
import helper from "@/plugin/helper";

export default {
	namespaced: true,
	state: {
		trainings: undefined || JSON.parse(LS.get("trainings")),
		trainingId: undefined || JSON.parse(LS.get("trainingId")),
		actionId: undefined || JSON.parse(LS.get("actionId")),

		designations: undefined || JSON.parse(LS.get("designations")),
		designationId: undefined || JSON.parse(LS.get("designationId")),

		redirectingLimit: 0,
	},

	mutations: {
		clearLimit(state) {
			state.redirectingLimit = 0;
		},

		setLimit(state) {
			state.redirectingLimit = 1;
		},

		setTrainings(state, data) {
			LS.set("trainings", JSON.stringify(data));
			state.trainings = data;
		},
		setTrainingId(state, data) {
			LS.set("trainingId", JSON.stringify(data));
			state.trainingId = data;
		},
		setActionId(state, data) {
			LS.set("actionId", JSON.stringify(data));
			state.actionId = data;
		},

		setDesignations(state, data) {
			LS.set("designation", JSON.stringify(data));
			state.designations = data;
		},
		setDesignationId(state, data) {
			LS.set("designationId", JSON.stringify(data));
			state.designationId = data;
		},
	},

	actions: {
		// ? OLD VERSION
		// async getTrainings({ commit, rootState }, trainingId = undefined) {
		// 	commit("loading", true, { root: true });

		// 	const { response, status } = await APP_SERVICE.getTrainings({
		// 		clientId: rootState.auth.user.id,
		// 		Autorization: rootState.auth.user.token,
		// 		trainingId: trainingId,
		// 	});

		// ? NEW VERSION
		async getTrainings({ commit, rootState, dispatch }, getters) {
			commit("loading", true, { root: true });

			const { response, status } = await APP_SERVICE.getTrainings({
				// ! TEST with code below instead of user.id
				// id: rootState.auth.user.id,
				clientId: rootState.auth.user.id,
				// Autorization: rootState.auth.user.token,
			});

			if (status) {
				commit("setTrainings", response.data);
			} else {
				dispatch("openErrorHandlingModal", response, { root: true });
			}

			commit("loading", false, { root: true });
			return response.data;
		},
	},

	getters: {
		trainings(state) {
			let trainings = state.trainings;

			if (trainings && typeof trainings === "object") {
				trainings = Object.values(state.trainings);
			}
			if (trainings) {
				return trainings.filter((item) => !Array.isArray(item)).sort((a, b) => (a.priority > b.priority ? 1 : -1));
			}
		},

		publishedTrainings({}, getters) {
			const trainings = getters.trainings;
			if (trainings) {
				return trainings.filter((i) => i.total !== undefined && i.total > 0);
			}
		},

		unpublishedTrainings({}, getters) {
			const trainings = getters.trainings;
			if (trainings) {
				return trainings.filter((i) => i.total === undefined);
			}
		},

		designations(state) {
			if (state.trainings?.actions) {
				return state.trainings.actions;
			}
		},

		sortedDesignations(state, getters) {
			if (getters.designations) {
				return helper.sortDesignations(getters.designations);
			}
		},

		currentTraining(state, getters) {
			if (getters.trainings) {
				return getters.trainings.find((trainings) => trainings.id === state.trainingId);
			}
		},

		currentDesignation(state, getters) {
			if (getters.designations) {
				return getters.designations.find((i) => i.id === state.designationId);
			}
		},

		trainingAction(state, getters) {
			if (getters.currentTraining) {
				return getters.currentTraining.actions.find((action) => action.id === state.actionId);
			}
		},

		agreementContent({}, getters) {
			if (getters.trainingAction) {
				return JSON.parse(getters.trainingAction.agreement.jsonData).paragraphs;
			}
		},

		agreementContentConfirm({}, getters) {
			if (getters.agreementContent) {
				let result = [];
				let acceptToAssign = false;

				for (let item of getters.agreementContent) {
					if (acceptToAssign) {
						result.push(item);
						continue;
					}

					if (item.children) acceptToAssign = true;
				}

				return result;
			}
		},

		agreementContentSwiper({}, getters) {
			if (getters.agreementContent) {
				return getters.agreementContent.find((i) => i.children);
			}
		},

		agreementContentDescription({}, getters) {
			if (getters.agreementContent) {
				let result = [];

				for (let item of getters.agreementContent) {
					result.push(item);

					if (item.children) break;
				}

				return result;
			}
		},

		trainingsAgreementParagraphToRender({}, getters) {
			if (getters.trainingAction) {
				return JSON.parse(getters.trainingAction.agreement.jsonData).paragraphs.find((i) => i.children);
			}
		},

		partOfArrayWithChildren({}, getters) {
			if (getters.trainingAction) {
				let result = [];
				let data = JSON.parse(getters.trainingAction.agreement.jsonData);

				for (let item of data.paragraphs) {
					if (!item.children) {
						result.push(item);
					} else {
						result.push(item);
						break;
					}
				}

				return result;
			}
		},

		uncompleteTrainings({}, getters) {
			if (getters.trainings) {
				let array = getters.trainings.filter((i) => i.done / i.total !== 1 && !Array.isArray(i));
				array.sort((a, b) => (a.priority > b.priority ? 1 : -1));
				return array.slice(0, 3);
			}
		},

		completeTrainings({}, getters) {
			if (getters.trainings) {
				return getters.trainings.filter((i) => i.done / i.total === 1);
			}
		},
	},
};
