import { AXIOS } from "@/plugin/axios";

export const APP_SERVICE = {
	async getDashboard(params) {
		return await AXIOS.post("dashboard", params);
	},
	async editStaff(params) {
		return await AXIOS.post("edit-staff", params);
	},
	async getStates() {
		return await AXIOS.post("getState");
	},
	async getCities() {
		return await AXIOS.post("getCity");
	},
	async getCitiesByState(params) {
		return await AXIOS.post(`getCity?state=${params}`);
	},
	async getStateByZIP(params) {
		return await AXIOS.post("getZip", params);
	},
	async getDirectory(params) {
		return await AXIOS.post("get-directory", params);
	},
	async getHandbook(params) {
		return await AXIOS.post("get-handbook", params);
	},
	async getHandbookProcedures(params) {
		return await AXIOS.post("get-handbook-procedures", params);
	},
	async getShowProcedures(params) {
		return await AXIOS.post("show-procedure", params);
	},
	async setActionDone(params) {
		return await AXIOS.post("employee-action-done", params);
	},
	async getTrainings(params) {
		return await AXIOS.post("get-employee-actions", params);
	},
	async getAlerts(params) {
		return await AXIOS.post("get-employee-alerts", params);
	},
	async loadDef(params) {
		return await AXIOS.post("load-def", params);
	},
	async getPracticeInfo(params) {
		return await AXIOS.post("practice-info", params);
	},
};
