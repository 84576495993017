<template>
	<f7-page class="notifications-info">
		<div class="notifications-info-main-icon">
			<div
				class="svg-wrapper"
				v-if="currentNotification.securityAlert.urgency === 1">
				<svg
					viewBox="0 0 24 24"
					id="exclamation-triangle">
					<path
						class="cls-1"
						d="M12.13,3.41h-.26a1.48,1.48,0,0,0-1.26.7L1.73,18.32a1.48,1.48,0,0,0-.23.79h0A1.48,1.48,0,0,0,3,20.59H21a1.48,1.48,0,0,0,1.48-1.48h0a1.48,1.48,0,0,0-.23-.79L13.39,4.11A1.48,1.48,0,0,0,12.13,3.41Z" />
					<line
						class="cls-1"
						x1="12"
						y1="8.18"
						x2="12"
						y2="13.91" />
					<line
						class="cls-1"
						x1="12"
						y1="15.82"
						x2="12"
						y2="17.73" />
				</svg>
			</div>

			<div
				class="svg-wrapper"
				v-if="currentNotification.securityAlert.urgency === 0">
				<svg
					fill="currentcolor"
					id="bell"
					viewBox="0 0 227.805 227.805">
					<path
						d="M200.551,194.476c-8.234-7.774-27.387-30.341-27.387-71.894V100.01c0-24.913-15.461-46.269-37.285-55.018
                                                c3.691-4.728,5.898-10.667,5.898-17.117C141.777,12.505,129.272,0,113.902,0S86.027,12.505,86.027,27.875
                                                c0,6.449,2.208,12.39,5.899,17.118c-21.823,8.749-37.282,30.105-37.282,55.017v22.572c0,41.545-19.155,64.118-27.392,71.895
                                                c-2.233,2.109-2.955,5.368-1.82,8.224c1.136,2.855,3.897,4.729,6.97,4.729h54.658c3.285,11.738,14.07,20.375,26.842,20.375
                                                s23.557-8.637,26.842-20.375h54.658c3.073,0,5.835-1.874,6.97-4.73C203.507,199.844,202.785,196.585,200.551,194.476z M113.902,15
                                                c7.1,0,12.875,5.776,12.875,12.875c0,7.099-5.775,12.875-12.875,12.875c-7.1,0-12.875-5.776-12.875-12.875
                                                C101.027,20.776,106.803,15,113.902,15z M113.902,212.805c-4.3,0-8.104-2.127-10.443-5.375h20.887
                                                C122.006,210.678,118.202,212.805,113.902,212.805z M134.297,192.43c-0.007,0-0.013-0.001-0.02-0.001
                                                c-0.007,0-0.013,0.001-0.02,0.001H93.547c-0.007,0-0.013-0.001-0.02-0.001c-0.007,0-0.013,0.001-0.02,0.001H48.742
                                                c9.907-13.845,20.902-36.686,20.902-69.848V100.01c0-24.405,19.854-44.26,44.26-44.26c24.405,0,44.26,19.855,44.26,44.26v22.572
                                                c0,33.165,10.992,56.004,20.897,69.848H134.297z" />
				</svg>
			</div>
		</div>
		<BackButton />

		<div class="notifications-info-main-block">
			<div class="notifications-info-title main-title">
				{{ currentNotification.securityAlert.subject }}
			</div>

			<CreatedBy
				:createdBy="currentNotification.securityAlert.createdBy"
				:createdAt="currentNotification.securityAlert.updatedAt"
				reason="" />

			<!-- <div class="alerts-info-description">
				<div class="alerts-info-img-wrapper"
						 v-if="currentAlert.securityAlert.createdBy.image">
					<img :src="currentAlert.securityAlert.createdBy.image"
							 alt="">
				</div>
				<div v-else
						 class="alerts-info-icon-wrapper">
					<svg viewBox="0 0 512 512"
							 fill="currentcolor">
						<path
									d="M332.64,64.58C313.18,43.57,286,32,256,32c-30.16,0-57.43,11.5-76.8,32.38-19.58,21.11-29.12,49.8-26.88,80.78C156.76,206.28,203.27,256,256,256s99.16-49.71,103.67-110.82C361.94,114.48,352.34,85.85,332.64,64.58Z" />
						<path
									d="M432,480H80A31,31,0,0,1,55.8,468.87c-6.5-7.77-9.12-18.38-7.18-29.11C57.06,392.94,83.4,353.61,124.8,326c36.78-24.51,83.37-38,131.2-38s94.42,13.5,131.2,38c41.4,27.6,67.74,66.93,76.18,113.75,1.94,10.73-.68,21.34-7.18,29.11A31,31,0,0,1,432,480Z" />
					</svg>
				</div>

				<div class="alerts-info-created">
					<div v-html="currentAlert.securityAlert.createdBy.username"></div>
					<div>
						{{ transformDate(currentAlert.securityAlert.updatedAt) }}
					</div>
				</div>
				<div class="alerts-info-credentials"
						 v-if="currentAlert.credentials">
					<div class="cred-img-wrapper"
							 v-for="(cred, index) in currentAlert.credentials"
							 :key="index">
						<img :src="cred.image"
								 alt="" />
					</div>
				</div>
			</div> -->

			<div v-html="currentNotification.securityAlert.message"></div>

			<div class="notifications-info-resources">
				<div class="resources-name">Resources:</div>
				<div
					class="resources-link"
					v-for="(link, index) in parceResources(currentNotification.securityAlert.resources)"
					:key="index">
					<a
						:href="link.link"
						target="_blank"
						class="external">
						{{ link.title }}
					</a>
				</div>
			</div>

			<div class="confirm-block">
				<label
					class="checkbox card-shadow1"
					@change="agreeWithProcedure">
					<input type="checkbox" />
					<i class="icon-checkbox"> </i>
					<div class="text">
						I have reviewed this security alert as well as the included links.
						<div
							v-if="currentNotification.done"
							class="accepted">
							{{
								`You acknowledged on
												 					${transformDateAccepted(currentNotification.doneStaffMap[0].created)}`
							}}
						</div>
					</div>
				</label>
				<div
					class="submit-button"
					v-if="!currentNotification.done"
					@click="submit">
					<f7-button> Submit </f7-button>
				</div>
			</div>
			<!-- <div v-html="currentAlert.securityAlert.createdBy"></div> -->
			<!-- <div>{{ currentAlert }}</div> -->
		</div>

		<!-- <f7-popup class="demo-popup">
			<f7-page>
				<f7-navbar title="Popup Title">
					<f7-nav-right>
						<f7-link popup-close>Close</f7-link>
					</f7-nav-right>
				</f7-navbar>
				<f7-block>
					<p>Here comes popup. You can put here anything, even independent view with its own navigation. Also not, that
						by
						default popup looks a bit different on iPhone/iPod and iPad, on iPhone it is fullscreen.</p>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse faucibus mauris leo, eu bibendum neque
						congue non. Ut leo mauris, eleifend eu commodo a, egestas ac urna. Maecenas in lacus faucibus, viverra ipsum
						pulvinar, molestie arcu. Etiam lacinia venenatis dignissim. Suspendisse non nisl semper tellus malesuada
						suscipit eu et eros. Nulla eu enim quis quam elementum vulputate. Mauris ornare consequat nunc viverra
						pellentesque. Aenean semper eu massa sit amet aliquam. Integer et neque sed libero mollis elementum at vitae
						ligula. Vestibulum pharetra sed libero sed porttitor. Suspendisse a faucibus lectus.</p>
					<p>Duis ut mauris sollicitudin, venenatis nisi sed, luctus ligula. Phasellus blandit nisl ut lorem semper
						pharetra. Nullam tortor nibh, suscipit in consequat vel, feugiat sed quam. Nam risus libero, auctor vel
						tristique ac, malesuada ut ante. Sed molestie, est in eleifend sagittis, leo tortor ullamcorper erat, at
						vulputate eros sapien nec libero. Mauris dapibus laoreet nibh quis bibendum. Fusce dolor sem, suscipit in
						iaculis id, pharetra at urna. Pellentesque tempor congue massa quis faucibus. Vestibulum nunc eros,
						convallis
						blandit dui sit amet, gravida adipiscing libero.</p>
				</f7-block>
			</f7-page>
		</f7-popup> -->

		<!-- <div class="custom-popup">
			<div class="custom-popup-container">ASDASDASDASDASDASDASD</div>
		</div> -->
	</f7-page>
</template>

<script>
	import CreatedBy from "../CreatedBy.vue";
	import Popup from "../Popup.vue";
	import Topbar from "../topbar.vue";

	export default {
		data() {
			return {
				agreedWithAction: false,
			};
		},

		mounted() {
			if (this.currentNotification.done) {
				const checkbox = document.querySelector('input[type="checkbox"]');
				checkbox.checked = true;
				checkbox.disabled = true;
			}
		},

		methods: {
			transformDate(date) {
				date = new Date(date);
				const monthNames = [
					"January",
					"February",
					"March",
					"April",
					"May",
					"June",
					"July",
					"August",
					"September",
					"October",
					"November",
					"December",
				];
				return `${monthNames[date.getMonth()]} ${date.getMonth() + 1}, ${date.getFullYear()}`;
			},

			getAmPm(x) {
				if (x >= 12) {
					return "PM";
				}
				return "AM";
			},
			getHoursByAmPm(x) {
				if (x > 12) {
					return x - 12;
				}
				return x;
			},
			checkMinutes(x) {
				if (x < 10) {
					return "0";
				}
				return "";
			},
			transformDateAccepted(date) {
				// date = new Date(date);
				// let month = date.getMonth() + 1;
				// let day = date.getDate();
				// let year = date.getFullYear().toString().substr(-2);
				// if (month < 10) {
				// 	month = "0" + month;
				// }
				// if (day < 10) {
				// 	day = "0" + day;
				// }
				// return `${month}/${day}/${year} - ${this.getHoursByAmPm(date.getHours())}:${this.checkMinutes(
				// 	date.getMinutes()
				// )}${date.getMinutes()} ${this.getAmPm(date.getHours())} `;

				date = new Date(date);
				let month = date.getMonth() + 1;
				let day = date.getDate();
				let year = date.getFullYear().toString().substr(-2);
				if (month < 10) {
					month = "0" + month;
				}
				if (day < 10) {
					day = "0" + day;
				}
				return `${month}/${day}/${year}`;
			},

			parceResources(x) {
				let y = JSON.parse(x);
				return y;
			},

			agreeWithProcedure() {
				this.agreedWithAction = !this.agreedWithAction;
			},

			submit() {
				if (this.agreedWithAction && this.currentNotification.id && !this.currentNotification.done) {
					this.$store
						.dispatch("app/setActionDone", {
							actId: this.currentNotification.id,
						})
						.then(resp => {
							if (resp.status) {
								f7.views.main.router.navigate("/notifications");
							}
						});
				}
			},
		},
		computed: {
			// ...mapGetters('app',[]),

			currentNotification() {
				return this.$store.state.app.currentNotification;
			},
		},
		components: { Topbar, Popup, CreatedBy },
	};
</script>

<style lang="scss"></style>
