// Import Framework7 Bundle

// import "framework7/framework7.bundle.min.css";
// import "framework7/css/bundle/rtl";
import "framework7/css/bundle";
import "framework7-icons";

import topbar from "./components/topbar.vue";
import backbutton from "./components/GoBackButton.vue";

import Framework7 from "framework7/bundle";
import Framework7Vue, { registerComponents } from "framework7-vue/bundle";

import store from "./store";

import { createApp, h } from "vue";
import App from "./App.vue";

Framework7.use(Framework7Vue);

const app = createApp(App);
registerComponents(app);

app.use(store);
app.component("Topbar", topbar).component("BackButton", backbutton);
app.mount("#app");

import "./sass/main.scss";
