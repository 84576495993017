<template>
   <div class="directory-list">
      <div class="directory-list-wrapper">
         <div class="main-info-block">
            <div class="title">My Directory</div>
            <div class="link">
               <f7-link tab-link
                        view=".view-main"
                        href="/directory">See all
               </f7-link>
            </div>
         </div>
         <div class="list-wrapper">
            <f7-link view=".view-main"
                     href="/directoryinfo"
                     @click="toDetails(user)"
                     v-for="user,idx in listOfUsers"
                     :key="idx">
               <div v-if="user.image"
                    class="img-wrapper">
                  <img :src="user.image" />
               </div>
               <div v-else
                    class="img-wrapper">
                  <div class="icon">
                  </div>
               </div>
               <div class="name">
                  {{ user.name }} {{ user.where }}
               </div>
            </f7-link>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   data() {
      return {
         listOfUsers: [
            {
               name: 'John',
               where: 'USA',
            },
            {
               name: 'Will',
               where: 'USA',
            },
            {
               name: 'Gregory',
               where: 'USA',
            },
            {
               name: 'Steve',
               where: 'USA',
            },
            {
               name: 'Northon',
               where: 'USA',
            },
            {
               name: 'James',
               where: 'USA',
            },
         ] || this.directory


      }
   },
   props: ["directory"],
   mounted() { },
   computed: {
      // users() {
      //    return Object.values(this.directory).filter((i) => i.type === "user");
      // },
   },
   methods: {
      toDetails(info) {
         this.$store.commit("app/setCurrentItemInfo", info).then(() => {
            f7.views.main.router.navigate("/directoryinfo");
         });
      },
   },
};
</script>

<style>

</style>
