<template>
	<div class="created-by">
		<div class="created-by__wrapper">
			<div
				class="created-by__image-wrapper"
				v-if="createdBy?.image">
				<img
					:src="createdBy.image"
					alt="" />
			</div>
			<!-- v-else -->
			<div
				v-else
				class="created-by__svg-wrapper">
				<svg
					viewBox="0 0 512 512"
					fill="currentcolor">
					<path
						d="M332.64,64.58C313.18,43.57,286,32,256,32c-30.16,0-57.43,11.5-76.8,32.38-19.58,21.11-29.12,49.8-26.88,80.78C156.76,206.28,203.27,256,256,256s99.16-49.71,103.67-110.82C361.94,114.48,352.34,85.85,332.64,64.58Z" />
					<path
						d="M432,480H80A31,31,0,0,1,55.8,468.87c-6.5-7.77-9.12-18.38-7.18-29.11C57.06,392.94,83.4,353.61,124.8,326c36.78-24.51,83.37-38,131.2-38s94.42,13.5,131.2,38c41.4,27.6,67.74,66.93,76.18,113.75,1.94,10.73-.68,21.34-7.18,29.11A31,31,0,0,1,432,480Z" />
				</svg>
			</div>
			<div class="created-by__description">
				<div class="created-by__name">{{ createdBy?.username }}</div>
				<div
					class="created-by__time"
					v-if="!reason">
					{{ createdTime }}
				</div>
				<f7-link
					class="created-by__time"
					v-else
					@click="openPopup"
					>{{ createdTime }}</f7-link
				>
				<!-- <div class="created-by__time"></div> -->
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			createdAt: String,
			createdBy: Object,
			reason: String,
		},

		data() {
			return {
				popupTitle: "Version Note",
				monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
			};
		},

		computed: {
			createdTime() {
				let date = new Date(this.createdAt);
				return `${this.monthNames[date.getMonth()]} ${date.getMonth() + 1}, ${date.getFullYear()}`;
			},
		},

		methods: {
			openPopup() {
				let store = this.$store.state.app;
				// store.popupText;
				store.popupTitle = `${this.popupTitle}:`;
				store.popupText = this.reason;
				store.popupShow = true;
			},
		},
	};
</script>
<style lang="scss">
	@import "@/sass/_vars.scss";

	.created-by {
		&__wrapper {
			margin-top: 24px;
			display: flex;
			align-items: center;
		}

		&__image-wrapper,
		&__svg-wrapper {
			flex: 0 1 18%;
			max-height: 100%;
			aspect-ratio: 1/1;
			border-radius: 999px;
			min-width: 70px;
			// border: 1px solid black;
		}

		&__image-wrapper {
			display: flex;

			border-radius: 999px;
			flex: 0 1 16%;
			min-width: 50px;
			// max-width: 90px;
			height: 100%;

			aspect-ratio: 1/1;
			display: flex;
			// align-items: center;
			// justify-content: center;

			img {
				border-radius: 0;
				object-fit: cover;
				width: 100%;
			}
		}

		&__svg-wrapper {
			background-color: var(--primary-color) 0;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 65%;
				color: #fff;
			}
		}

		&__description {
			// flex-shrink: 1;
			flex-grow: 1;
			margin-left: 10px;
			align-self: stretch;

			display: flex;
			flex-direction: column;
			// align-items: center;
			justify-content: center;
		}

		&__name {
			font-size: 14px;
			// font-weight: 500;
			// margin-bottom: 5px;
			// flex-grow: 1;
		}

		&__time {
			font-size: 14px;

			&.link {
				display: inline-flex;
				align-self: start;
				text-decoration: underline;
				color: var(--primary-color) !important;
			}
		}
	}
</style>
