<template>
	<div class="procedures-list">
		<f7-link
			class="list-item card-shadow1"
			v-for="(procedure, index) in procedures"
			:key="index"
			@click="toCurrentProcedure(procedure.id, procedure.practiceId)"
			:class="{
				'not-published': procedure.actions[0]?.children?.length === 0 || !procedure.actions[0]?.children[0]?.Publish,
			}">
			<div class="img-wrapper">
				<svg
					viewBox="0 0 24 24"
					fill="none"
					v-if="procedure.actions[0]?.children?.length !== 0">
					<path
						d="M6 22H18C19.1046 22 20 21.1046 20 20V9.82843C20 9.29799 19.7893 8.78929 19.4142 8.41421L13.5858 2.58579C13.2107 2.21071 12.702 2 12.1716 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22Z"
						stroke="currentcolor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round" />
					<path
						d="M13 2.5V9H19"
						stroke="currentcolor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round" />
					<path
						d="M8 17H15"
						stroke="currentcolor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round" />
					<path
						d="M8 13H15"
						stroke="currentcolor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round" />
					<path
						d="M8 9H9"
						stroke="currentcolor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round" />
				</svg>

				<svg
					viewBox="0 0 330 330"
					xml:space="preserve"
					fill="currentcolor"
					v-else>
					<path
						d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85
                        S80.001,38.131,80.001,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M110.001,85
                        c0-30.327,24.673-55,54.999-55c30.327,0,55,24.673,55,55v45H110.001V85z" />
				</svg>

				<ItemDoneMark v-if="checkForDoneProcedure(procedure)" />

				<!-- <div
					class="img-wrapper-done"
					v-if="checkForDoneProcedure(procedure)"
				>
					<svg
						viewBox="0 0 405.272 405.272"
						fill="currentcolor"
					>
						<path
							d="M393.401,124.425L179.603,338.208c-15.832,15.835-41.514,15.835-57.361,0L11.878,227.836
                            c-15.838-15.835-15.838-41.52,0-57.358c15.841-15.841,41.521-15.841,57.355-0.006l81.698,81.699L336.037,67.064
                            c15.841-15.841,41.523-15.829,57.358,0C409.23,82.902,409.23,108.578,393.401,124.425z"
						/>
					</svg>
				</div> -->
			</div>

			<div class="description">
				<div class="name">{{ procedure.name }}</div>
				<div
					class="publisher"
					v-if="procedure.actions[0]?.children?.length === 0">
					{{
						`Waiting for ${
							procedure.publisher ||
							procedure.title ||
							currentPractice.publisher ||
							currentPractice.role.title
						} to publish`
					}}
				</div>
			</div>
		</f7-link>
	</div>
</template>
<script>
	import ItemDoneMark from "@/components/ItemDoneMark.vue";

	export default {
		props: {
			procedures: Array,
		},
		computed: {
			currentPractice() {
				return this.$store.getters["handbook/currentPractice"];
			},
		},
		methods: {
			checkForDoneProcedure(item) {
				if (item.actions[0]?.children[0]?.doneStaffMap?.length > 0) {
					return true;
				}
			},
			toCurrentProcedure(procedureId, practiceId) {
				this.$store.commit("handbook/setProcedureId", procedureId);
				let route = "handbook-procedure-info";

				if (f7router.currentRoute.name === "handbook") {
					route = `handbook-practice/${route}`;
				}
				if (practiceId) {
					this.$store.commit("handbook/setPracticeId", practiceId);
				}

				f7router.navigate(route);
			},
		},
		components: { ItemDoneMark },
	};
</script>
<style lang="scss"></style>
