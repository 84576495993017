<template>
	<f7-page
		class="destignation-page"
		v-if="currentDesignation"
	>
		<!-- <Topbar /> -->
		<BackButton @click="" />

		<div
			class="container"
			v-if="!accepted"
		>
			<!-- !TITLE! -->
			<div class="title main-title">{{ currentDesignation.action }}</div>

			<!--!USER INFO! -->
			<!-- <div class="user-info">
				<div class="img-wrapper"
						 v-if="stateUserInfo.image">
					<img :src="stateUserInfo.image"
							 alt="" />
				</div>
				<div class="svg-wrapper"
						 v-else>
					<svg viewBox="0 0 512 512"
							 fill="currentcolor">
						<path
									d="M332.64,64.58C313.18,43.57,286,32,256,32c-30.16,0-57.43,11.5-76.8,32.38-19.58,21.11-29.12,49.8-26.88,80.78C156.76,206.28,203.27,256,256,256s99.16-49.71,103.67-110.82C361.94,114.48,352.34,85.85,332.64,64.58Z" />
						<path
									d="M432,480H80A31,31,0,0,1,55.8,468.87c-6.5-7.77-9.12-18.38-7.18-29.11C57.06,392.94,83.4,353.61,124.8,326c36.78-24.51,83.37-38,131.2-38s94.42,13.5,131.2,38c41.4,27.6,67.74,66.93,76.18,113.75,1.94,10.73-.68,21.34-7.18,29.11A31,31,0,0,1,432,480Z" />
					</svg>
				</div>
				<div class="name">
					{{ `${stateUserInfo.firstName} ${stateUserInfo.lastName}` }}
				</div>
			</div> -->

			<!-- <p v-if="currentDesignation.official"> -->
			<!-- !TEXT! -->
			<div
				class="description card-shadow1"
				v-if="currentDesignation.role[1].toLowerCase() !== 'practice owner'"
			>
				<p>
					Hello
					{{ userFullName }}
				</p>
				<p>
					Thank you for you willingness to assist me in our practice's HIPAA compliance efforts. You are a valuable member of this team.
					Your efforts to understand and implement HIPAA standarts in our practice will help protect the privacy of our patients and their
					families.
				</p>

				<!-- ! SIGNATRURE -->
				<p style="">{{ currentDesignation.signature }}</p>
			</div>
			<!-- </p> -->

			<!-- <div v-html="currentDesignation.role[0]"></div> -->
			<!-- <div v-html="currentDesignation.role[1]"></div> -->
			<div
				v-html="currentDesignation.role[2]"
				class="duties"
			></div>
			<div v-html="currentDesignation.role[3]"></div>
			<div v-html="currentDesignation.role[4]"></div>

			<p
				v-if="!currentDesignation.official"
				class="description description2"
			>
				In order to perform the responsibilities of the
				{{ currentDesignation.role[1] }}, we'll need to elevate your Bite-Size HIPAA privileges to that of an ADMIN. Please create your admin
				account by providing a unique email address and password below. Once submitted, check your inbox, including your spam and junk
				folders, for a verification email from
				<span style="text-decoration: underline">donotreply@bitesizehipaa.com</span>
				and click on the included link.
			</p>

			<div
				v-if="!currentDesignation.official"
				class="inputs-block"
			>
				<p>Create ADMIN account:</p>
				<form action="">
					<div class="input-block">
						<!-- <f7-icon
							f7="envelope"
							class="input-icon"
							size="18px"
						></f7-icon> -->
						<div class="svg-wrapper">
							<svg
								fill="currentcolor"
								id="envelope"
								viewBox="0 0 490 490"
							>
								<path
									d="M479.574,78.191H10.425c-0.071,0-0.141,0.007-0.211,0.008l0.001,0.003C4.556,78.315,0,82.925,0,88.617v312.766
                        c0,5.762,4.669,10.426,10.425,10.426h469.149c5.758,0,10.426-4.664,10.426-10.426V88.617C490,82.854,485.332,78.191,479.574,78.191z
                         M454.407,99.043l-138.581,138.58c-0.002,0.002-0.004,0.003-0.006,0.005L245,308.449l-70.82-70.821
                        c-0.004-0.004-0.008-0.007-0.013-0.012L35.594,99.043H454.407z M20.851,113.784l131.215,131.215L20.851,376.215V113.784z
                         M35.594,390.957l131.215-131.216l70.821,70.821c3.629,3.629,9.303,5.439,14.743,0l70.82-70.82l131.215,131.215H35.594z
                         M469.149,376.213L337.935,244.999l131.214-131.214V376.213z"
								/>
							</svg>
						</div>
						<f7-input
							class="input"
							v-model="adminEmail"
							@input="addEmail($event)"
							label="E-mail"
							type="email"
							placeholder="Email"
						>
						</f7-input>
					</div>
					<div
						v-if="emailErrMessage"
						style="color: red; text-align: center"
					>
						{{ emailErrMessage }}
					</div>
					<div class="input-block">
						<f7-input
							type="password"
							autocomplete="off"
							v-model="adminPassword"
							@input="addAdminPassword($event)"
							placeholder="Password"
						>
						</f7-input>
					</div>
				</form>
				<div
					v-if="passErrMessage"
					style="color: red; text-align: center"
				>
					{{ passErrMessage }}
				</div>
			</div>

			<div class="confirm-block">
				<label
					class="checkbox card-shadow1"
					@change="agreeWithProcedure"
					style="margin-bottom: 5px"
				>
					<input type="checkbox" />
					<i class="icon-checkbox"> </i>
					I understand and agree to perform the responsibilities of the '{{ currentDesignation.role[1] }}' to the best of my abilities.
				</label>
				<div
					v-if="confirmErrMessage"
					style="color: red; text-align: center"
				>
					{{ confirmErrMessage }}
				</div>
				<div
					class="submit-button designation"
					@click="submit"
				>
					<f7-button> Accept "{{ currentDesignation.role[1] }}" Designation</f7-button>
				</div>
			</div>
		</div>
		<!-- v-else -->
		<SuccessDesignationScreen
			v-else
			:designation="currentDesignation"
			:adminEmail="adminEmail"
		/>
	</f7-page>
</template>

<script>
	import SuccessDesignationScreen from "./SuccessDesignationScreen.vue";
	import { mapGetters } from "vuex";
	import LS from "@/plugin/localstorage";

	const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{3,})$/iu;

	export default {
		data() {
			return {
				emailErrMessage: "",
				passErrMessage: "",
				confirmErrMessage: "",
				agreedWithAction: false,
				adminEmail: "",
				adminPassword: "",
				accepted: false,
			};
		},
		mounted() {},

		watch: {
			adminEmail() {
				this.emailErrMessage = "";
			},
			adminPassword() {
				this.passErrMessage = "";
			},
			agreedWithAction() {
				this.confirmErrMessage = "";
			},
		},

		methods: {
			validatePassword: function (val) {
				return val.length > 7;
			},
			validateEmail: function (value) {
				return EMAIL_REGEXP.test(value);
			},
			addAdminPassword(e) {
				this.adminPassword = e.target.value;
			},
			addEmail(e) {
				this.adminEmail = e.target.value;
			},
			agreeWithProcedure() {
				this.agreedWithAction = !this.agreedWithAction;
			},

			clearDesignationsLs() {
				LS.remove("designation");
				LS.remove("designationId");
			},

			acceptSubmit() {
				this.accepted = true;
			},

			submit() {
				if (!this.currentDesignation.official) {
					if (this.agreedWithAction) {
						if (this.validateEmail(this.adminEmail) && this.adminPassword) {
							this.$store
								.dispatch("app/setActionDone", {
									actId: this.currentDesignation.id,
									adminPassword: this.adminPassword,
									adminEmail: this.adminEmail,
								})
								.then((resp) => {
									if (!resp.status) {
										this.emailErrMessage = resp.response.message;
									} else {
										if (resp.response.data.roles !== undefined) {
											this.$store.commit("auth/setRoles", resp.response.data.roles);
										}
										this.accepted = true;
										this.clearDesignationsLs();
									}
								});
						} else {
							this.passErrMessage = "Please enter full information";
						}
					} else {
						this.confirmErrMessage = "Please confirm the rules";
					}
				} else {
					if (this.agreedWithAction) {
						this.$store
							.dispatch("app/setActionDone", {
								actId: this.currentDesignation.id,
							})
							.then((resp) => {
								if (!resp.status) {
									this.emailErrMessage = resp.response.message;
								} else {
									if (resp.response.data.roles !== undefined) {
										this.$store.commit("auth/setRoles", resp.response.data.roles);
									}
									this.accepted = true;
									this.clearDesignationsLs();
								}
							});
					} else {
						this.confirmErrMessage = "Please confirm the rules";
					}
				}
			},
		},
		computed: {
			...mapGetters("app", ["userFullName"]),
			...mapGetters("trainings", ["currentDesignation"]),
		},
		components: { SuccessDesignationScreen },
	};
</script>

<style></style>
