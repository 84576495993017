<template>
	<f7-page
		class="handbook-procedures-info"
		v-if="procedure">
		<BackButton />

		<div class="handbook-procedures-info-wrapper">
			<div class="handbook-procedures__title main-title">{{ procedure?.name }}</div>

			<CreatedBy
				:createdBy="procedure.createdBy"
				:createdAt="procedureCreatedAt"
				:reason="procedure?.reason"
				style="margin-bottom: 24px" />

			<AssignedTo :assigned="assignedUser" />

			<div class="title">Objective:</div>
			<div
				class="description"
				v-html="procedure?.objective"></div>
			<!-- <div v-show="checkForDoneProcedure()"> -->
			<div class="title">Circumstances:</div>
			<div
				class="description"
				v-html="procedure?.when_cirum"></div>
			<!-- </div> -->

			<div
				v-if="!isOfficial && isAssignedStaff()"
				:class="isOfficial ? 'assign' : 'not-assign'"
				class="official">
				<div class="title">Procedure for <i>You</i> as Assigned Staff:</div>
				<!-- <div class="title">You are Assigned Staff.</div> -->
				<!-- <div class="title">Your procedure:</div> -->
				<div
					class="description"
					v-html="procedure.json.items[0].content"></div>
			</div>

			<div
				v-if="isPrivacyOrSecurityOfficial"
				:class="isOfficial ? 'assign' : 'not-assign'"
				class="official">
				<!-- ${youText} -->
				<div class="title">Procedure for <i>YOU</i> {{ forWhom }}:</div>
				<!-- <div class="title">You are Privacy Official.</div> -->
				<!-- <div class="title">Your procedure:</div> -->
				<!-- <div class="title">{{ user.firstName }} {{ user.lastName }}:</div> -->
				<div
					class="description"
					v-html="guideline.content"></div>
			</div>

			<div :class="isOfficial ? 'assign' : 'not-assign'">
				<div class="title">Procedure for <i>ALL</i> Staff:</div>
				<div
					class="description"
					v-html="this.procedure.json.items[1].content"></div>
			</div>

			<div class="title">Requirements:</div>
			<div
				class="description"
				v-html="procedure?.requirements"></div>
			<div class="confirm-block">
				<label
					class="checkbox card-shadow1"
					@change="this.agreedWithProcedure = !this.agreedWithProcedure">
					<input type="checkbox" />
					<i class="icon-checkbox"> </i>
					<div class="text">
						I have reviewed our practice's "{{ procedure.name }}" and agree to comply with these expectations.
						<div
							v-if="checkForDoneProcedure()"
							class="accepted">
							{{
								`You accepted on
								 							${date}`
							}}
						</div>
					</div>
				</label>
				<div
					class="submit-button"
					v-if="!checkForDoneProcedure()"
					@click="submit">
					<f7-button> Submit </f7-button>
				</div>
			</div>
		</div>
	</f7-page>
</template>

<script>
	import LS from "@/plugin/localstorage";
	import AssignedTo from "../AssignedTo.vue";
	import CreatedBy from "../CreatedBy.vue";
  import {mapGetters} from "vuex";

	export default {
		data() {
			return {
				agreedWithProcedure: false,
				errMsg: "",
				showPoliticText: true,
				showUpdatedText: true,
				youText: "(You)",
				defs: [],
        assignedUser: {},
			};
		},
		mounted() {
			if (this.checkForDoneProcedure()) {
				const checkbox = document.querySelector('input[type="checkbox"]');
				checkbox.checked = true;
				checkbox.disabled = true;
			}

			document.querySelectorAll(".def").forEach(e => {
				e.addEventListener("click", e => {
					let id = e.target.getAttribute("def");
					let store = this.$store.state.app;
					let def;
					def = this.defs.find(e => e.id == id);

					store.popupTitle = `Define: ${def.term.charAt(0).toUpperCase() + def.term.slice(1)}`;
					store.popupText = def.definition;
					store.popupShow = true;
				});
			});

      if (this.procedure['assigned_official'] || this.isOfficial) {
        this.assignedUser = this.procedure.assigned[0]
      } else {
        this.assignedUser = this.procedure.assigned.find(us => us.locationId == this.location.id) || undefined
      }
		},

		created() {
			if (!this.$store.state.handbook.handbook) {
				f7router.navigate("/");
			}
			this.defs = LS.get("definitions");
			this.defs = Object.values(this.defs);
		},

    beforeCreate() {
      this.$store.dispatch("handbook/getHandbookProcedures");
    },

    computed: {
      ...mapGetters("auth", ["userPractice"]),
      location: function () {
        return this.userPractice[0].userToLocations[0].location;
      },

      date() {
        return this.transformDate(this.procedure.actions[0]?.children[0]?.doneStaffMap[0]?.created)
      },

			procedure() {
				return this.$store.getters["handbook/currentProcedure"];
			},

      procedureCreatedAt() {
        return this.procedure.actions[0]?.children[0].createdAt || this.procedure.created
      },

			politicInfo() {
				return this.$store.state.app.currentPoliticInfo;
			},

			currentPractice() {
				return this.$store.getters["handbook/currentPractice"];
			},

			isOfficial() {
				return this.$store.getters["auth/isOfficial"];
			},

			isPO() {
				return this.$store.getters["auth/isPrivacyOfficial"];
			},

			isSO() {
				return this.$store.getters["auth/isSecurityOfficial"];
			},

			user() {
				return this.$store.state.app.userInfo;
			},

			isPrivacyOrSecurityOfficial() {
				if (
					(this.procedure.json.items[0].assigned === "ROLE_SECURITY_OFFICIAL" && this.isSO) ||
					(this.procedure.json.items[0].assigned === "ROLE_PRIVACY_OFFICIAL" && this.isPO)
				) {
					return true;
				}
				return false;
			},

			guideline() {
				if (this.procedure.json.items[0].assigned === "ROLE_SECURITY_OFFICIAL" && this.isSO) {
					return this.procedure.json.items[0];
				} else if (this.procedure.json.items[0].assigned === "ROLE_PRIVACY_OFFICIAL" && this.isPO) {
					return this.procedure.json.items[0];
				} else {
					return false;
				}
			},
			forWhom() {
				if (this.procedure.json.items[0].assigned === "ROLE_SECURITY_OFFICIAL" && this.isSO) {
					return "as Security Official";
				}
				if (this.procedure.json.items[0].assigned === "ROLE_PRIVACY_OFFICIAL" && this.isPO) {
					return "as Privacy Official";
				}
				return false;
			},
		},
		methods: {
			isAssignedStaff() {
				return this.assignedUser?.id === this.user.id;
			},
			// agreeWithProcedure() {
			//    this.agreedWithProcedure = !this.agreedWithProcedure;
			// },
			getAmPm(x) {
				if (x >= 12) {
					return "PM";
				}
				return "AM";
			},
			getHoursByAmPm(x) {
				if (x > 12) {
					return x - 12;
				}
				return x;
			},
			checkMinutes(x) {
				if (x < 10) {
					return "0";
				}
				return "";
			},
			transformDate(date) {
				date = new Date(date);
				let month = date.getMonth() + 1;
				let day = date.getDate();
				let year = date.getFullYear().toString().substr(-2);
				if (month < 10) {
					month = "0" + month;
				}
				if (day < 10) {
					day = "0" + day;
				}
				return `${month}/${day}/${year}`;
			},
			changeTextHeight(getter) {
				let wrapper = document.querySelector(getter);
				if (getter === ".politic__text") {
					if (!this.showPoliticText) {
						wrapper.style.marginTop = `-${wrapper.offsetHeight}px`;
					} else {
						wrapper.style.marginTop = "0";
					}
				} else if (getter === ".updated__text") {
					if (!this.showUpdatedText) {
						wrapper.style.marginTop = `-${wrapper.offsetHeight}px`;
					} else {
						wrapper.style.marginTop = "0";
					}
				}
			},
			submit() {
				if (
					this.agreedWithProcedure &&
					this.procedure.actions &&
					this.procedure.actions.length > 0 &&
					this.procedure.actions[0].children.length > 0
				) {
					this.$store.dispatch("app/setActionDone", { actId: this.procedure.actions[0].children[0].id }).then(res => {
						if (res.status) {
							f7.views.main.router.navigate("/handbook", {
								// reloadPrevious: true,
								reloadCurrent: true,
							});
						}
					});
				} else {
					this.errMsg = "Please confirm the rules";
				}

				// return;
				// if (this.agreedWithProcedure) {
				// 	if (this.procedure.actions &&
				// 		this.procedure.actions.length > 0 &&
				// 		this.procedure.actions[0].children.length > 0) {
				// 		this.$store
				// 			.dispatch("app/setActionDone", {
				// 				clientId: this.$store.state.auth.user.id,
				// 				act: this.procedure.actions[0].children[0].id,
				// 			})
				// 			.then((resp) => {
				// 				if (resp) {
				// 					f7.views.main.router.navigate("/handbook");
				// 				}
				// 			});
				// 	}
				// }
				// else {
				// 	this.errMsg = "Please confirm the rules";
				// }
			},
			checkForDoneProcedure() {
        return this.procedure?.actions[0]?.children[0]?.doneStaffMap?.length > 0
			},
		},
		components: { AssignedTo, CreatedBy },
	};
</script>

<style></style>
