<template>
	<section
		style="margin: 15px 0px"
		class="imp-uploading">
		<div style="max-width: 100%; display: flex; flex-direction: column; margin: 0 24px">
			<cropper
				ref="cropper"
				v-if="round"
				class="cropper"
				:src="imageDataSrc"
				:stencil-component="$options.components.CircleStencil"
				:stencil-props="{ aspectRatio: 1, handlers: {}, movable: false, resizable: false }"
				:stencil-size="{ width: 200, height: 200 }"
				image-restriction="stencil"
				@change="changeImg"
				:responsive="true" />
			<!-- :max-width="maxStencilWidth" :min-width="minStencilWidth" -->

			<div
				v-if="imageDataSrc"
				class="upload-label">
				<f7-button
					@click="setImg"
					class="button"
					>Apply</f7-button
				>
				<f7-button @click="cancelImg">Cancel</f7-button>
			</div>
		</div>

		<input
			style="display: none"
			type="file"
			ref="file"
			@change="uploadImage($event)"
			accept="image/*, image/heic, image/heif" />

		<div
			class="img-wrap"
			@click="$refs.file.click()"
			v-if="!imageDataSrc">
			<img
				:class="round ? 'img-round upload-label' : ''"
				v-if="item[image] && !imageDataSrc"
				:src="item[image]" />

			<svg
				fill="currentcolor"
				viewBox="0 0 24 24"
				xmlns="http://www.w3.org/2000/svg"
				v-if="!item[image] && !imageDataSrc"
				class="wtf">
				<path
					d="M9 14c1.381 0 2.631-.56 3.536-1.465.904-.904 1.464-2.154 1.464-3.535s-.56-2.631-1.464-3.535c-.905-.905-2.155-1.465-3.536-1.465s-2.631.56-3.536 1.465c-.904.904-1.464 2.154-1.464 3.535s.56 2.631 1.464 3.535c.905.905 2.155 1.465 3.536 1.465zM9 21c3.518 0 6-1 6-2 0-2-2.354-4-6-4-3.75 0-6 2-6 4 0 1 2.25 2 6 2zM21 12h-2v-2c0-.553-.447-1-1-1s-1 .447-1 1v2h-2c-.553 0-1 .447-1 1s.447 1 1 1h2v2c0 .553.447 1 1 1s1-.447 1-1v-2h2c.553 0 1-.447 1-1s-.447-1-1-1z" />
			</svg>
		</div>

		<div>{{ imageTempURL }}</div>
	</section>
</template>

<script>
	import heic2any from "heic2any";
	import { Cropper, CircleStencil } from "vue-advanced-cropper";
	import "vue-advanced-cropper/dist/style.css";

	export default {
		name: "imp_upload",
		components: {
			CircleStencil,
			Cropper,
		},
		data() {
			return {
				imageDataSrc: null,
				imageDataType: null,
				imageCanvas: null,
				imageTempURL: null,
				stencilSize: null,
				uploadingImage: {
					width: null,
					height: null,
				},
			};
		},
		props: ["item", "image", "cid", "label", "width", "height", "round", "maxWidth", "maxHeight"],
		methods: {
			async uploadImage(e) {
				this.$store.commit("loading", true);
				const { files } = e.target;
				let mainFile = files[0];

				if (mainFile) {
					const mainFn = files[0].name.toLowerCase();

					if (this.imageDataSrc) {
						URL.revokeObjectURL(this.imageDataSrc);
					}

					let blob;
					if (mainFn.indexOf(".heic") !== -1 || mainFn.indexOf(".heiv") !== -1) {
						const blobHeic = mainFile.slice(0, mainFile.size, "image/heic");
						mainFile = await heic2any({
							blob: blobHeic,
							toType: "image/jpeg",
							quality: 0.1,
						});
						mainFile.name = mainFn.replace(".heic", ".jpeg");
					}

					blob = URL.createObjectURL(mainFile);
					this.imageDataSrc = blob;
					this.$store.commit("loading", false);
				}
				e.target.value = "";
			},

			changeImg: function ({ coordinates, image, canvas }) {
				this.uploadingImage.width = image.width;
				this.uploadingImage.height = image.height;
        if (canvas) {
  				this.imageCanvas = this.getRoundedCanvas(canvas);
        }
				this.stencilSize = image.width / 2;
			},

			setImg() {
				this.item[this.image] = this.imageCanvas.toDataURL();
				this.$emit("setImage");
				this.imageDataSrc = null;
			},

      getRoundedCanvas(sourceCanvas) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const width = sourceCanvas.width;
        const height = sourceCanvas.height;
        canvas.width = width;
        canvas.height = height;

        context.imageSmoothingEnabled = true;
        context.drawImage(sourceCanvas, 0, 0, width, height);
        context.globalCompositeOperation = 'destination-in';
        context.beginPath();
        context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
        context.fill();
        return canvas;
      },

			cancelImg: function () {
				URL.revokeObjectURL(this.imageDataSrc);
				this.imageDataSrc = null;
			},
		},
		computed: {
			maxStencilWidth() {
				let maxSize = 2400;
				if (this.stencilSize) maxSize = this.stencilSize * 1.6;
				return maxSize;
			},
			minStencilWidth() {
				let minSize;
				if (this.stencilSize) minSize = this.stencilSize * 0.7;
				if (this.stencilSize && this.uploadingImage.height < minSize)
					minSize = this.uploadingImage.height;
				return minSize;
			},
		},
	};
</script>

<style lang="scss">
	.imp-uploading {
		max-width: 100%;
		.vue-line-wrapper {
			display: none !important;
		}
		.cropper {
			max-height: 58vh;
		}

		.img-wrap {
			max-width: 120px;
			margin: 0 auto;
			aspect-ratio: 1/1;

			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 120px;
			}
			> svg {
				width: 120px;
				aspect-ratio: 1/1;
			}
		}

		.upload-label {
			display: flex;
			flex-direction: column;
			align-items: center;

			a {
				margin-top: 10px;
				color: white !important;
				background-color: #0071ba;
				display: inline-block;
				min-width: 90px;
			}
		}

		.img-round {
			border-radius: 999px;
		}
	}
</style>
