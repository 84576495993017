<template>
	<header class="header">
		<img
			src="@/assets/images/new-home-header-image.png"
			alt=""
		/>
	</header>
</template>

<script>
	export default {};
</script>

<style lang="scss">
	.header {
		line-height: 1;
		// max-height: 80px;
		width: 100%;
		display: flex;
		margin: 0 auto;
		height: 100%;

		img {
			object-fit: cover;
			max-width: 100%;
			height: 100%;
		}
	}
</style>
