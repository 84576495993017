<template>
	<f7-page class="current-training">
		<BackButton />

		<div class="current-training__card">
			<div class="card__topbar">
				<svg
					viewBox="0 0 340 433"
					fill="currentcolor"
					id="shield"
				>
					<path
						d="M282.05 88.15C269.193 92.655 255.673 94.98 242.05 95.03C216.274 94.952 191.143 86.966 170.05 72.15C149.074 86.908 124.096 94.917 98.45 95.11C84.688 95.078 71.03 92.725 58.05 88.15V240.15C58.05 251.43 98.05 316.47 170.05 360.15C241.49 316.23 282.05 251.19 282.05 240.15C281.49 207.51 282.05 120.15 282.05 88.15Z"
					/>
					<path
						d="M339.81 27.67C339.845 23.252 336.292 19.642 331.874 19.607C330.722 19.598 329.581 19.838 328.53 20.31C309.146 29.197 288.094 33.86 266.77 33.99C233.616 33.582 201.504 22.344 175.33 1.98999C172.31 -0.663006 167.79 -0.663006 164.77 1.98999C138.642 22.429 106.501 33.677 73.33 33.99C52.006 33.86 30.954 29.197 11.57 20.31C7.54004 18.498 2.80504 20.297 0.993038 24.326C0.521038 25.377 0.281039 26.517 0.290039 27.67C0.290039 29.35 0.850039 194.31 0.290039 248.95C0.290039 278.23 68.13 372.95 165.97 431.03C168.445 432.459 171.495 432.459 173.97 431.03C271.97 372.95 340.13 278.15 339.81 248.87C339.25 194.23 339.81 29.27 339.81 27.67ZM174.13 377.03C171.655 378.459 168.605 378.459 166.13 377.03C92.93 333.67 42.05 262.47 42.05 240.15C42.45 199.67 42.05 77.59 42.05 76.31C42.015 71.892 45.568 68.282 49.986 68.247C51.138 68.238 52.279 68.478 53.33 68.95C67.412 75.426 82.71 78.835 98.21 78.95C122.291 78.824 145.669 70.816 164.77 56.15C167.79 53.497 172.31 53.497 175.33 56.15C194.322 70.886 217.613 79.006 241.65 79.27C257.15 79.155 272.448 75.746 286.53 69.27C290.56 67.458 295.295 69.257 297.107 73.286C297.579 74.337 297.819 75.477 297.81 76.63C298.05 77.83 297.41 200.15 298.05 240.15C298.05 262.47 247.17 333.67 174.13 377.03Z"
					/>
				</svg>
				<div class="topbar__name main-title">{{ currentTraining.name }}</div>
			</div>

			<div class="card__body">
				<div
					class="icon-container"
					:class="{ 'not-published': currentTraining.total === undefined }"
				>
					<div
						class="img-wrapper"
						v-if="currentTraining.image"
					>
						<img
							:src="currentTraining.image"
							alt=""
						/>
					</div>

					<div
						v-else
						class="svg-wrapper"
					>
						<svg
							viewBox="0 0 340 433"
							fill="currentcolor"
						>
							<path
								d="M282.05 88.15C269.193 92.655 255.673 94.98 242.05 95.03C216.274 94.952 191.143 86.966 170.05 72.15C149.074 86.908 124.096 94.917 98.45 95.11C84.688 95.078 71.03 92.725 58.05 88.15V240.15C58.05 251.43 98.05 316.47 170.05 360.15C241.49 316.23 282.05 251.19 282.05 240.15C281.49 207.51 282.05 120.15 282.05 88.15Z"
							/>
							<path
								d="M339.81 27.67C339.845 23.252 336.292 19.642 331.874 19.607C330.722 19.598 329.581 19.838 328.53 20.31C309.146 29.197 288.094 33.86 266.77 33.99C233.616 33.582 201.504 22.344 175.33 1.98999C172.31 -0.663006 167.79 -0.663006 164.77 1.98999C138.642 22.429 106.501 33.677 73.33 33.99C52.006 33.86 30.954 29.197 11.57 20.31C7.54004 18.498 2.80504 20.297 0.993038 24.326C0.521038 25.377 0.281039 26.517 0.290039 27.67C0.290039 29.35 0.850039 194.31 0.290039 248.95C0.290039 278.23 68.13 372.95 165.97 431.03C168.445 432.459 171.495 432.459 173.97 431.03C271.97 372.95 340.13 278.15 339.81 248.87C339.25 194.23 339.81 29.27 339.81 27.67ZM174.13 377.03C171.655 378.459 168.605 378.459 166.13 377.03C92.93 333.67 42.05 262.47 42.05 240.15C42.45 199.67 42.05 77.59 42.05 76.31C42.015 71.892 45.568 68.282 49.986 68.247C51.138 68.238 52.279 68.478 53.33 68.95C67.412 75.426 82.71 78.835 98.21 78.95C122.291 78.824 145.669 70.816 164.77 56.15C167.79 53.497 172.31 53.497 175.33 56.15C194.322 70.886 217.613 79.006 241.65 79.27C257.15 79.155 272.448 75.746 286.53 69.27C290.56 67.458 295.295 69.257 297.107 73.286C297.579 74.337 297.819 75.477 297.81 76.63C298.05 77.83 297.41 200.15 298.05 240.15C298.05 262.47 247.17 333.67 174.13 377.03Z"
							/>
						</svg>
					</div>

					<div
						class="not-published-svg"
						v-if="currentTraining.total === undefined"
						id="lock"
					>
						<svg
							viewBox="0 0 330 330"
							xml:space="preserve"
							fill="currentcolor"
						>
							<path
								id="XMLID_519_"
								d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85S80.001,38.131,80.001,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M110.001,85c0-30.327,24.673-55,54.999-55c30.327,0,55,24.673,55,55v45H110.001V85z"
							/>
						</svg>
					</div>
				</div>

				<CustomProgressBar
					v-if="currentTraining.total"
					:percent="(currentTraining.done / currentTraining.total) * 100"
				/>

				<div class="card__wrapper">
					<div class="card__instructions training-main-descriptions">
						<div
							class="instructions__descriptions"
							v-if="!currentTraining.total"
						>
							Your Privacy Official {{ personForSchedule }} will schedule this trainings module for your team using the desktop version
							of Bite-Size HIPAA.
						</div>
						<div
							class="instructions__text"
							:class="{ 'not-published': currentTraining.total === undefined }"
						>
							{{ currentTraining.description }}
						</div>
					</div>

					<div
						class="submit-button"
						@click="goToTrainingAction"
						v-if="hasAvailableAction"
					>
						<f7-button v-if="currentTraining.done === 0">START TRAINING</f7-button>
						<f7-button v-else-if="currentTraining.done / currentTraining.total !== 1">CONTINUE TRAINING</f7-button>
					</div>

					<TrainingActionsList
						:notPublished="!currentTraining.total"
						:trainingActions="currentTraining.actions"
            :publisher="currentTraining.publisher"
						@goToAction="setAction"
					/>
				</div>
			</div>
		</div>
	</f7-page>
</template>

<script>
	import CustomProgressBar from "@/components/CustomProgressBar.vue";
	import TrainingActionsList from "./actions/TrainingActionsList.vue";
	import { mapGetters } from "vuex";

	export default {
		components: { CustomProgressBar, TrainingActionsList },
		computed: {
			...mapGetters("trainings", ["currentTraining"]),

			personForSchedule() {
				if (this.currentTraining.publisher.length > 0) return `- ${this.currentTraining.publisher} - `;
				return "";
			},

      hasAvailableAction() {
        if (!this.currentTraining.total) return false;

        for (const action of this.currentTraining.actions) {
            if (action.publish && !action.done) return true;
        }

        return false;
      }
		},

		methods: {
			goToTrainingAction() {
				for (let item of this.currentTraining.actions) {
					if (item.doneStaffMap.length === 0 && item.publish) {
						this.setAction(item.id);
						break;
					}
				}
			},

			setAction(id) {
				this.$store.commit("trainings/setActionId", id);
				f7router.navigate("training-action");
			},
		},
	};
</script>

<style lang="scss"></style>
