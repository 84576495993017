import { APP_SERVICE } from "@/api/app";
import helper from "@/plugin/helper";
import LS from "@/plugin/localstorage";

export default {
	namespaced: true,

	state: {
		handbook: null || JSON.parse(LS.get("handbook")),
		practiceId: null || JSON.parse(LS.get("practiceId")),
		procedureId: null || JSON.parse(LS.get("procedureId")),
	},

	mutations: {
		setHandbook(state, data) {
			LS.set("handbook", JSON.stringify(data));
			state.handbook = data;
		},

		setPracticeId(state, data) {
			LS.set("practiceId", JSON.stringify(data));
			state.practiceId = data;
		},

		setProcedureId(state, data) {
			LS.set("procedureId", JSON.stringify(data));
			state.procedureId = data;
		},

		updatePolicy(state,data) {
			const idx = state.handbook.findIndex(e => e.id === state.practiceId);
			state.handbook[idx].procedures = data;
		},

		updateProcedure(state,data){
			const polIdx = state.handbook.findIndex(e => e.id === state.practiceId);
			const procIdx = state.handbook[polIdx].procedures.findIndex(e => e.id === state.procedureId)
			state.handbook[polIdx].procedures[procIdx] = data;
		}
	},

	actions: {
		async getHandbook({ commit, rootState, dispatch }, payload) {
			commit("loading", true, { root: true });

			const { response, status } = await APP_SERVICE.getHandbook({
				// ! TEST with code below instead of user.id
				// id: rootState.auth.user.id123,
				clientId: rootState.auth.user.id,
				// Autorization: rootState.auth.user.token,
			});

			if (status) {
				commit("setHandbook", response.data.politics.politics);
			} else {
				dispatch("openErrorHandlingModal", response, { root: true });
			}

			commit("loading", false, { root: true });
			return status;
		},

		async getHandbookProcedures({ commit, rootState, dispatch,state }, payload) {
			commit("loading", true, { root: true });

			const { response, status } = await APP_SERVICE.getHandbookProcedures({
				// ! TEST with code below instead of user.id
				// id: rootState.auth.user.id123,
				clientId: rootState.auth.user.id,
				policyId: state.practiceId,
				// Autorization: rootState.auth.user.token,
			});

			if (status) {
				commit("updatePolicy", response.data.procedures);
			} else {
				dispatch("openErrorHandlingModal", response, { root: true });
			}

			commit("loading", false, { root: true });
			return status;
		},

		async getShowProcedures({ commit, rootState, dispatch, state }, payload) {
			commit("loading", true, { root: true });

			const { response, status } = await APP_SERVICE.getShowProcedures({
				// ! TEST with code below instead of user.id
				// id: rootState.auth.user.id123,
				clientId: rootState.auth.user.id,
				procedureId: state.procedureId,
				// Autorization: rootState.auth.user.token,
			});

			if (status) {
				commit("updateProcedure", response.data.procedure[0]);
			} else {
				dispatch("openErrorHandlingModal", response, { root: true });
			}

			commit("loading", false, { root: true });
			return status;
		},
	},

	getters: {
		publishedPractices(state) {
			if (state.handbook) {
				// return state.handbook;
				return state.handbook.filter(obj => obj.publishedProcedures !== 0);
			}
		},

		unpublishedPractices(state) {
			if (state.handbook) {
				return state.handbook.filter(obj => obj.publishedProcedures === 0);
			}
		},

		allHandbookPractices(state, getters) {
			if (state.handbook) {
				return [...getters.publishedPractices, ...getters.unpublishedPractices];
			}
		},

		publishedHandbookProcedures(state) {
			let publishedHandbookProcedures = [];

			for (const practice of state.handbook) {
				practice.procedures.forEach(e => {
					e.practiceId = practice.id;
					e.publisher = practice.publisher;
					e.title = practice.title;
				});

				let publishedProcedures = helper.getPublishedProcedure(practice.procedures);

				if (practice.publishedProcedures) {
					publishedHandbookProcedures = [...publishedHandbookProcedures, ...publishedProcedures];
				}
			}
			return publishedHandbookProcedures;
		},

		unpublishedHandbookProcedures(state) {
			let unpublishedHandbookProcedures = [];

			for (const practice of state.handbook) {
				practice.procedures.forEach(e => {
					e.practiceName = practice.name;
				});
				let unpublishedProcedures = helper.getUnpublishedProcedure(practice.procedures);

				unpublishedHandbookProcedures = [...unpublishedHandbookProcedures, ...unpublishedProcedures];
			}
			return unpublishedHandbookProcedures;
		},

		allHandbookProcedures({}, getters) {
			return [...getters.publishedHandbookProcedures, ...getters.unpublishedHandbookProcedures];
		},

		currentPractice(state) {
			if (state.handbook) {
				return state.handbook.find(prac => prac.id === state.practiceId);
			}
		},

		publishedProcedures({}, getters) {
			if (getters.currentPractice?.procedures) {
				return helper.getPublishedProcedure(getters.currentPractice.procedures);
			}
		},

		unpublishedProcedures({}, getters) {
			if (getters.currentPractice?.procedures) {
				return helper.getUnpublishedProcedure(getters.currentPractice.procedures);
			}
		},

		currentProcedure(state, getters) {
			if (getters.currentPractice) {
				return getters.currentPractice?.procedures.find(proc => proc.id === state.procedureId);
			}
		},
	},
};
