<template>
	<div class="home-screen">
		<MainHeader
			:image="userImage"
			:fullName="userFullName"
			:alertsCount="alertsCount" />

		<!-- <HomepageUserPractice /> -->

		<!-- <HomepageOfficialsBlock
			official="Privacy Official"
			:img="true" />

		<HomepageOfficialsBlock
			official="Security Official"
			:img="false" /> -->

		<HomepageDesignation :designations="designations" />

		<HomepageHandbook :totals="totals" />
		<HomepageTrainings
			:completeTrainings="completeTrainings"
			:uncompleteTrainings="uncompleteTrainings" />

		<!-- <MainDirectory v-if="directory.length >= 4"
									 :directory="directory" /> -->
		<!-- <MainDirectory v-if="listOfUsers.length >= 4"
                     :list='listOfUsers' /> -->
		<!-- <DirectoryList v-else
									 :directory="directory" /> -->
	</div>
</template>

<style></style>

<script>
	import MainHeader from "@/components/homepage/HomepageHeader.vue";
	import MainDirectory from "@/components/homepage/main-directory.vue";
	import DirectoryList from "@/components/homepage/directory-list.vue";

	import HomepageTrainings from "@/components/homepage/HomepageTrainings.vue";
	import HomepageHandbook from "@/components/homepage/HomepageHandbook.vue";
	import HomepageDesignation from "@/components/homepage/HomepageDesignation.vue";
	import HomepageUserPractice from "@/components/homepage/HomepageUserPractice.vue";
	import HomepageOfficialsBlock from "@/components/homepage/HomepageOfficialsBlock.vue";
	import { mapGetters } from "vuex";

	export default {
		components: {
			MainHeader,
			HomepageTrainings,
			MainDirectory,
			DirectoryList,
			HomepageHandbook,
			HomepageUserPractice,
			HomepageDesignation,
			HomepageOfficialsBlock,
		},

		created() {
			this.$emit("dashboard");
		},

		computed: {
			...mapGetters("app", [
				"totals",
				"directory",
				"designations",
				"userFullName",
				"userImage",
				"alertsCount",
				"completeTrainings",
				"uncompleteTrainings",
			]),
		},
	};
</script>
