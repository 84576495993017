import { AXIOS, setAxiosToken } from "@/plugin/axios";
import { LS } from "@/plugin/localstorage";

import { createStore } from "vuex";

import auth from "./modules/auth.js";
import app from "./modules/app.js";
import trainings from "./modules/trainings.js";
import handbook from "./modules/handbook.js";

if (LS.get("user.token")) {
	setAxiosToken(LS.get("user.token"));
}

export default createStore({
	state: {
		loading: false,
		showLoadingPage: true,
		showErrorHandlingModal: false,
		errorMessage: undefined,
		errorInfo: undefined,
		options: undefined,
		payload: undefined,
	},

	mutations: {
		loading(state, a) {
			state.loading = a;
		},
		showLoadingPage(state, bool) {
			state.showLoadingPage = bool;
		},
		setShowErrorHandlingModal(state, bool) {
			state.showErrorHandlingModal = bool;
		},
		setErrorMessage(state, message) {
			state.errorMessage = message;
		},
		setErrorInfo(state, data) {
			state.errorInfo = data;
		},
		setAllOptions(state, data) {
			state.options = data;
		},
		setPayload(state, data) {
			state.payload = data;
		},
		setErrorHandlerButtonText(state, message) {
			state.buttonText = message;
		},
	},

	getters: {
		loading(state) {
			return state.loading;
		},

		getShowErrorHandlingModalStatus(state) {
			return state.showErrorHandlingModal;
		},
		getErrorMessage(state) {
			return state.errorMessage;
		},
		getErrorInfo(state) {
			return state.errorInfo;
		},
		getAllOptions(state) {
			return state.options;
		},
		getPayload(state) {
			return state.payload;
		},
		getButtonText(state) {
			return state.buttonText;
		},
	},

	actions: {
		openErrorHandlingModal({ commit }, options = undefined) {
			commit("setShowErrorHandlingModal", true);
			commit("setErrorMessage", options?.data?.message);
			commit("setErrorInfo", options?.config);
			commit("setAllOptions", options);
			if (options?.config.url === "dashboard") {
				commit("setErrorHandlerButtonText", "Back to Login");
			} else {
				commit("setErrorHandlerButtonText", "Back to Dashboard");
			}
		},

		closeErrorHandlingModal({ commit }) {
			commit("setShowErrorHandlingModal", false);
		},

		logout(root) {
			root.commit("showLoadingPage", true);
			setTimeout(() => {
				this.$store.commit("showLoadingPage", false);
			}, 1000);

			LS.clear();
			root.commit("auth/clearToken");
			root.commit("app/hideToolbars");
			delete AXIOS.defaults.headers.common["Authorization"];
			location.hash = "";
		},

		login(root, { token, id }) {
			root.commit("app/showToolbars");
			root.commit("auth/setUserToken", token);
			root.commit("auth/setUserId", id);

			root.dispatch("app/loadDef");
			setAxiosToken(token);
			LS.set("update-time", Date.now());
		},
	},
	modules: {
		app: app,
		auth: auth,
		trainings: trainings,
		handbook: handbook,
	},
});
