<template>
   <f7-page class="directory-info">
      <BackButton title="My Directory" />
      <div class="text"
           v-if="currentInfo.type === 'user'">
         <div v-if="currentInfo.image"
              class="img-wrapper">
            <img :src="currentInfo.image"
                 alt="" />
         </div>
         <div v-else
              class="icon-wrapper">
         
         </div>
         <div class="name">
            {{ fullName }}
         </div>
         <div class="jobtitle"
              v-for="(title, index) in currentInfo.jobTitle"
              :key="index">
            {{ title }}
         </div>
         <!-- <div>{{ maskedPhone }}</div> -->
         <div>{{ maskedPhone(currentInfo.phone) }}</div>
         <div v-if="currentInfo.workEmail">{{ currentInfo.workEmail }}</div>
         <div v-if="currentInfo.email">{{ currentInfo.email }}</div>
      </div>
      <div class="text"
           v-else>
         <div class="icon-wrapper">
         </div>
         <div class="name">{{ currentInfo.name }}</div>
         <div class="address"
              v-if="currentInfo.address1">
            <div>{{ currentInfo.address1 }}</div>
            <div>{{ getAdressString }}</div>
         </div>
         <div>
            {{ maskedPhone }}
         </div>
         <div>
            <a target="_blank"
               :href="practiceUrl"
               class="external"
               v-if="practiceUrl">
               {{ currentInfo.name }}</a>
         </div>
      </div>
   </f7-page>
</template>

<script>
import { getMaskedPhone } from "@/plugin/func";

export default {
   mounted() { },
   data() {
      return {
         practicePhone: false,
      };
   },
   watcher: {},
   methods: {
      maskedPhone: getMaskedPhone,
   },
   computed: {
      fullName() {
         return `${this.currentInfo.firstName} ${this.currentInfo.lastName}`;
      },
      currentInfo() {
         // return this.$store.getters["app/getCurrentItemInfo"];
         return this.$store.state.app.currentItemInfo
      },
      getAdressString() {
         return `${this.currentInfo.city}, ${this.currentInfo.state} ${this.currentInfo.zip}`;
      },
      // maskedPhone() {
      //    if (this.currentInfo.phone) {
      //       var y = this.currentInfo.phone
      //          .replace(/\D/g, "")
      //          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      //       return (this.value = !y[2]
      //          ? "(" + y[1]
      //          : "(" + y[1] + ") " + y[2] + (y[3] ? "-" + y[3] : ""));
      //    } else {
      //       return "";
      //    }
      // },
      practiceUrl() {
         if (this.currentInfo.website) {
            return `http://${this.currentInfo.website}`;
         } else {
            return "";
         }
      },
   },
};
</script>

<style>

</style>
