<template>
	<div class="homepage-officials-block card-shadow1">
		<div class="image-block">
			<div
				class="image-wrapper"
				v-if="img">
				<img
					src="@/assets/images/member-3.png"
					alt="###" />
			</div>
			<div
				class="svg-wrapper"
				v-else>
				<svg
					viewBox="0 0 16 20"
					fill="currentcolor"
					id="user">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M13.294 5.29105C13.294 8.22808 10.9391 10.5831 8 10.5831C5.0619 10.5831 2.70601 8.22808 2.70601 5.29105C2.70601 2.35402 5.0619 0 8 0C10.9391 0 13.294 2.35402 13.294 5.29105ZM8 20C3.66237 20 0 19.295 0 16.575C0 13.8539 3.68538 13.1739 8 13.1739C12.3386 13.1739 16 13.8789 16 16.599C16 19.32 12.3146 20 8 20Z" />
				</svg>
			</div>
		</div>
		<div class="desc-block">
			<div class="title">{{ official }}</div>
			<div class="po-name name">Albert Test</div>
			<div class="po-phone phone">{{ maskedPhone("4512224888") }}</div>
		</div>
	</div>
</template>

<script>
	import { getMaskedPhone } from "@/plugin/func";

	export default {
		props: {
			official: String,
			img: Boolean,
		},

		methods: {
			maskedPhone: getMaskedPhone,
		},
	};
</script>

<style lang="scss">
	@import "@/sass/_vars";

	.homepage-officials-block {
		display: flex;
		margin: 18px 24px;
		.image-block {
			flex: 0 0 18%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 6px;
			.image-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 100%;
					width: 55px;
				}
			}

			.svg-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;

				// width: 100%;
				max-width: 60px;

				border-radius: 999px;
				overflow: hidden;
				aspect-ratio: 1/1;

				svg {
					aspect-ratio: 1/1;
					background-color: $primary-color-500;
					color: $white;

					width: 35px;
					padding: 10px;
				}
			}
		}

		.desc-block {
			padding: 8px 8px 8px 0;

			.title {
				border-bottom: 2px solid var(--primary-font-color);
				margin-bottom: 5px;
				font-size: 18px;

				text-transform: uppercase;

				@media (max-width: 379.98px) {
					font-size: 16px;
				}
			}
			.name,
			.phone {
				font-weight: 300;
				font-size: 15px;
				@media (max-width: 374.98px) {
					font-size: 13px;
				}
			}
		}
	}
</style>
