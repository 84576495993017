<template>
	<f7-page class="homepage">
		<!-- ptr
		ptr-mousewheel
		@ptr:refresh='loadDashboard' -->

		<LoginScreen v-if="!isLoggedIn || showVerificationPracticeSuccessScreen" />

		<HomeScreen
			v-else
			@dashboard="loadDashboard" />
	</f7-page>
</template>

<style></style>

<script>
	import LoginScreen from "@/components/authorization/LoginScreen.vue";
	import HomeScreen from "@/components/homepage/HomeScreen.vue";
	import VerificationPracticeSuccessScreen from "../VerificationPracticeSuccessScreen.vue";

	export default {
		components: {
			LoginScreen,
			HomeScreen,
			VerificationPracticeSuccessScreen,
		},

		mounted() {},

		methods: {
			loadDashboard: async function (done, i) {
				const { response, status } = await this.$store.dispatch("app/getDashboard");
				if (done) done();
				if (
					response.data.dashboard?.training?.actions &&
					this.$store.state.trainings.redirectingLimit >= 1
				) {
					await this.$store.dispatch("trainings/getTrainings");
					f7router.navigate("/designation");
					this.$store.commit(
						"trainings/setDesignationId",
						this.$store.getters["trainings/sortedDesignations"][0].id
					);
					this.$store.commit("trainings/clearLimit");
				}
			},
		},

		computed: {
			isLoggedIn() {
				return this.$store.getters["auth/isLoggedIn"];
			},

			showVerificationPracticeSuccessScreen() {
				return this.$store.getters["app/getShowPracticeVerificationSuccessScreen"];
			},
		},
	};
</script>
