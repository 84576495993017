<template>
	<f7-page class="trainings">
		<BackButton title="Trainings" />

		<div class="trainings-links-container"
				 style="margin: 24px"
				 v-if="this.$store.state.trainings.trainings">
			<HomepageTrainingsList :trainings="publishedTrainings"
														 :done="false"
														 @toTraining="toCurrentTraining" />

			<HomepageTrainingsList :trainings="unpublishedTrainings"
														 :done="false"
														 @toTraining="toCurrentTraining" />
			<!-- :designations="designations" -->
			<!-- @toDesignation="toCurrentDesignation" -->
		</div>

		<div v-else
				 style="margin: 24px">Empty list
		</div>
	</f7-page>
</template>

<script>

import HomepageTrainingsList from "../homepage/HomepageTrainingsList.vue";

export default {

	created() {
		this.$store.dispatch("trainings/getTrainings")
	},

	components: {
		HomepageTrainingsList,
	},

	methods: {
		toCurrentTraining(id) {
			this.$store.commit('trainings/setTrainingId', id)
			const curTra = this.$store.getters['trainings/currentTraining']
			f7router.navigate("current-training");

			// if (curTra.total) {
			// 	f7router.navigate("current-training");
			// }
			// else console.log(curTra);
		},

		// toCurrentDesignation(id) {
		// 	this.$store.commit('trainings/setDesignationId', id)
		// 	f7router.navigate("/trainings-page/trainings-designation-page");
		// },
	},

	computed: {
		unpublishedTrainings() {
			return this.$store.getters['trainings/unpublishedTrainings'];
		},
		publishedTrainings() {
			return this.$store.getters['trainings/publishedTrainings'];
		},

		trainings() {
			return this.$store.getters['trainings/trainings'];
		},
		designations() {
			return this.$store.getters['trainings/designations'];
		},

	},
};
</script>

<style>

</style>
