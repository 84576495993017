<template>
	<f7-page
		class="handbook-info"
		v-if="currentPractice">
		<BackButton />

		<!-- <div v-html="politicInfo.text"
				 class="politic-text"></div> -->
		<!-- <div class="procedures-title">{{ politicInfo.name }}</div> -->

		<div
			class="practice-text__container"
			style="margin: 24px">
			<div class="practice-text__title main-title">{{ `${currentPractice.name}` }}</div>
			<div
				class="practice-text__text"
				v-html="currentPractice.text"></div>
		</div>
		<div
			class="practice-text__title main-title"
			style="margin: 24px">
			{{ proceduresTitle }}
		</div>

		<HandbookProceduresList :procedures="publishedProcedures" />

		<HandbookProceduresList :procedures="unpublishedProcedures" />
	</f7-page>
</template>

<script>
	import { mapGetters } from "vuex";
	import HandbookProceduresList from "./HandbookProceduresList.vue";

	export default {
    beforeCreate() {
      this.$store.dispatch("handbook/getHandbookProcedures");
    },
		methods: {},
		computed: {
			proceduresTitle() {
				return `${this.currentPractice?.name.replace("Policy", "Procedures")}`;
			},

			...mapGetters("handbook", ["currentPractice", "publishedProcedures", "unpublishedProcedures"]),
		},
		components: { HandbookProceduresList },
	};
</script>

<style></style>
