export const LS = {
	set(key, val) {
		const _val = {
			data: val,
		};
		localStorage.setItem(key, JSON.stringify(_val));
	},
	get(key) {
		const val = localStorage.getItem(key);
		if (val) {
			val.replace('"undefined"', '""')
				.replace("'undefined'", '""')
				.replace('"null"', '""')
				.replace("'null'", '""');
			const data = JSON.parse(val);
			return data.data;
		} else {
			return null;
		}
	},
	remove(key) {
		localStorage.removeItem(key);
	},
	clear() {
		localStorage.clear();
	},
};

export default LS;
