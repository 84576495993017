<template>
	<f7-page class="practice-page">
		<BackButton title="Practice Info" />
		<div
			class="practice-page__container"
			v-if="practiceInfo">
			<div class="practice-page__pr-info">
				<div
					class="image-wrapper"
					v-if="location.image">
					<img
						:src="location.image"
						alt="###" />
				</div>
				<div
					class="svg-wrapper"
					v-else>
					<div class="hipaa-icon-tooth"></div>
				</div>
				<div class="practice-page__info name">{{ practiceInfo.client.practice.name }}</div>

				<div
					class="practice-page__info"
					v-if="location.address1">
					<span>{{ location.address1 }}</span>
					<span v-if="location.address2">, {{ location.address2 }}</span>
				</div>

				<div class="practice-page__info">{{ `${location.city}, ${location.state} ${location.zip}` }}</div>

				<div
					class="practice-page__info phone"
					v-if="location.phone">
					{{ maskedPhone(location.phone) }}

					<a
						class="icon-wrapper external"
						:href="`tel:+1${location.phone}`">
						<svg
							id="cell-phone"
							viewBox="0 0 512 512">
							<path
								d="M500.177 55.798s-21.735-7.434-39.551-11.967C411.686 31.369 308.824 24.727 256 24.727S100.314 31.369 51.374 43.831c-17.816 4.534-39.551 11.967-39.551 11.967C4.281 58.078-.621 65.322.063 73.172l8.507 97.835c.757 8.596 7.957 15.201 16.581 15.201h84.787c8.506 0 15.643-6.416 16.553-14.878l4.28-39.973c.847-7.93 7.2-14.138 15.148-14.815 0 0 68.484-6.182 110.081-6.182 41.586 0 110.08 6.182 110.08 6.182 7.949.676 14.302 6.885 15.148 14.815l4.29 39.973c.9 8.462 8.038 14.878 16.545 14.878h84.777c8.632 0 15.832-6.605 16.589-15.201l8.507-97.835c.685-7.85-4.216-15.094-11.759-17.374z"
								fill="currentcolor" />
							<path
								d="M357.503 136.629h-55.365v46.137h-92.275v-46.137h-55.365S145.27 256.586 34.541 344.247v129.95c0 7.218 5.857 13.076 13.075 13.076h416.768c7.218 0 13.076-5.858 13.076-13.076v-129.95c-110.73-87.661-119.957-207.618-119.957-207.618zM338.768 391.42v37.406h-37.396V391.42h37.396zm0-59.15v37.406h-37.396V332.27h37.396zm-37.396-21.752v-37.396h37.396v37.396h-37.396zm-26.674 80.902v37.406h-37.396V391.42h37.396zm0-59.15v37.406h-37.396V332.27h37.396zm0-59.148v37.396h-37.396v-37.396h37.396zM210.629 391.42v37.406h-37.397V391.42h37.397zm0-59.15v37.406h-37.397V332.27h37.397zm0-59.148v37.396h-37.397v-37.396h37.397z"
								fill="currentcolor" />
						</svg>
					</a>
				</div>

				<div
					class="practice-page__info email"
					v-if="location.email">
					{{ location.email }}
					<a
						class="icon-wrapper external"
						:href="`mailto: ${location.email}`">
						<svg
							fill="currentcolor"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M10 15l-4 4v-4H2a2 2 0 0 1-2-2V3c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-8zM5 7v2h2V7H5zm4 0v2h2V7H9zm4 0v2h2V7h-2z"></path>
						</svg>
					</a>
				</div>

				<div
					class="practice-page__info"
					v-if="websiteUrl">
					<a
						:href="websiteUrl"
						class="external website"
						target="_blank"
						>{{ websiteName }}</a
					>
				</div>
			</div>

			<div
				class="practice-page__po-info card-shadow1"
				v-if="privacyOfficialInfo">
				<div class="po-description description">
					Our practice has a <span>PRIVACY OFFICIAL</span> appointed to oversee all activities related to
					the development, implementation, and enforcement of our privacy policies. The responsibilities
					associated with this role have been designated to:
				</div>

				<div
					class="image-wrapper"
					v-if="privacyOfficialInfo.image">
					<img
						:src="privacyOfficialInfo.image"
						alt="###" />
				</div>
				<div
					class="svg-wrapper"
					v-else>
					<svg
						viewBox="0 0 16 20"
						fill="currentcolor"
						id="user">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M13.294 5.29105C13.294 8.22808 10.9391 10.5831 8 10.5831C5.0619 10.5831 2.70601 8.22808 2.70601 5.29105C2.70601 2.35402 5.0619 0 8 0C10.9391 0 13.294 2.35402 13.294 5.29105ZM8 20C3.66237 20 0 19.295 0 16.575C0 13.8539 3.68538 13.1739 8 13.1739C12.3386 13.1739 16 13.8789 16 16.599C16 19.32 12.3146 20 8 20Z" />
					</svg>
				</div>

				<div class="po-name name">{{ privacyOfficialInfo.fullName }}</div>
				<div
					class="po-name title"
					v-if="privacyOfficialInfo.title">
					{{ privacyOfficialInfo.title }}
				</div>
				<div class="po-phone phone">
					{{ maskedPhone(privacyOfficialInfo.phone) }}
					<div class="actions">
						<a
							class="icon-wrapper external"
							:href="`tel:+1${privacyOfficialInfo.phone}`">
							<svg
								id="cell-phone"
								viewBox="0 0 512 512">
								<path
									d="M500.177 55.798s-21.735-7.434-39.551-11.967C411.686 31.369 308.824 24.727 256 24.727S100.314 31.369 51.374 43.831c-17.816 4.534-39.551 11.967-39.551 11.967C4.281 58.078-.621 65.322.063 73.172l8.507 97.835c.757 8.596 7.957 15.201 16.581 15.201h84.787c8.506 0 15.643-6.416 16.553-14.878l4.28-39.973c.847-7.93 7.2-14.138 15.148-14.815 0 0 68.484-6.182 110.081-6.182 41.586 0 110.08 6.182 110.08 6.182 7.949.676 14.302 6.885 15.148 14.815l4.29 39.973c.9 8.462 8.038 14.878 16.545 14.878h84.777c8.632 0 15.832-6.605 16.589-15.201l8.507-97.835c.685-7.85-4.216-15.094-11.759-17.374z"
									fill="currentcolor" />
								<path
									d="M357.503 136.629h-55.365v46.137h-92.275v-46.137h-55.365S145.27 256.586 34.541 344.247v129.95c0 7.218 5.857 13.076 13.075 13.076h416.768c7.218 0 13.076-5.858 13.076-13.076v-129.95c-110.73-87.661-119.957-207.618-119.957-207.618zM338.768 391.42v37.406h-37.396V391.42h37.396zm0-59.15v37.406h-37.396V332.27h37.396zm-37.396-21.752v-37.396h37.396v37.396h-37.396zm-26.674 80.902v37.406h-37.396V391.42h37.396zm0-59.15v37.406h-37.396V332.27h37.396zm0-59.148v37.396h-37.396v-37.396h37.396zM210.629 391.42v37.406h-37.397V391.42h37.397zm0-59.15v37.406h-37.397V332.27h37.397zm0-59.148v37.396h-37.397v-37.396h37.397z"
									fill="currentcolor" />
							</svg>
						</a>
						<a
							class="icon-wrapper external"
							:href="`sms:+1${privacyOfficialInfo.phone}`">
							<svg
								fill="currentcolor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M10 15l-4 4v-4H2a2 2 0 0 1-2-2V3c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-8zM5 7v2h2V7H5zm4 0v2h2V7H9zm4 0v2h2V7h-2z"></path>
							</svg>
						</a>
					</div>
				</div>
			</div>

			<div
				class="practice-page__so-info card-shadow1"
				v-if="securityOfficialInfo">
				<div class="po-description description">
					Our practice has a <span>SECURITY OFFICIAL</span> appointed to oversee our information security
					policies, procedures, and technical systems in order to maintain the confidentiality,
					integrity, and availability of all the practice’s electronic protected health information
					(ePHI). The responsibilities associated with this role have been designated to:
				</div>
				<div
					class="image-wrapper"
					v-if="securityOfficialInfo.image">
					<!-- <img
						src="@/assets/images/member-3.png"
						alt="###" /> -->
					<img
						:src="securityOfficialInfo.image"
						alt="###" />
				</div>
				<div
					class="svg-wrapper"
					v-else>
					<svg
						viewBox="0 0 16 20"
						fill="currentcolor"
						id="user">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M13.294 5.29105C13.294 8.22808 10.9391 10.5831 8 10.5831C5.0619 10.5831 2.70601 8.22808 2.70601 5.29105C2.70601 2.35402 5.0619 0 8 0C10.9391 0 13.294 2.35402 13.294 5.29105ZM8 20C3.66237 20 0 19.295 0 16.575C0 13.8539 3.68538 13.1739 8 13.1739C12.3386 13.1739 16 13.8789 16 16.599C16 19.32 12.3146 20 8 20Z" />
					</svg>
				</div>

				<div class="po-name name">{{ securityOfficialInfo.fullName }}</div>
				<div
					class="po-name title"
					v-if="securityOfficialInfo.title">
					{{ securityOfficialInfo.title }}
				</div>
				<div class="po-phone phone">
					{{ maskedPhone(securityOfficialInfo.phone) }}
					<div class="actions">
						<a
							class="icon-wrapper external"
							:href="`tel:+1${securityOfficialInfo.phone}`">
							<svg
								id="cell-phone"
								viewBox="0 0 512 512">
								<path
									d="M500.177 55.798s-21.735-7.434-39.551-11.967C411.686 31.369 308.824 24.727 256 24.727S100.314 31.369 51.374 43.831c-17.816 4.534-39.551 11.967-39.551 11.967C4.281 58.078-.621 65.322.063 73.172l8.507 97.835c.757 8.596 7.957 15.201 16.581 15.201h84.787c8.506 0 15.643-6.416 16.553-14.878l4.28-39.973c.847-7.93 7.2-14.138 15.148-14.815 0 0 68.484-6.182 110.081-6.182 41.586 0 110.08 6.182 110.08 6.182 7.949.676 14.302 6.885 15.148 14.815l4.29 39.973c.9 8.462 8.038 14.878 16.545 14.878h84.777c8.632 0 15.832-6.605 16.589-15.201l8.507-97.835c.685-7.85-4.216-15.094-11.759-17.374z"
									fill="currentcolor" />
								<path
									d="M357.503 136.629h-55.365v46.137h-92.275v-46.137h-55.365S145.27 256.586 34.541 344.247v129.95c0 7.218 5.857 13.076 13.075 13.076h416.768c7.218 0 13.076-5.858 13.076-13.076v-129.95c-110.73-87.661-119.957-207.618-119.957-207.618zM338.768 391.42v37.406h-37.396V391.42h37.396zm0-59.15v37.406h-37.396V332.27h37.396zm-37.396-21.752v-37.396h37.396v37.396h-37.396zm-26.674 80.902v37.406h-37.396V391.42h37.396zm0-59.15v37.406h-37.396V332.27h37.396zm0-59.148v37.396h-37.396v-37.396h37.396zM210.629 391.42v37.406h-37.397V391.42h37.397zm0-59.15v37.406h-37.397V332.27h37.397zm0-59.148v37.396h-37.397v-37.396h37.397z"
									fill="currentcolor" />
							</svg>
						</a>
						<a
							class="icon-wrapper external"
							:href="`sms:+1${securityOfficialInfo.phone}`">
							<svg
								fill="currentcolor"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M10 15l-4 4v-4H2a2 2 0 0 1-2-2V3c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-8zM5 7v2h2V7H5zm4 0v2h2V7H9zm4 0v2h2V7h-2z"></path>
							</svg>
						</a>
					</div>
				</div>
			</div>
		</div>
	</f7-page>
</template>

<script>
	import { mapGetters } from "vuex";
	import { getMaskedPhone } from "@/plugin/func";

	export default {
		async created() {
			await this.$store.dispatch("app/getPracticeInfo");
		},

		methods: {
			maskedPhone: getMaskedPhone,
		},
		computed: {
			// ...mapGetters("auth", ["userPractice"]),

			...mapGetters("app", ["practiceInfo"]),

			privacyOfficialInfo() {
				// return this.userPractice[0].client.practice.privacy;
				return this.practiceInfo?.client.practice.privacy;
			},
			securityOfficialInfo() {
				// return this.userPractice[0].client.practice.security;
				return this.practiceInfo?.client.practice.security;
			},

			location: function () {
				// return this.userPractice[0].client.practice.locations[0];
				return this.practiceInfo?.client.practice.locations[0] || undefined;
			},

			websiteUrl() {
				let website = this.location.website;
				if (website) return `http://${website}`;

				return null;
			},

			websiteName() {
				let website = this.location.website;
				if (website && website.indexOf("/") !== -1) website = website.slice(0, website.indexOf("/"));
				return website;
			},
		},
	};
</script>
