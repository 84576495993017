<template>
	<div class="training-procedure">
		<div class="training-procedure__container container">
			<div
				class="training-main-name main-title"
				v-html="procedure.name"></div>

			<CreatedBy
				:createdBy="procedure.createdBy"
				:createdAt="trainingAction.created"
				:reason="procedure.reason" />

			<!-- <ActionNavigationMarks :categories='categories'
														 :done="trainingAction.done"
														 :isProcedure="true"
														 @changeSlideMark="changeMark"
														 @marksrdy="$emit('rdyToRender')" /> -->

			<ActionNavigationBar
				:countOfBlocks="categories.length"
				:done="trainingAction.done" />

			<!-- <AssignedTo v-if="procedure.assigned.length > 0"
									:name="procedure.assigned[0].fullName"
									:image="procedure.assigned[0].image" /> -->
		</div>

		<div class="swiper-self-wrapper">
			<swiper
				class="swiper"
				v-bind="swiperParametrs"
				@slideChange="$emit('slideChanged')">
				<swiper-slide
					v-for="(category, idx) in categories"
					:key="idx"
					class="">
					<div class="swiper-slide-wrapper">
						<div
							class="slider-body card-shadow1"
							v-if="!category.fullname">
							<div
								class="procedure-title"
								v-html="category.name"></div>
							<div
								class="description"
								v-html="category.text"></div>
						</div>
						<div
							class="slider-body card-shadow1"
							v-else>
							<div
								class="procedure-title"
								v-html="category.name"></div>
							<div class="assigned-to__fullname">
								The responsibilities associated with this procedure have been assigned to:
								<div class="name">{{ category.fullname }}</div>
							</div>
							<div
								class="assigned-to__image-container"
								v-if="category.fullname && category.fullname !== 'PENDING'">
								<div
									class="assigned-to__image"
									v-if="category.image">
									<img
										:src="category.image"
										alt="" />
								</div>
								<div
									class="assigned-to__icon"
									v-else>
									<svg
										viewBox="0 0 512 512"
										fill="currentcolor">
										<path
											d="M332.64,64.58C313.18,43.57,286,32,256,32c-30.16,0-57.43,11.5-76.8,32.38-19.58,21.11-29.12,49.8-26.88,80.78C156.76,206.28,203.27,256,256,256s99.16-49.71,103.67-110.82C361.94,114.48,352.34,85.85,332.64,64.58Z" />
										<path
											d="M432,480H80A31,31,0,0,1,55.8,468.87c-6.5-7.77-9.12-18.38-7.18-29.11C57.06,392.94,83.4,353.61,124.8,326c36.78-24.51,83.37-38,131.2-38s94.42,13.5,131.2,38c41.4,27.6,67.74,66.93,76.18,113.75,1.94,10.73-.68,21.34-7.18,29.11A31,31,0,0,1,432,480Z" />
									</svg>
								</div>
							</div>
						</div>
					</div>
				</swiper-slide>

				<swiper-slide>
					<div class="swiper-slide-wrapper">
						<div class="slider-body card-shadow1">
							<div class="procedure-title">Acknowledgement:</div>
							<div class="description">
								<div class="confirm-block trainings-action-page">
									<label
										class="checkbox"
										@change="$emit('agreeWithProcedure')">
										<input
											type="checkbox"
											:disabled="trainingAction.done"
											:checked="trainingAction.done" />
										<i class="icon-checkbox"> </i>
										<div class="text">
											I have reviewed our practice's
											{{ procedure.name }}
											and agree to comply with these expectations.
											<div
												v-if="trainingAction.done"
												class="accepted">
												{{
													`You accepted on
													 												${transformDate(trainingAction.doneStaffMap[0]?.created)}`
												}}
											</div>
										</div>
									</label>
									<div
										class="submit-button trainings-action-page"
										v-if="!trainingAction.done">
										<f7-button @click="$emit('submit')"> Submit </f7-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</swiper-slide>
			</swiper>
		</div>
	</div>
</template>

<script>
	import { Swiper, SwiperSlide } from "swiper/vue";
	import { Navigation, Pagination } from "swiper";
	import "swiper/css";

	import { mapGetters } from "vuex";

	import AssignedTo from "@/components/AssignedTo.vue";
	import CreatedBy from "@/components/CreatedBy.vue";
	import ActionNavigationBar from "@/components/ActionNavigationBar.vue";

	export default {
		data() {
			return {
				agreedWithAction: false,
				swiperParametrs: {
					slidesPerView: 1,
					autoHeight: true,
					modules: [Navigation, Pagination],
				},
				categories: [],
			};
		},

		props: {
			showDescription: Boolean,
			procedure: Object,
			trainingAction: Object,
			transformDate: Function,
		},

		components: {
			AssignedTo,
			SwiperSlide,
			Swiper,
			CreatedBy,
			ActionNavigationBar,
		},

		created() {
			this.categories.push({
				name: `${this.trainingAction.policy.name}:`,
				text: this.trainingAction.policy.text,
			});

			if (this.procedure.objective) {
				this.categories.push({
					name: "Objective:",
					text: this.procedure.objective,
				});
			}

			if (this.procedure.assigned[0]?.fullName) {
				this.categories.push({
					name: "Assigned to:",
					fullname: this.procedure.assigned[0].fullName,
					image: this.procedure.assigned[0].image,
				});
			} else {
				this.categories.push({
					name: "Assigned to:",
					fullname: "PENDING",
					image: undefined,
				});
			}

			if (this.procedure.when_cirum) {
				this.categories.push({
					name: "Circumstances:",
					text: this.procedure.when_cirum,
				});
			}

			if (this.procedure.json.items) {
				for (let item of this.procedure.json.items) {
					if (this.isPrivacyOfficial && item.assigned === "ROLE_PRIVACY_OFFICIAL") {
						this.categories.push({
							name: "Procedure for <i>YOU</i> as Privacy Official:",
							text: item.content,
						});
					} else if (this.isSecurityOfficial && item.assigned === "ROLE_SECURITY_OFFICIAL") {
						this.categories.push({
							name: "Procedure for <i>YOU</i> as Security Official:",
							text: item.content,
						});
					}
					if (item.assigned === "ALL_STAFF") {
						this.categories.push({
							name: "Procedure for <i>ALL</i> Staff:",
							text: item.content,
						});
					}
				}
			}

			if (this.procedure.requirements) {
				this.categories.push({
					name: "Requirements:",
					text: this.procedure.requirements,
				});
			}
		},

		methods: {
			changeMark(idx) {
				this.$emit("changeSlideFromMark", idx);
			},
		},

		computed: {
			...mapGetters("auth", ["isOfficial", "isPrivacyOfficial", "isSecurityOfficial", "isSecurityOfficial"]),

			forWhom() {
				if (this.procedure.json.items[0].assigned === "ROLE_SECURITY_OFFICIAL" && this.isSecurityOfficial) {
					return "for Security Official";
				}
				if (this.procedure.json.items[0].assigned === "ROLE_PRIVACY_OFFICIAL" && this.isPrivacyOfficial) {
					return "for Privacy Official";
				}
				return false;
			},

			isPrivacyOrSecurityOfficial() {
				if (
					(this.procedure.json.items[0].assigned === "ROLE_SECURITY_OFFICIAL" && this.isSecurityOfficial) ||
					(this.procedure.json.items[0].assigned === "ROLE_PRIVACY_OFFICIAL" && this.isPrivacyOfficial)
				) {
					return true;
				}
				return false;
			},
		},
	};
</script>
<style lang="scss"></style>
