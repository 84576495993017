import { AXIOS } from "@/plugin/axios";

export const AUTH_SERVICE = {
	getTimezone() {
		return -(new Date().getTimezoneOffset() / 60)
	},

	async login(params) {
		return await AXIOS.post("login-check-phone", params);
	},
	async loginVerification(params) {
		return await AXIOS.post("login-phone", params);
	},
	async checkForPractice(params) {
		params = {...params, utc: this.getTimezone()}

		return await AXIOS.post("login-select-practice", params);
	},
	async verifyPractice(params) {
		params = {...params, utc: this.getTimezone()}

		return await AXIOS.post("verify", params);
	},
};
