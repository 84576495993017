export default {
	REGEX: /<(.|\n)*?>|([\s\.,'";$!()?])|&(.|\n)*?;/gm,

	sortDesignations: (arr = undefined) => {
		// console.log(arr);
		// let arr2 = ["privacy", "security", "owner", "security", "owner", "security"];
		// console.log(arr2);

		arr.sort((a, b) => {
			// arr2.sort((a, b) => {
			const roleA = a.role[1].toLowerCase();
			const roleB = b.role[1].toLowerCase();

			// const roleA = a;
			// const roleB = b;

			if (roleA.indexOf("owner") !== -1) {
				return -1;
			} else if (roleB.indexOf("owner") !== -1) {
				return 1;
			}

			if (roleA.indexOf("privacy") !== -1) {
				return -1;
			} else if (roleB.indexOf("privacy") !== -1) {
				return 1;
			}

			if (roleA.indexOf("security") !== -1) {
				return -1;
			} else if (roleB.indexOf("security") !== -1) {
				return 1;
			}
		});

		return arr;
	},

	getPublishedProcedure(proceduresArray) {
		return proceduresArray.filter(obj => obj.actions[0]?.children?.length > 0 && obj.actions[0]?.children[0].Publish);
	},

	getUnpublishedProcedure(proceduresArray) {
		return proceduresArray.filter(obj => obj.actions[0]?.children?.length === 0 || !obj.actions[0]?.children[0]?.Publish);
	},

	filterText(string) {
		let str = string.toLowerCase().replace(this.REGEX, "");
		return str;
		// return string.toLowerCase().replace(this.REGEX, "");
	},

	findStr(str, searchingStr) {
		if (this.filterText(str).indexOf(this.filterText(searchingStr)) !== -1) {
			return true;
		}
	},

	filterHandbookProcedures(i, searchingStr) {
		if (
			this.findStr(i.name, searchingStr) ||
			this.findStr(i.objective, searchingStr) ||
			this.findStr(i.requirements, searchingStr) ||
			this.findStr(i.when_cirum, searchingStr) ||
			this.findStr(i.instructions, searchingStr)
		) {
			return true;
		}

		return false;
	},
};
