<template>
	<f7-page
		class="training-action"
		v-if="trainingAction">
		<BackButton />

		<Procedure
			v-if="trainingAction.procedure !== null"
			:procedure="trainingAction.procedure"
			:trainingAction="trainingAction"
			:showDescription="showDescription"
			:transformDate="transformDate"
			@agreeWithProcedure="agreeWithProcedure"
			@slideChanged="slideChanged"
			@changeSlideFromMark="changeSlideFromMarkMain"
			@submit="submit" />

		<Agreement
			v-if="trainingAction.agreement !== null"
			:agreement="trainingAction.agreement"
			:trainingAction="trainingAction"
			:showDescription="showDescription"
			:transformDate="transformDate"
			@agreeWithProcedure="agreeWithProcedure"
			@slideChanged="slideChanged"
			@changeSlideFromMark="changeSlideFromMarkMain"
			@submit="submit" />

		<Video
			v-if="trainingAction.video !== null"
			:trainingAction="trainingAction"
			:video="trainingAction.video"
			@submit="submit"
			@agreeWithAction="agreeWithProcedure" />

		<div
			class="tabs-container"
			v-if="trainingAction.video === null">
			<div class="tabs-buttons">
				<f7-button @click="changeSlide('prev')">
					<div class="icon-wrapper left">
						<svg
							viewBox="0 0 24 24"
							fill="currentcolor"
							xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M15.7071 18.7071C16.0976 18.3166 16.0976 17.6834 15.7071 17.2929L10.4142 12L15.7071 6.70711C16.0976 6.31658 16.0976 5.68342 15.7071 5.29289C15.3166 4.90237 14.6834 4.90237 14.2929 5.29289L8.29289 11.2929C7.90237 11.6834 7.90237 12.3166 8.29289 12.7071L14.2929 18.7071C14.6834 19.0976 15.3166 19.0976 15.7071 18.7071Z" />
						</svg>
					</div>
				</f7-button>
				<f7-button @click="changeSlide('next')">
					<div class="icon-wrapper right">
						<svg
							viewBox="0 0 24 24"
							fill="currentcolor"
							xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071Z" />
						</svg>
					</div>
				</f7-button>
			</div>
		</div>
	</f7-page>
</template>
<script>
	import LS from "@/plugin/localstorage";

	import Procedure from "./actions/Procedure.vue";
	import Agreement from "./actions/Agreement.vue";
	import Video from "./actions/Video.vue";
	import { mapGetters } from "vuex";

	export default {
		data() {
			return {
				agreedWithAction: false,
				showDescription: true,
				funcCounter: 1,
				defs: [],
			};
		},

		components: {
			Procedure,
			Agreement,
			Video,
		},

		created() {
			this.videoID = this.trainingAction.video ? this.trainingAction.video.vimeoId : null;
			this.defs = LS.get("definitions");
			this.defs = Object.values(this.defs);
		},

		mounted() {
			if (this.trainingAction.video === null) {
				this.swiper = document.querySelector(".swiper").swiper;
			}

			document.querySelectorAll(".def").forEach(e => {
				e.addEventListener("click", e => {
					let id = e.target.getAttribute("def");
					let store = this.$store.state.app;
					let def;
					def = this.defs.find(e => e.id == id);

					store.popupTitle = `Define: ${def.term.charAt(0).toUpperCase() + def.term.slice(1)}`;
					store.popupText = def.definition;
					store.popupShow = true;
				});
			});
		},

		methods: {
			getAmPm(x) {
				if (x >= 12) {
					return "PM";
				}
				return "AM";
			},

			getHoursByAmPm(x) {
				if (x > 12) {
					return x - 12;
				}
				return x;
			},

			checkMinutes(x) {
				if (x < 10) {
					return "0";
				}
				return "";
			},

			transformDate(date) {
				date = new Date(date);
				let month = date.getMonth() + 1;
				let day = date.getDate();
				let year = date.getFullYear().toString().substr(-2);
				if (month < 10) {
					month = "0" + month;
				}
				if (day < 10) {
					day = "0" + day;
				}
				return `${month}/${day}/${year}`;
			},

			agreeWithProcedure() {
				this.agreedWithAction = !this.agreedWithAction;
			},

			changeSlide(where) {
				if (where === "next") this.swiper.slideTo(this.swiper.activeIndex + 1);
				else this.swiper.slideTo(this.swiper.activeIndex - 1);

				this.changeActiveMark(this.swiper.activeIndex, where);
			},

			slideChanged() {
				document.getElementById(`${this.swiper.activeIndex}`).classList.add("active");

				if (document.getElementById(`${this.swiper.activeIndex - 1}`)) {
					if (!this.trainingAction.done) {
						document.getElementById(`${this.swiper.activeIndex - 1}`).classList.add("done");
					}
					document.getElementById(`${this.swiper.activeIndex - 1}`).classList.remove("active");
				}

				if (document.getElementById(`${this.swiper.activeIndex + 1}`)) {
					document.getElementById(`${this.swiper.activeIndex + 1}`).classList.remove("active");
				}
			},

			changeActiveMark(x, where) {
				document.getElementById(`${x}`).classList.add("active");

				if (where === "next") {
					document.getElementById(`${this.swiper.activeIndex - 1}`).classList.remove("active");
				} else {
					document.getElementById(`${this.swiper.activeIndex + 1}`).classList.remove("active");
				}
			},

			changeSlideFromMarkMain(x) {
				let where = false;

				if (x - 1 === this.swiper.activeIndex) where = "next";
				if (x + 1 === this.swiper.activeIndex) where = "prev";

				if (where) {
					this.swiper.slideTo(x);
					this.changeActiveMark(this.swiper.activeIndex, where);
				}

				if (this.trainingAction.done) {
					document.getElementById(`${this.swiper.activeIndex}`).classList.remove("active");
					this.swiper.slideTo(x);
					document.getElementById(`${x}`).classList.add("active");
				}
			},

			submit() {
				if (this.agreedWithAction && this.trainingAction.id && !this.trainingAction.done) {
					this.$store.dispatch("app/setActionDone", { actId: this.trainingAction.id }).then(resp => {
						if (resp.status) {
							this.$store.dispatch("trainings/getTrainings");
							f7router.navigate("/trainings-page/current-training", {
								reloadCurrent: true,
							});
						}
					});
				}
			},
		},

		computed: {
			...mapGetters("trainings", ["trainingAction"]),
		},
	};
</script>
<style lang="scss"></style>
