<template>
	<div class="homepage-user-practice">
		<div class="homepage-user-practice__container">
			<div class="homepage-user-practice__header section-header">
				<div class="homepage-user-practice__title section-header__title">Your practice</div>
				<!-- <div class="homepage-user-practice__link section-header__link">See all</div> -->
			</div>
			<f7-link
				class="homepage-user-practice__block card-shadow1"
				@click="navigateToPracticePage">
				<div class="image-block">
					<div
						class="image-wrapper"
						v-if="1">
						<!-- <img
							src="@/assets/images/pepsiCo.png"
							alt="###" /> -->
						<img
							src="@/assets/images/logo.png"
							alt="###" />
						<!-- <img
							:src="location.image"
							alt="###" /> -->
					</div>
					<div
						class="svg-wrapper"
						v-else>
						<svg
							id="building"
							viewBox="0 0 512 512"
							xml:space="preserve"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="m52.963 0v512h176.55v-123.59h52.965v123.59h176.56v-512h-406.07zm123.59 441.38h-52.966v-52.966h52.966v52.966zm0-105.93h-52.966v-52.966h52.966v52.966zm0-105.93h-52.966v-52.966h52.966v52.966zm0-105.93h-52.966v-52.965h52.966v52.965zm105.93 211.86h-52.965v-52.966h52.965v52.966zm0-105.93h-52.965v-52.966h52.965v52.966zm0-105.93h-52.965v-52.965h52.965v52.965zm105.94 317.79h-52.97v-52.966h52.97v52.966zm0-105.93h-52.97v-52.966h52.97v52.966zm0-105.93h-52.97v-52.966h52.97v52.966zm0-105.93h-52.97v-52.965h52.97v52.965z"
								fill="currentcolor" />
						</svg>
					</div>
				</div>

				<div class="desc-block">
					<div class="title">{{ location.name }}</div>
					<div class="po">Privacy Official: 'Test User#1'</div>
					<div class="so">Security Official: 'Test User#1'</div>
				</div>
			</f7-link>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";

	export default {
		methods: {
			navigateToPracticePage() {
				f7router.navigate("practice-page");
			},
		},

		computed: {
			...mapGetters("auth", ["userPractice"]),
			location: function () {
				return this.userPractice[0].client.practice.locations[0];
			},
		},
	};
</script>

<style lang="scss">
	.homepage-user-practice {
		&__block {
			display: flex;
			margin: 0 24px;

			// border: 1px solid red;

			.image-block {
				flex: 0 1 23%;

				svg {
					width: 70%;
					min-width: 50px;
				}
				img {
					width: 80%;
				}
				.svg-wrapper,
				.image-wrapper {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			.desc-block {
				flex-grow: 1;
				padding: 8px 8px 8px 0;

				.title {
					font-size: 20px;
					display: inline-block;
					border-bottom: 2px solid var(--primary-font-color);
					margin-bottom: 5px;
					@media (max-width: 389.99px) {
						font-size: 18px;
					}
				}
			}

			.po,
			.so {
				font-size: 15px;
				@media (max-width: 389.99px) {
					font-size: 12px;
				}
			}
		}
	}
</style>
