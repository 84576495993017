<template>
	<!-- :style='{ "transition": `all ${transitionTime}s linear` }' -->
	<div
		class="loading-page"
		:class="{ disable: !showLoadingPage }"
	>
		<img
			src="@/assets/images/loading-screen.png"
			alt=""
		/>
	</div>
</template>
<script>
	export default {
		props: {
			showingTime: Number,
			transitionTime: Number,
		},

		mounted() {
			setTimeout(() => {
				this.$store.commit("showLoadingPage", false);
			}, this.showingTime);
		},
		computed: {
			showLoadingPage: function () {
				return this.$store.state.showLoadingPage;
			},
		},
	};
</script>
<style lang="scss">
	@import "@/sass/_vars";

	.loading-page {
		height: 100%;
		width: 100%;

		position: absolute;
		top: 0;
		left: 0;
		z-index: 100000;

		overflow: hidden;

		transition: all 0s;
		background-color: var(--page-bg-color);
		// background-color: var(--main-font-color);

		&.disable {
			visibility: 0;
			opacity: 0;
			pointer-events: none;
			transition: all 0.5s linear;
		}

		img {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}
</style>
