<template>
	<div class="success-designation-screen">

		<p>
			<!-- <svg viewBox="0 0 489.2 489.2"
					 fill="currentcolor">
				<path d="M39.5,377.35h160.9v44.1H169c-5.2,0-9.5,4.2-9.5,9.5v27.5c0,5.2,4.2,9.5,9.5,9.5h151.2c5.2,0,9.5-4.2,9.5-9.5v-27.5
											c0-5.2-4.2-9.5-9.5-9.5h-31.4v-44.1h160.9c21.8,0,39.5-17.7,39.5-39.5V60.75c0-21.8-17.7-39.5-39.5-39.5H39.5
											C17.7,21.25,0,38.95,0,60.75v277.1C0,359.65,17.7,377.35,39.5,377.35z M53.9,75.15h381.4v248H53.9V75.15z M295.5,135.05
											l31.1,31.3l-71.7,71.3l-31.3,31.1l-31.1-31.2l-30-30.2l31.3-31.1l30,30.2L295.5,135.05z" />
			</svg> -->

			<!-- TODO: check icon -->
			<!-- <svg fill="currentcolor"
					 viewBox="0 0 24 24">
				<path fill-rule="evenodd"
							d="M11,18 L5,18 C3.34314575,18 2,16.6568542 2,15 L2,5 C2,3.34314575 3.34314575,2 5,2 L19,2 C20.6568542,2 22,3.34314575 22,5 L22,15 C22,16.6568542 20.6568542,18 19,18 L13,18 L13,20 L15,20 C15.5522847,20 16,20.4477153 16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 C8,20.4477153 8.44771525,20 9,20 L11,20 L11,18 Z M19,4 L5,4 C4.44771525,4 4,4.44771525 4,5 L4,15 C4,15.5522847 4.44771525,16 5,16 L19,16 C19.5522847,16 20,15.5522847 20,15 L20,5 C20,4.44771525 19.5522847,4 19,4 Z" />
			</svg> -->

		<div class="hipaa-icon-tooth-pc"
				 style="font-size: 90px"></div>

		</p>
		<p v-if="designation">
			You have accepted the role and responsibilities of
			{{ designation?.role[1] }}.
		</p>
		<p v-if="adminEmail.length > 0">We have sent a verification link to {{ adminEmail || 'THERE IS NO EMAIL SETTED' }}</p>
		<p>
			Please use your desktop computer to access the administrative features
			of Bite-Size HIPAA.
		</p>
	</div>
</template>

<script>
export default {
	props: {
		designation: Object,
		adminEmail: String,
	},
};
</script>

<style lang="scss">
@import '@/sass/_vars.scss';

.success-designation-screen {

	svg {
		width: 130px;
		// color: var(--primary-color);
	}

	font-size: 17px;
	margin: 0 24px;
	display: flex;
	flex-direction: column;
	text-align: center;
}

.block {
	content: "\e911";
}
</style>
