<template>
	<div class="homepage-trainings">
		<div class="homepage-trainings__wrapper">
			<div class="homepage-trainings__header section-header">
				<div class="title section-header__title">Required Training</div>
				<div class="link section-header__link">
					<f7-link @click="toTrainingsPage">See all </f7-link>
				</div>
			</div>

			<div class="homepage-trainings__links">
				<a
					href="#tab-1"
					class="tab-link tab-link-active"
					>Assigned</a
				>
				<a
					href="#tab-2"
					class="tab-link"
					>Completed</a
				>
			</div>

			<div class="tabs-animated-wrap">
				<div class="tabs">
					<div
						id="tab-1"
						class="page-content tab tab-active"
					>
						<HomepageTrainingsList
							:trainings="uncompleteTrainings"
							:done="false"
							@toTraining="toCurrentTraining"
						/>
					</div>

					<div
						id="tab-2"
						class="page-content tab"
					>
						<HomepageTrainingsList
							:trainings="completeTrainings"
							:done="true"
							@toTraining="toCurrentTraining"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import "swiper/css";

	import { Swiper, SwiperSlide } from "swiper/vue";
	import HomepageTrainingsList from "./HomepageTrainingsList.vue";

	export default {
		props: {
			uncompleteTrainings: Array,
			completeTrainings: Array,
		},

		mounted() {
			const tabLinks = document.querySelectorAll(".tab-link");
			const tabs = document.querySelectorAll(".tab");

			if (!this.uncompleteTrainings && this.completeTrainings) {
				tabs.forEach((el) => {
					el.classList.toggle("tab-active");
				});
				tabLinks.forEach((el) => {
					el.classList.toggle("tab-link-active");
				});
			}
		},

		components: {
			Swiper,
			SwiperSlide,
			HomepageTrainingsList,
		},

		methods: {
			toCurrentTraining(id) {
				this.$store.dispatch("trainings/getTrainings").then(() => {
					this.$store.commit("trainings/setTrainingId", id);
					f7router.navigate("/trainings-page/current-training");
				});
			},

			toTrainingsPage() {
				f7router.navigate("/trainings-page");
			},
		},
	};
</script>

<style></style>
