<template>
	<div class="homepage-training-list">
		<div class="link-container">
			<HomepageTrainingsListItem
				v-for="training in trainings"
				:key="training.id"
				:training="training"
				:done="done"
				@click="toTraining(training.id)"
			/>
		</div>
	</div>
</template>

<script>
	import HomepageTrainingsListItem from "./HomepageTrainingsListItem.vue";

	export default {
		props: {
			trainings: Array,
			done: Boolean,
		},

		components: {
			HomepageTrainingsListItem,
		},

		methods: {
			toTraining(id) {
				this.$emit("toTraining", id);
			},
		},
	};
</script>

<style lang="scss"></style>
