<template>
	<div
		class="success-block"
		style="width: 100%: margin: auto 24px"
		v-if="showSuccessMessage"
	>
		<div class="var-message">
			<div class="text">Verification complete. Welcome to Bite-Size HIPAA.</div>
		</div>

		<!-- ? FUTURE FUNCTIONAL -->
		<!-- TO FUTURE -->
		<!-- <div class="submit-button">
				<f7-button class="disabled"> Add a Bite-Size HIPAA shortcut to your phone </f7-button>
			</div> -->
		<!-- ? FUTURE FUNCTIONAL -->

		<div
			class="var-message"
			style="text-align: center; padding: 0 0 20px 0"
			v-if="chosenPracticeName"
		>
			{{ chosenPracticeName }}
		</div>

		<div class="submit-button">
			<f7-button @click="toHomePage"> Continue to Bite-Size HIPAA </f7-button>
		</div>
	</div>

	<div
		class="error-block"
		v-if="showErrorMessage"
	>
		<div class="err-message">{{ errMsg }}</div>
		<div class="submit-button">
			<f7-button @click="destroy"> Return to login </f7-button>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			destroy: Function,
			toHomePage: Function,
			chosenPracticeName: String,
			errMsg: String,
			showErrorMessage: Boolean,
			showSuccessMessage: Boolean,
		},
	};
</script>
<style lang="scss"></style>
