<template>
	<div class="training-agreement">
		<div class="training-agreement__container container">
			<div
				class="agreement-head training-main-name main-title"
				v-html="agreement.title"
			></div>

			<CreatedBy
				:createdBy="agreement.createdBy"
				:createdAt="agreement.updatedAt"
				:reason="agreement.reason"
			/>

			<!-- <DoneActiveMarks :marksCount='marksCount'
											 :done="trainingAction.done"
											 :isAgreement="true"
											 @changeSlideMark="changeSlideFromMarkProc" /> -->

			<ActionNavigationBar
				:countOfBlocks="marksCount"
				:done="trainingAction.done"
			/>
		</div>

		<div class="swiper-self-wrapper">
			<swiper
				class="swiper"
				v-bind="swiperParametrs"
				@slideChange="$emit('slideChanged')"
			>
				<swiper-slide
					v-for="(content, idx) in agreementContentSwiper.children"
					:key="idx"
					class=""
				>
					<div class="swiper-slide-wrapper">
						<div class="slider-body card-shadow1">
							<div class="procedure-title">
								<span> {{ idx + 1 }}. </span>
								<span v-html="content.content"></span>
							</div>
							<ol class="description">
								<li
									class="unordered-list-of-paragraphs"
									v-for="(content, idx) in content.children"
									:key="idx"
								>
									<span v-html="content.content"></span>
								</li>
							</ol>
						</div>
					</div>
				</swiper-slide>
				<swiper-slide>
					<div class="swiper-slide-wrapper">
						<div class="slider-body card-shadow1">
							<div class="procedure-title">
								<span> {{ agreementContentSwiper.children.length + 1 }}. </span>Acknowledgement:
							</div>
							<div
								class="description"
								style="margin-bottom: 20px"
							>
								<p
									v-for="(content, idx) of agreementContentConfirm"
									:key="idx"
									v-html="content.content"
								></p>
								<div class="confirm-block trainings-action-page">
									<label
										class="checkbox"
										@change="$emit('agreeWithProcedure')"
									>
										<input type="checkbox" />
										<i class="icon-checkbox"> </i>
										<div class="text">
											I have reviewed our practice's
											{{ agreement.title }}
											and agree to comply with these expectations.
											<div
												v-if="trainingAction.done"
												class="accepted"
											>
												{{
													`You accepted on
													 												${transformDate(trainingAction.doneStaffMap[0]?.created)}`
												}}
											</div>
										</div>
									</label>
									<div
										class="submit-button trainings-action-page"
										v-if="!trainingAction.done"
									>
										<f7-button @click="$emit('submit')"> Submit </f7-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</swiper-slide>
			</swiper>
		</div>
	</div>
</template>
<script>
	import { Swiper, SwiperSlide } from "swiper/vue";
	import { Navigation, Pagination } from "swiper";
	import "swiper/css";

	import CreatedBy from "@/components/CreatedBy.vue";
	import ActionNavigationBar from "@/components/ActionNavigationBar.vue";

	export default {
		beforeMount() {
			this.marksCount = this.agreementContentSwiper.children.length || 1;
		},

		mounted() {
			if (this.trainingAction.done) {
				const checkbox = document.querySelector('input[type="checkbox"]');
				checkbox.checked = true;
				checkbox.disabled = true;
			}
		},

		data() {
			return {
				marksCount: "",
				agreedWithAction: false,
				swiperParametrs: {
					slidesPerView: 1,
					autoHeight: true,
					modules: [Navigation, Pagination],
				},
			};
		},

		props: {
			showDescription: Boolean,
			agreement: Object,
			trainingAction: Object,
			transformDate: Function,
		},

		components: {
			SwiperSlide,
			Swiper,
			CreatedBy,
			ActionNavigationBar,
		},

		methods: {
			changeSlideFromMarkProc(x) {
				this.$emit("changeSlideFromMark", x);
			},
		},

		computed: {
			agreementContent() {
				return this.$store.getters["trainings/agreementContent"];
			},

			agreementContentConfirm() {
				return this.$store.getters["trainings/agreementContentConfirm"];
			},

			agreementContentSwiper() {
				return this.$store.getters["trainings/agreementContentSwiper"];
			},

			agreementContentDescription() {
				return this.$store.getters["trainings/agreementContentDescription"];
			},

			trainingsAgreementParagraphToRender() {
				return this.$store.getters["trainings/trainingsAgreementParagraphToRender"];
			},

			partOfArrayWithChildren() {
				return this.$store.getters["trainings/partOfArrayWithChildren"];
			},
		},
	};
</script>
<style lang="scss"></style>
