<template>
	<div class="main-directory">
		<div class="main-directory-wrapper">
			<div class="main-info-block">
				<div class="title">My Directory</div>
				<div class="link">
					<f7-link @click="setCurrentPage('directory')"
									 tab-link
									 href="/directory">See all
					</f7-link>
				</div>
			</div>
			<swiper class="swiper"
							:slides-per-view="4"
							:space-between="20">
				<swiper-slide v-for="(user, index) in list"
											:key="index">
					<f7-link class="swiper-slide"
									 href="/directoryinfo"
									 @click="toDetails(user)">
						<div class="img-wrapper"
								 v-if="user.image">
							<img :src="user.image" />
						</div>
						<div v-else
								 class="img-wrapper">
							<div class="icon">
							</div>
						</div>
						<div class="name"
								 style="text-align:center">{{ user.name }} {{ user.where }}</div>
					</f7-link>
				</swiper-slide>
			</swiper>
		</div>
	</div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";
export default {
	data() {
		return {};
	},
	created() {
		// console.log(this.directory);
	},
	props: {
		directory: Array,
		list: Array,
	},
	components: {
		Swiper,
		SwiperSlide,
	},
	computed: {
		// users() {
		//    return this.directory.filter((i) => i.type === "user");
		// },
	},
	setup() { },
	mounted() { },
	updated() { },
	methods: {
		setCurrentPage(id) {
			this.$store.commit("app/setCurrentPage", id);
		},
		toDetails(info) {
			this.$store.commit("app/setCurrentItemInfo", info);
			f7.views.main.router.navigate("/directoryinfo");
			this.$store.commit("app/setCurrentPage", "directory");
		},
	},
};
</script>
