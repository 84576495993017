<template>
	<div class="author__assigned-to card-shadow1">
		<div class="text-for">
			The responsibilities associated with this procedure have been assigned to:
			<div
				class="name"
				style="text-align: center"
        v-if="assigned"
			>
				{{ assigned.fullName || assigned.id }}
			</div>
			<div
				class="name"
				style="text-align: center"
				v-else
			>
				PENDING
			</div>
		</div>
		<div
			class="main-image"
			v-if="assigned"
		>
			<div
				v-if="assigned.image"
				class="author-image"
			>
				<img
					:src="assigned.image"
					alt=""
				/>
			</div>
			<div
				v-else
				class="author-icon"
			>
				<!-- <svg
					viewBox="0 0 512 512"
					fill="currentcolor"
				>
					<path
						d="M332.64,64.58C313.18,43.57,286,32,256,32c-30.16,0-57.43,11.5-76.8,32.38-19.58,21.11-29.12,49.8-26.88,80.78C156.76,206.28,203.27,256,256,256s99.16-49.71,103.67-110.82C361.94,114.48,352.34,85.85,332.64,64.58Z"
					/>
					<path
						d="M432,480H80A31,31,0,0,1,55.8,468.87c-6.5-7.77-9.12-18.38-7.18-29.11C57.06,392.94,83.4,353.61,124.8,326c36.78-24.51,83.37-38,131.2-38s94.42,13.5,131.2,38c41.4,27.6,67.74,66.93,76.18,113.75,1.94,10.73-.68,21.34-7.18,29.11A31,31,0,0,1,432,480Z"
					/>
				</svg> -->

				<svg
					viewBox="0 0 16 20"
					fill="currentcolor"
					id="user"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M13.294 5.29105C13.294 8.22808 10.9391 10.5831 8 10.5831C5.0619 10.5831 2.70601 8.22808 2.70601 5.29105C2.70601 2.35402 5.0619 0 8 0C10.9391 0 13.294 2.35402 13.294 5.29105ZM8 20C3.66237 20 0 19.295 0 16.575C0 13.8539 3.68538 13.1739 8 13.1739C12.3386 13.1739 16 13.8789 16 16.599C16 19.32 12.3146 20 8 20Z"
					/>
				</svg>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			assigned: Object,
		},
	};
</script>
<style lang="scss">
	@import "../sass/vars";

	.author__assigned-to {
		display: flex;
		align-items: center;
		flex-direction: column;
		padding: 15px;
		margin: 0 0 20px 0;

		border-radius: 0.2rem;

		letter-spacing: 0.2px;
		font-family: "Urbanist";
		font-weight: 700;

		font-size: 16px;
		line-height: 1.4;

		// @media (max-width: 374.9px) {
		// 	font-size: 15px;
		// 	line-height: 1.3;
		// }

		.author-image {
			display: flex;
			align-items: center;
			max-width: 150px;
			// margin-right: 16px;

			img {
				width: 100%;
				border-radius: 999px;
			}

			svg {
				width: 100%;
				color: $amber;
			}
		}

		.author-icon {
			background-color: var(--primary-color);
			border-radius: 999px;
			width: 150px;

			aspect-ratio: 1/1;

			display: flex;
			align-items: center;
			justify-content: center;

			color: $white;

			svg {
				width: 100px;
				aspect-ratio: 1/1;

				color: var(--main-font-color);
			}
		}

		// .img-wrapper {
		// 	display: flex;
		// 	align-items: center;
		// 	max-width: 70px;
		// 	margin-right: 16px;
		// background-color: black;

		// .icon {
		// width: 100%;
		// }

		// svg {
		// width: 100%;
		// color: black;
		// }
		// }

		.text-for {
			.name {
				text-align: center;
				font-size: 20px;
				font-family: Nexa;
				font-weight: 700;
				margin: 10px 0;
			}

			span {
				color: var(--primary-color);
			}
		}
	}
</style>
