<template lang="">
	<div v-if="quizInfo">
		<div
			v-if="!quiz.name"
			class="options-for-name"
			style="width: 100%">
			<h1 style="text-align: center">Please tell me your name?</h1>
			<div
				class="submit-button"
				v-for="(name, idx) in quizInfo.name"
				:key="idx"
				@click="quiz.name = name">
				<f7-button>
					{{ name }}
				</f7-button>
			</div>
		</div>

		<div
			v-else-if="quiz.name && !quiz.where"
			class="options-for-where"
			style="width: 100%">
			<h1 style="text-align: center">Where do you work?</h1>

			<div
				class="submit-button"
				v-for="(where, idx) in quizInfo.where"
				:key="idx"
				@click="quiz.where = where">
				<f7-button>
					{{ where }}
				</f7-button>
			</div>
		</div>

		<div
			v-else
			class="options-for-coworker"
			style="width: 100%">
			<h1 style="text-align: center">Recognize a co-worker?</h1>

			<div
				class="submit-button"
				v-for="(coworker, idx) in quizInfo.coworker"
				:key="idx"
				@click="
					quiz.coworker = coworker;
					confirmWithQuiz();
				">
				<f7-button>
					{{ coworker }}
				</f7-button>
			</div>
		</div>
	</div>
</template>
<script>
	import { AUTH_SERVICE } from "@/api/auth";
	import { setAxiosToken } from "@/plugin/axios";
	import LS from "@/plugin/localstorage";

	import { mapGetters } from "vuex";

	export default {
		props: {
			quizInfo: Object,
			verifyData: Array,
		},

		data() {
			return {
				quiz: {},
			};
		},

		methods: {
			async confirmWithQuiz() {
				const { response, status } = await AUTH_SERVICE.verifyPractice({
					userId: this.verifyData[0],
					code: this.verifyData[1],
					quiz: this.quiz,
          phone_info: navigator.userAgent
				});
				if (status) {
					this.$store.commit("auth/setUserToken", response.data.token);
					this.$store.commit("auth/setUserId", this.verifyData[0]);
					this.$store.dispatch("app/loadDef");

					setAxiosToken(response.data.token);
					LS.set("update-time", Date.now());

					this.$emit("statusSuccess");
				} else {
					this.$emit("statusError", response.data.message);
				}
			},
		},
	};
</script>
<style lang=""></style>
