<template>
	<div class="log-in-screen">
		<div class="log-in-screen__wrapper">
			<Swiper
				:allow-touch-move="false"
				:speed="500"
				@swiper="getSwiperInstance"
			>
				<SwiperSlide>
					<Login
						@success="
							() => {
								slideNext();
								showConfirmCode = true;
							}
						"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<ConfirmCode
						v-if="showConfirmCode"
						@practiceChoice="
							() => {
								this.slideNext();
								showConfirmCode = false;
								showPracticeChoice = true;
							}
						"
						@goBack="
							() => {
								swiper.slideTo(0, 0);
								showConfirmCode = false;
							}
						"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<ChoiceOfPractice
						v-if="showPracticeChoice"
						@goBack="swiper.slideTo(0, 0)"
					/>
				</SwiperSlide>
			</Swiper>
		</div>
	</div>
</template>
<script>
	import { mapGetters } from "vuex";
	import { Swiper, SwiperSlide } from "swiper/vue";
	import ChoiceOfPractice from "@/components/authorization/ChoiceOfPractice.vue";
	import ConfirmCode from "@/components/authorization/ConfirmCode.vue";
	import Login from "@/components/authorization/Login.vue";

	import "swiper/css";

	export default {
		data() {
			return {
				showConfirmCode: false,
				showPracticeChoice: false,
			};
		},
		methods: {
			slideNext: function () {
				this.swiper.slideNext(0);
			},

			getSwiperInstance: function (swiper) {
				this.swiper = swiper;
			},
		},

		components: { Swiper, SwiperSlide, Login, ConfirmCode, ChoiceOfPractice },

		computed: {
			...mapGetters("auth", ["getPractices", "getVerify"]),
		},
	};
</script>

<style lang="scss"></style>
