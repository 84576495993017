export function getMaskedPhone(x) {
	if (x) {
		var y = x.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

		let value = !y[2] ? "(" + y[1] : "(" + y[1] + ") " + y[2] + (y[3] ? "-" + y[3] : "");
		if (y[1] || y[2] || y[3]) {
			return value;
		} else {
			return "";
		}
	}
}

export function goBack() {
	const router = f7.views.main.router;
	if (router.currentRoute.route?.isNested || router.previousRoute.route?.routes || router.previousRoute) {
		let currentRoutePath = router.currentRoute.route.path;
		let newRoutePath = "";
		let indexOf = currentRoutePath.lastIndexOf("/");
		indexOf !== 0 ? (newRoutePath = currentRoutePath.substr(0, indexOf)) : (newRoutePath = "/");
		// console.log(indexOf, newRoutePath);

		router.navigate(newRoutePath, {
			transition: "f7-cover",
			history: true,
			clearPreviousHistory: true,
			// animate: true,
			// force: true,
			// browserHistory: false,
			// reloadAll: true,
		});
	} else {
		router.back();
	}
}
