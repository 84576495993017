<template>
	<div class="login-vue">
		<div class="logo">
			<img
				src="@/assets/icons/logo.png"
				alt=""
			/>
		</div>
		<div class="login-title">Login to Staff Application</div>
		<div class="input-block">
			<div class="input-container">
				<div class="helper">
				
					<div class="svg-wrapper">
						<svg
							fill="currentcolor"
							viewBox="0 0 36 36"
						>
							<path
								d="M25,4H11A2,2,0,0,0,9,6V30a2,2,0,0,0,2,2H25a2,2,0,0,0,2-2V6A2,2,0,0,0,25,4ZM19,30H17V28h2Zm-8-4V6H25V26Z"
								class="clr-i-solid clr-i-solid-path-1"
							></path>
						</svg>
					</div>
				
					<f7-input
						type="text"
						placeholder="Enter phone number"
						required
						pattern="[0-9]*"
						clear-button
						:value="maskedPhone"
						@input="addPhone"
						maxlength="14"
						class="item-content item-input input-full my-input"
					>
					</f7-input>
					<div
						class="error-msg"
						v-show="errMsg"
					>
						{{ errMsg }}
					</div>
				</div>
			</div>
		</div>

		<Numericpad
			@addnumber="addNumber"
			@removenumber="removeNumber"
		/>

		<div class="submit-button">
			<f7-button
				class="submit button"
				:class="{ 'confirm-button': filledPhone }"
				@click="signIn"
				>Login
			</f7-button>
		</div>
	</div>
</template>

<script>
	import Numericpad from "@/components/authorization/numericpad.vue";
	import { getMaskedPhone } from "@/plugin/func";

	export default {
		components: {
			Numericpad,
		},
		data() {
			return {
				errMsg: false,
				phone: "",
				maskedPhone: "",
			};
		},
		props: {},

		computed: {
			filledPhone() {
				if (this.phone.length >= 10) return true;
				return false;
			},
		},

		watch: {
			phone() {
				this.errMsg = "";
				this.maskedPhone = getMaskedPhone(this.phone) || "";
			},
		},

		methods: {
			addNumber(x) {
				this.phone.length < 10 ? (this.phone += x) : false;
			},

			addPhone(e) {
				this.phone = e.target.value.replace(/[^\d]+/g, "");
			},

			removeNumber() {
				if (this.phone > 0) {
					this.phone = this.phone.slice(0, -1);
				}
			},

			async signIn() {
				const isLoginSuccess = await this.$store.dispatch("auth/checkPhone", {
					phone: this.phone,
				});
				if (isLoginSuccess === true) {
					this.phone = "";
					this.maskedPhone = "";
					this.$emit("success");
				} else {
					this.errMsg = isLoginSuccess;
				}
			},
		},
	};
</script>

<style></style>
