<template>
	<!-- Main Framework7 App component where we pass Framework7 params -->
	<f7-app v-bind="f7params">
		<!-- <f7-views> -->
		<f7-view
			class="app-page"
			:init="false"
			main
			@click="checkTokenLifeTime">
			<f7-toolbar
				v-if="showToolbars"
				position="top"
				:inner="false"
				no-hairline
				no-shadow>
				<Topbar />
			</f7-toolbar>

			<f7-toolbar
				v-if="showToolbars"
				position="bottom"
				:inner="false"
				no-hairline
				no-shadow>
				<MainMenu />
			</f7-toolbar>

			<LoadingPage
				:showingTime="1000"
				:transitionTime="0.5" />
		</f7-view>

		<Popup
			:popupShow="this.$store.state.app.popupShow"
			:title="this.$store.state.app.popupTitle"
			:reason="this.$store.state.app.popupText" />

		<ErrorHandlingModal v-if="getShowErrorHandlingModalStatus" />
	</f7-app>
</template>

<script>
	import Popup from "@/components/Popup.vue";
	import ErrorHandlingModal from "./components/ErrorHandlingModal.vue";
	import LoadingPage from "@/components/LoadingPage.vue";

	import routers from "./router";
	import MainMenu from "./components/main-menu.vue";
	import { f7, f7ready, theme } from "framework7-vue";

	import { goBack } from "@/plugin/func";

	import LS from "@/plugin/localstorage";
	import { mapGetters } from "vuex";

	export default {
		components: { MainMenu, Popup, LoadingPage, ErrorHandlingModal },

		data() {
			return {
				f7params: {
					// Theme
					theme: "ios",
					// Array with app routes
					routes: routers,
					// App Name
					name: "BitesizeHIPAA",
					// App id
					id: "com.smallhorse.bitesizehipaa.staff",
					// View options
					view: {
						iosSwipeBack: false,
						preloadPreviousPage: false,
						url: "/",
						browserHistorySeparator: "#p",
						browserHistory: true,
						browserHistoryStoreHistory: true,
						browserHistoryOnLoad: true,
						browserHistoryInitialMatch: true,
					},
				},
			};
		},

		methods: {
			goBack: goBack,

			checkUrl(path, hash) {
				if (path === "home") {
					return;
				}
				if (hash.indexOf(path) === -1 && hash.length > 1) {
					location.reload();
				}
			},

			setCurrentPage(urlPath) {
				let urls = urlPath.split("/");
				urls.shift();
				let url = urls[0];

				const exceptions = ["", "notifications", "designation"];

				for (const exception of exceptions) {
					if (exception === url) {
						this.$store.commit("app/setCurrentPage", "home");
						return;
					}
				}

				for (let route of f7router.routes) {
					if (route.name === url) {
						this.$store.commit("app/setCurrentPage", route.name);
						break;
					}
				}
			},

			toMins(ms) {
				return ms / 60000;
			},

			checkTokenLifeTime() {
				let lastUpdateTime = LS.get("update-time");
				if (lastUpdateTime) {
					let diff = Date.now() - lastUpdateTime;
					const minsDiff = Math.floor(this.toMins(diff));

					if (minsDiff > this.tokenLifeTime) this.$store.dispatch("logout");
					else LS.set("update-time", Date.now());
				}
			},

			// closeAllSmartSelects() {
			// 	let smartSelect = f7.smartSelect.get(".smart-select");
			// 	if (smartSelect) {
			// 		f7.smartSelect.close(smartSelect);
			// 	}
			// },

			changeRoute() {
				// this.closeAllSmartSelects();
				this.setCurrentPage(f7router.currentRoute.url);
				this.checkUrl(f7router.currentRoute.path, location.hash);
			},
		},

		async created() {
			if (this.userToken) {
				await this.$store.dispatch("app/loadDef");
				this.$store.commit("app/showToolbars");
				this.checkTokenLifeTime();
			}
		},

		async mounted() {
			f7ready(f7 => {
				window.f7 = f7;
				window.f7router = f7.views.main.router;
				f7.view.main.init();

				if (f7router.initialUrl === "/") {
					this.$store.commit("trainings/setLimit");
				}

				if (this.userToken) {
					this.setCurrentPage(f7router.initialUrl);
				} else {
					f7router.navigate("/");
				}

				f7.on("routeChange", this.changeRoute);
				f7.on("smartSelectBeforeDestroy", el => {
					el.close();
				});
			});
		},
		watch: {
			userToken: function (val) {
				if (!val) {
					location.href = `${location.origin}/`;
				}
			},

			loading: function (val) {
				if (val) f7.dialog.preloader();
				else f7.dialog.close();
			},
		},

		computed: {
			...mapGetters("app", ["tokenLifeTime", "showToolbars"]),
			...mapGetters(["userToken", "loading", "getShowErrorHandlingModalStatus"]),
			...mapGetters("auth", ["userToken"]),
		},
	};
</script>

<style lang="scss">
	@import "@/sass/_vars";
	.app-page {
		position: relative;
		max-width: $max-width;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: auto;
	}
</style>
